.meta-container {
    width: 100%;
    display: flex;

    .meta-sidenav {
        width: 340px;
  
    }

    .meta-main-body {
        width: calc(100% - 340px);
        padding: 10px 0px 10px 10px;
        background-color: #ffffff;
    }
}

.common-label {
    color: #031639;
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 6px;
}

.drawer-btn {
    width: 100%;
    color: #0075FF;
    border-color: #0075FF;
    height: 37px;
    border-radius: 4px;
}

.new-container {
    padding: 20px;
    width: 100%;
}

.submit-btn {
    display: flex;
    justify-content: space-between;
    width: 30%;
}

.main-section {
    padding: 20px;

    width: 100%;
}

.header-section {
    padding: 20px;
    display: flex;
    justify-content: space-between;
}

.dark-text{
font-weight: 700;
color: #0C0E2E;
}
.font-head{
font-size: 18px;
font-weight: 700;
color: #0C0E2E;
}
.grey-bold{
    color: #58596C;
font-weight: 700;

}

.col-content{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;

}
.common-type{
width: 100%;
display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #F1F1F1;
    padding: 10px 20px 10px 0px;
}
.left-div{
    display: flex;
    align-items: flex-start;
}
.right-div{
    display: flex;
    align-items: flex-end;
}
.error-status{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}