.custom-modal {
  background-color: rgba(0, 0, 0, 0.7);
  // display: none;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;

  .close-icon {
    cursor: pointer;
    line-height: 0;
    position: absolute;
    top: 21px;
    right: 25px;
    -webkit-transition: 0.3s ease-out;
    -moz-transition:  0.3s ease-out;
    transition:  0.3s ease-out;
    z-index: 9999;

    &:hover {
      transform: rotateZ(180deg);

      svg {
        path {
          fill: #3d414a;
        }
      }
    }
  }

  .modal-content {
    background-color: #fff;
    box-shadow: 0 5px 30px 0 rgba(0, 0, 0, .3);
    cursor: default;
    max-width: 400px;
    position: absolute;
    top: 0;
    left: 0;

    &.full-width {
      height: 100%;
      max-width: 100%;
      width: 100%;
      
      &.modify-width {
        max-width: 50%;
      }

      .inner-content {
        max-height: calc(100% - 45px);
        overflow-y: auto;
        padding: 15px 40px 80px 40px;
   
        &.p80 {
          padding: 80px;
        }

        .contact-details  {
          font-weight: 600;
          text-align: center;
          text-transform: capitalize;
        }

        .certificate-editor-container {
          .cke_inner {
            .cke_contents {
              height: calc(100vh - 250px)!important;
            }
          }
        }

        .separator-border {
          border-top: 1px solid #ddd;
          padding-top: 5px;
          padding-bottom: 5px;
        }
      }
    }

    .logo {
      width: 50px;
      height: 50px;
    }

    .read-only-content {
      color: #676a6c;
      padding-bottom: 5px;
      width: 100%;
     .ant-col{
      display: flex;
     }
      .title {
        font-weight: 600;
        

        &.lh-adjust {
          line-height: .8;
        }
      }

      .info {
        color: #656b79;
        font-size: 13px;
        width: 200px;
      }

      &.w-33 {
        width: 33%;
      }

      .text-right {
        padding-right: 20px;
        text-align: right;

        &.pr10 {
          padding-right: 10px;
        }
      }

      ul {
        margin-left: 15px;

        li {
          padding-bottom: 10px;
        }
      }
      .remarks-heading{
        font-weight: 600;
        color: #000;
      }
    }

    .bill-summary {
      // background-color: #f8f8f8;
      // box-shadow: 0 2px 2px rgba(0, 0, 0, .3);
      padding: 20px 0 20px 20px;

      .right-txt {
        padding-right: 30px;
        text-align: left;

        &.bold {
          font-weight: 700;
        }
      }

      .pr50 {
        padding-right: 50px;
      }
    }

    .upload-link {
      color: #4697ce;
      cursor: pointer;
      margin-top: 10px;
      text-align: center;
    }
    .upload-link {
      .ant-upload-list-text{
        display: none !important;
      }
    }

    .button-group-action {
      background: #fff;
      position: fixed;
      bottom: 0;
      left: 25px;
      padding-top: 15px;
      padding-bottom: 15px;
      text-align: center;
      width: 100%;
      z-index: 999;

      button {
        margin-right: 10px;

        &:last-child {
          margin-right: 0;
        }

        a {
          color: #fff;
          padding-left: 5px;
        }
      }

      .ant-btn-default {
        background-color: #e9ecf0;

        &:hover {
          background-color: #cbd2db;
          color: #3f414d;
        }
        &:active {
          background-color: #cbd2db;
          color: #3f414d;
        }
      }

      &.mb0 {
        margin-bottom: 0;
      }

      &.header-control {
        background-color: transparent;
        border-top-width: 0;
        display: inline-flex;
        top: -2px;
        right: 85px;
        left: unset;
        bottom: unset;
        width: auto;

        button {
          box-shadow: none;
        }

        .small-btn-group {
          // border: 1px solid #ddd;
          border-radius: 4px;
          margin-right: 10px;

          button {
            background-color: rgba(#ddd, .2);
            border: 0;
            border-right: 1px solid #ddd;
            border-radius: 0;
            box-shadow: none;
            height: 30px;
            margin-right: 0;
            width: 40px;

            &:last-child {
              border-right: 0;
            }

            // &:hover {
            //   border-right: 1px solid #ddd;
            //   color: #16a085;
            // }
          }
        }

        .action-dropdown-btn {
          background-color: transparent;
          margin-top: 5px;
        }
      }
    }

    &.vertical-view-data {
      .brand-logo {
        text-align: right;
      }

      .doc-title {
        color: #000;
        display: inline-block;
        font-size: 24px;
        font-weight: 600;
        margin-top: -20px;
      }

      .read-only-content {
        .title {
          color: #000;
        }
      }

      .underline-text {
        text-decoration: underline;
      }
    }
  }

  .modal-dialog {
    height: 100vh;
    position: relative;
  }

  // &.show {
  //   display: block;
  // }

  .modal-header {
    background: #fff;
    border-bottom: 1px solid #ddd;
    color: #000;
    font-size: 18px;
    font-weight: 500;
    height: 60px;
    padding: 15px 20px;
    position: sticky;
    position: -webkit-sticky;
    top: -15px;
    text-align: center;
    z-index: 999;

    &.align-left {
      text-align: left;
      padding-left: 40px;
    }
  }
}

.ant-modal-content {
  .ant-modal-header {
    border-bottom: 0;
    padding-top: 25px;

    .ant-modal-title {
      // color: rgba(0, 0, 0, 0.6);
      font-size: 18px;
      font-weight: 300;
      padding-bottom: 5px;
    }

    .ant-modal-subtitle {
      font-size: 17px;
      color: #000;

      &.extra-sm {
        font-size: 13px;
      }
    }
  }
  .ant-modal-body{
    overflow: auto;
  }
}

.payment-details-modal {
  .read-only-content {
    color: #676a6c;
    padding-bottom: 5px;
    width: 100%;

    .title {
      font-weight: 600;
    }
  }

  .payment-details-action {
    margin-top: 10px;
    text-align: center;

    button {
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
.modal-heading{
  display: flex;
  justify-content: space-between;
  padding: 8px;
  border-bottom: 1px solid rgb(228, 228, 228);
  .modal-heading-right{
  display: flex;
  gap: 10px;
  padding-right: 20px;
  }
  .modal-heading-left{
    font-weight: 600;
    padding-top: 6px;
    font-size: 15px;
    // padding-bottom: 6px;
    align-items: center;
    
  }
 
 }


 .full_modal{
  margin-top: -20px;
  overflow: hidden;

  .ant-modal-content {
    height: 100vh;
    overflow: hidden;

    .ant-modal-close {
      .ant-modal-close-x {
        display: block;
        width: 40px;
        height: 40px;
        font-size: 16px;
        font-style: normal;
        line-height: 42px;
      }
    }

    .ant-modal-body {
      min-height: 100vh;
      overflow: hidden;
      padding: 0px !important;

      .top_header {
        height: 48px;
        display: flex;
        align-items: center;
        padding: 0 40px 0 10px;
        border-bottom: 1px solid #e7e7e7;
        box-shadow: rgba(0, 0, 0, 0.45) 0px 1px 10px -8px;

        .sep {
          width: 1px;
          background-color: #e7e7e7;
          margin: 0 5px;
        }

        .title {
          font-size: 16px;
          font-weight: 700;
          color: $primaryColor;
        }
      }

      .m_body {
        height: calc(100vh - 50px);
        overflow: auto;
       .sub-heading-form{
        font-weight: 600;
       }
    .detail-para{
      font-weight: 200;
      font-size: smaller;
    }
       .ant-tabs-nav-scroll{
        display: flex;
        justify-content: center;
       }
        
      }
    }
    .mf_footer{
      background-color: #ffffff;
      border-top: 1px solid #e7e7e7;
      height: 48px;
      display: flex;
      justify-content: space-between;
      padding: 0px 20px;
      align-items: center;
      gap: 15px;
      position: absolute;
      bottom: 0;
      width: 100%;
    }
  }
}
