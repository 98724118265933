.action-dropdown-btn {
  display: inline-flex;
  background-color: #16a085;
  border-color: #16a085;
  border-radius: 2px;
  position: relative;

  button {
    background-color: #16a085;
    border: 1px solid #16a085;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    color: #fff;
    cursor: pointer;
    font-size: 13px;
    height: 38px;
    padding-left: 10px;
    padding-right: 10px;

    &:hover {
      background-color: #107360;
    }

    &:focus {
      outline: none;
    }

    &.right-border {
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
    }
  }

  .arrow-down {
    align-items: center;
    display: inline-flex;
    justify-content: center;
    padding-right: 5px;
    padding-left: 10px;

    .fa {
      align-items: center;
      color: #fff;
    }

    &:hover {
      background-color: #107360;
      cursor: pointer;
    }

    &.active {
      +.dropdown {
        background-color: #fff;
        border-radius: 3px;
        box-shadow: 0 1px 2px 1px rgba(0, 0, 0, .1);
        display: block;
        list-style-type: none;
        padding-left: 5px;
        padding-bottom: 5px;
        max-width: 200px;
        position: absolute;
        top: 36px;
        left: 0;
        width: 100%;
        z-index: 99;

        li {
          padding-top: 6px;
          padding-bottom: 6px;
          padding-left: 7px;
        }
      }
    }
  }

  .fa {
    margin-right: 5px;
  }

  .anticon-user {
    padding-right: 5px;
  }

  .dropdown {
    cursor: pointer;
    display: none;
    align-items: center;
    padding-left: 5px;
    padding-right: 5px;
    position: relative;

    &:hover {
      background-color: #107360;
    }

    .fa-angle-down {
      color: #fff;
      font-size: 16px;
    }

    &.active {
      display: inline-flex;

      .option-list {
        background-color: #fff;
        border-radius: 3px;
        box-shadow: 0 1px 2px 1px rgba(0, 0, 0, .1);
        display: inline-block;
        font-size: 13px;
        padding: 10px;
        position: absolute;
        top: 40px;
        right: 0;
        width: 160px;
        z-index: 99;

        .anticon-upload {
          padding-right: 5px;
        }

        &:hover {
          background-color: #d7dcde;
        }
      }
    }
  }

  &:focus {
    outline: none;
  }

  &.grey-btn {
    button {
      background-color: #e4e7eb;
      border-color: #e9ecf0;
      color: #3f414d;

      &:hover {
        background-color: #cbd2db;
      }
    }
  }
}

.combined-dropdown {
  margin-top: -3px;
}
