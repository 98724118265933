@import '~antd/dist/antd.css';
@import '../../common.scss';

.form-footer {
  right: 0;
  bottom: 0;
  margin-top: 10px;
}

.success-button {
  float: right;
}

.cancel-button {
  float: left;
  
}

.ant-tabs.ant-tabs-card {
  .ant-tabs-card-bar {
    .ant-tabs-tab {
      border-top: 1px solid $white;
      border-right: 1px solid $white;
      border-left: 1px solid $white;
      border-bottom: 1px solid #e8e8e8;
      color: #a2a7b1;
      background-color: $white;
      font-size: 13px;

      &:hover {
        color: #3d414a;
      }

      &.ant-tabs-tab-active {
        color: #3d414a;
      }
    }
  }
}
.Product-tab{
  .ant-input-prefix{
    background: transparent !important;
  }
  .ant-input-affix-wrapper{
    border: none !important;
  }
}
.ant-legacy-form-item {
  margin-bottom: 10px;
}

.ant-checkbox-checked .ant-checkbox-inner {
  @include green-btn-style;
}

.ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus+.ant-checkbox-inner:hover {
  @include green-border;
}

.address-label {
  margin-bottom: 20px;
}

.ant-tabs-tab-active {
  background: #fff;
  color: #727475;
  border-color: #e8e8e8 !important;
  border-bottom: 1px solid #fff !important;
}

.tab-view {
  min-height: 250px;
}

.ant-switch-checked {
  background-color: #4ca185;
}

.inventory-tabs {
  .ant-row {
    .ant-legacy-form-item-label {
      line-height: 20px;
    }
  }

  input[type="number"] {
    &:placeholder-shown {
      color: #000 !important;
    }
  }

  .single-line-row {
    .ant-input {
      width: 200px;
    }
  }

  .alert-tab-section {
    padding-top: 8px;

    .input-control {
      align-items: center;
      display: flex;
      margin-right: auto;
      margin-bottom: 20px;
      margin-left: auto;
      width: 80%;

      .ant-col-8 {
        text-align: right;
        padding-right: 20px;
      }
    }
  }

  .input-currency-control {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    position: relative;

    .currency-tag {
      top: -6px;

      .fa-rupee {
        vertical-align: text-top;
      }
    }

    input[type="number"] {
      width: 80%;
      height: 38px;
    }
  }

  .UOM-dropdown {
    width: 100%;

    .ant-select-selection__placeholder {
      display: none !important;
    }
  }

  .ant-legacy-form-item-control {
    line-height: 1.2;
  }

  .product-name-input {
    .ant-legacy-form-item {
      margin-top: -5px;
      margin-bottom: 0;

      ~.text-red {
        padding-left: 50px;
        padding-bottom: 5px;
      }

      +.text-red {
        padding-left: 135px;
      }
    }
  }

  .hsn-dropdown {
    .text-red {
      display: block;
      padding-top: 3px;

      &.hide {
        display: none;
      }
    }
  }
}

.custom-supplier-drawer {
  .ant-drawer-header {
    background: #fff;
    position: sticky;
    top: 0;
    z-index: 9999;
  }

  .ant-drawer-body {
    position: relative;
    font-size: 12px;
  

    .tab-view {
      padding-bottom: 50px;
    }

    .form-footer {
      background: #fff;
      // padding-right: 0px;
      // padding-bottom: 0px;
      // padding-left: 24px;
      width: 100%;

      .ant-btn-primary {
        margin-right: 25px;
      }
    }
  }
}

.product-details-tab {
  .product-detail-control {
    align-items: flex-start;
    display: flex;
    flex-direction: column;

    .ant-legacy-form-item-control-wrapper {
      width: calc(100% - 13px);
    }
  }
}

.product-name-input-error {
  margin-bottom: 10px;
}

.ant-checkbox-checked:after {
  border: none;
}

.drawer-heading {
  color: #071028;
  font-size: 14px;
  font-weight: 600;
}

.drawer-label {
  color: #031639;
  font-size: 12px;
  font-weight: 600;
}

.desc ::placeholder {
  display: flex;
  align-items: start;
  justify-content: start;
}

// .media-content {
//   align-items: start;
//   height: 200px;
//   width: 100%;

//   .ant-upload {
//     border-radius: 12px;
//     background-color: #ECF5FF;
//     border: 1px dashed #0075FF;
//   }

//   .anticon svg {
//     font-size: 50px;
//   }

//   .ant-upload-list {
//     margin-top:10px
//     // margin-top: 30px;
//     // border-radius: 5px;
//     // height: 60px;
//     // align-items: center;
//     // width: 100%;

//     div {
//       width: 100%;
//     }

//     .ant-upload-list-item {
//       border: 1px solid #CFCFCF;

//     }

//     .anticon svg {
//       font-size: 20px !important;
//     }
//   }
// }



.table-color {
  background-color: #a2a7b1;
}

.sku {

  .ant-switch-checked::after {
    height: 36px !important;
    width: 25px;
    border: 6px;

  }

  .ant-switch::after {
    height: 36px !important;
    width: 25px;
    border-radius: 6px;

  }

}

.profit-toggle {
  .ant-switch {
    width: 52px;
    background-color: #4ca185;
    &.ant-switch-checked {
      .ant-switch-inner {


        &:before {
          content:"%";
          color: #fff;
          font-size: 11px;
        }
      }
    }

    .ant-switch-inner {
      &:before {
        content: "Flat";
        color: #fff;
        font-size: 11px;
      }
    }
  }
} 

.custom-width {
  width: 80px;

  .ant-input-prefix {
    background: none;
    width: 20px;
  }

}

.pd0 {
  padding: 0px !important;
}

.p-image {
  padding: 0px !important;

  .ant-upload-picture-card-wrapper {
    display: contents;
    width: 100%;
}
  .ant-upload.ant-upload-select-picture-card {

    width: 50px !important;
    height: 45px !important;
    margin: 5px;
    display: flex;

    .ant-upload {
      display: inline;
      padding: 0px !important;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 5px;
    }
   
  }

}

.inventory-variant-table {
  .table-container {

    table {
      thead {
        tr {
          th {
            background-color: #F1F1F1;
            width: 30px;
            &:first-child {
              position: sticky;
              left: 0;
              z-index: 2;
              
            }
          }
        }
      }
    }
  }
}

.inventory-variant-table {
  .table-container {
  
overflow: auto;
  .fixed-column .p-image {
      width: 180px; 
  }
    table {
         .fixed-column-table {
      table-layout: fixed; 
  }
      tbody {
          .fixed-column {
      position: absolute; 
      left: 0;
      z-index: 3; 
  }
        tr {

          td {
            padding: 0px 5px !important;
           
            .ant-input-affix-wrapper {
              height: 30px !important;
            }
            &:first-child {
              position: sticky;
              left: 0;
              z-index: 2;
              background-color: #fff; 
            }
          }
        }
      }
      
    }
  }
}

.inventory-variant-table {
  .ant-select-selector {
    height: 30px !important;
    min-height: 30px !important;
  }
}

.more-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 38px;
}

.custom-add {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px
}

.custom-select {
  .ant-select-dropdown-menu {
    height: 30px !important;
    min-height: 30px !important;
    max-height: 30px !important;
  }
}

.discount-label {
  width: 56px;
  height: 15px;
  background-color: rgb(255 0 0 / 64%);
  color: #fff;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
}

.material-ui-rc-color-picker-trigger{
  width: 100%;
  height: 100%;
  opacity: 0;
}

.UnBoxing-Video{
  display: flex;
  gap: 40px;
  align-items: center;
  .uploadedVideo{
   width: 80%;
   
     video{
      width: 100%;
      height: 300px;
     }
  }
  .icon{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .unboxing-icon{
    font-size: 15px;
    font-weight: 600;
    cursor: pointer;
    color: rgb(168, 168, 168);
    // border: 1px solid rgb(168, 168, 168);
    // padding: 10px;
    // border-radius: 50%;
  }
}