.mainCard-body{ 
    padding-top: 20px;
    padding-left: 10%;
    padding-right: 10%;
    overflow: auto;
    // background-color: bisque;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    .center-div{
        height: 70%;
        width: 40%;
        background-color: white;
    }
    .img-container{
        height: 60%;
        // background-color: #f7f7f7;
        border-radius:  8px 8px 0 0;
        border: 2px solid #f7f7f7;
        border-bottom: none;

        text-align: center;
       img{
           height: 100%;
           max-width: 100%;

        
        
       }
    }
    .details-container{
        height: 40%;
        
        padding-top: 20px;
        padding-left: 10%;
        padding-right: 10%;
        border: 2px solid #f7f7f7;
        border-radius: 0 0 8px 8px;
        border-top: none;
       
    }
}

