.finance-container {
  padding-top: 30px;
  padding-right: 50px;
  padding-left: 10px;
}

.finance-table {
  border-collapse: collapse;
  color: #656b79;
  font-family: "Open Sans", "Segoe UI", "Droid Sans", Tahoma, Arial, sans-serif;
  font-size: 12px;
  // margin-top: 50px;
  // margin-bottom: 50px;
  // margin-left: 50px;
  width: 100%;

  tr{
    th{
      font-weight: 700;
    }
  }

  th, td {
    border: 1px solid #e2e2e2;
    text-align: left;
    padding: 6px 10px;
  }


  tr {
    &:nth-child(even) {
      background-color: #f9f9f9;
    }

    &:nth-child(odd) {
      background-color: #fbfbfb;
    }
  }

  .bold-text {
    font-weight: 700;
  }

  // .text-center {
  //   text-align: center;
  // }

  // .text-right {
  //   text-align: right;
  // }

  // .white-bg {
  //   background: #fff;
  //   border-right: 0; 
  // }
}

.sticky_row{
  position: sticky;
  bottom: 0;
  background: #e7e7e7;
}