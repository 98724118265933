.input-text-error {
  border: 1px solid $error-red;

  &.ant-select{
    width: 100%;
  }

  &.ant-input{
    height: 38px;
  }

  &:hover {
    border: 1px solid $error-red;
  }

  &:focus {
    border: 1px solid $error-red;
  }
}

.input-text {
  // color: $green-btn-border;
  height: 38px;

  &:hover {
    border: 1px solid $green-btn-border;
  }

  &:focus {
    border: 1px solid $green-btn-border;
  }
}
.ant-select-selector{
  min-height: 38px;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
  height: 38px;
  display: flex;
  align-items: center;
  // padding: 0 5px;
  // width: 85.6%;
}

.ant-btn-icon-only.ant-btn-sm{
  background-color: rgb(255, 255, 255) !important;
  border-color: rgb(153, 152, 152) !important;


  :hover{
    background-color: rgb(255, 255, 255) !important;
    border-color: rgb(153, 152, 152) !important;
  }
  :focus{
    background-color: rgb(255, 255, 255) !important;
    border-color: rgb(153, 152, 152) !important;
  }
}
.ant-transfer-operation .ant-btn .anticon{
  color: rgb(153, 152, 152) !important;
}
.ant-btn .ant-btn-primary .ant-btn-sm .ant-btn-icon-only{
  :hover{
    background-color: red;
  }
}

.ant-picker{
  height: 38px;
}
.ant-input-number-input{
  height: 38px;
}

//pagination select

.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector{
  min-height: 24px;
}

// for inline view in multi select
.ant-select-multiple{
  width: 100%;
 .ant-select-selector{
    flex-wrap: inherit;
    overflow: hidden;
  }
}

.ant-input-group-addon{
  background-color: #e7e7e7;
  height: 38px;
  width: 40px;
}

.ant-input-wrapper.ant-input-group{
  .ant-input{
    height: 38px;
  }
}

// input label style(inbuilt antd input)
.ant-input-prefix {
  margin: 1px 5px 1px -4px;
  background: #e7e7e7;
  height: 36px;
  width: 40px;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 2px 0px 0px 2px;
}