.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}


.spinner {
  top: 25% !important;
  z-index: 999999999999 !important;
  
}
// 12
// .spinner {
//   top: 25% !important;
//   z-index: 9999 !important;
// }
.spinner2 {
  // top: 25% !important;
  // z-index: 9999999 !important;
  z-index: 9999999 !important;
  height: 100%;
  width: 100%;
  backdrop-filter: blur(2px);
  // margin-top: -21vh !important;

  // .ant-spin-nested-loading{
  //   div{
  //     .ant-spin.ant-spin-show-text{ 
  //       .ant-spin-dot{
  //         margin-top: 0px !important;
  //       }
  //     }
  //   }
  // }
  span{
    position: relative;
    top: 50%;
  }
  div{
    position: relative;
    top: 50%;
  }


}
.ant-spin-spinning{
  position:absolute;
  display: inline-block;
  opacity: 1;
}
.spinnerIcon > svg{
  font-size: 30px;
  color: grey;
  // z-index: 9999999999999 !important;

}
.ant-spin-text{
  margin-top: 10px;
  color: grey;
}

.App-link {
  color: #09d3ac;
}

.action-menu-bars{
  color: #000000;
  background-color: #f3f3f3 !important;
  border-color: #f3f3f3 !important;
}

.ant-spin-dot{
  font-size: 30px !important;
}