.cloudStorage-Container{
  .leftBar{
    border: none;
    border-right:1px solid #f0f0f0;
    height: 100vh;
    .cardHeader{
      margin: 0 0 2rem;
      h2{
        font-size: 16px;
      }
    }
    .cardMenuItem{
      display: flex;
      flex-direction: column;
      gap: 1rem;
      margin: 1rem 0;
      .menu{
        cursor: pointer;
        font-size: 14px;
        color: #58596C;
        display: flex;
        gap: 1rem;
        margin: 0;
        text-align: left;
        align-items:center;
       
      }
      .menu.active{
        color: #0075FF;
      }
    }
    .storageSection{
      margin: 5rem 0 0;
      .free{
        background-color: #F3F4F6;
        color: #031639;
        border-radius: 4px;
        font-size: 12px;
        padding: 6px 12px;
        display: inline-block;
      }
      .storage{
        margin: 1rem 0 0;
        font-size: 14px;
        color: #58596C;
      }
    }
    .progressBar{
      .ant-progress-text{display: none;}
      .ant-progress .ant-progress-outer .ant-progress-inner {
        background-color: #F1F1F1;
        border-radius: 100px;
      }
      .ant-progress .ant-progress-outer .ant-progress-inner .ant-progress-bg {
        background-color: #0075FF;
        border-radius: 100px;
        height: 6px !important;
    }
    }
    .storageCount{
      font-size: 14px;
      color: #58596C;
      margin: 1rem 0 0;
    }
  }
  .rightBar{
    border: none;

    .cardHeader{

      .driveName{
        display: flex;
        gap: 0.5rem;
        margin: 0 0 1rem;
        position: relative;

        h2{
          font-size: 16px;
          margin: 0;
        }
        button{
          background-color: transparent;
          border: none;
          cursor: pointer;
        }
        .uploadDrive{
          position: absolute;
          top: 30px;
          width: 280px;
          background: #fff;
          z-index: 1;
          border-radius: 6px;
          box-shadow: 0 0 10px #efefef;

          ul{
            list-style: none;
            margin: 0;
            display: flex;
            flex-direction: column;
            gap:0.5rem;

            li{
              display: flex;
              align-items:center;
              gap:0.5rem;
              padding: 0.8rem;
              cursor: pointer;

              svg{
                font-size: 16px;
              }
            }
          }
        }
      }
      p{
        font-size: 12px;
        color: #58596C;
      }
    }
    .selectSection{
      display: flex;
      gap: 1rem;
      margin:1rem 0 0;

      .custom-dropdown-width{
        width:auto;
      }
    }
    .fileLocation{
      margin: 1rem 0 0;
      ul{
        list-style: none;
        display: flex;
        gap:0.625rem
      }
      ul li{color: #58596C;}
      ul li:first-child{color: #0075FF;}
    }
    .uploadSection{
      margin: 1rem 0 0;

      .ant-upload.ant-upload-drag{
        border-radius: 6px;
        border:1px solid #ECF5FF;
        background-color: #ECF5FF;

        .ant-upload{
          display: flex;
          padding: 1rem;
        }
        .ant-upload-drag-container{
          display: flex;
          gap: 1rem;

          .ant-upload-drag-icon i{
            font-size: 1.25rem;
            color: #0075FF;
          }
        }
      }
    }
    .uploadTable{
      margin: 1rem 0 0;
      .view-container-actions{

        .storageCount{
          display: flex;
          gap: 1rem;
          width: 40%;
          justify-content: space-between;
          align-items: center;

          svg{
            font-size: 18px;
          }
        }
      }
      .table-container{
        height: 50vh;
      }
    }
  }
}