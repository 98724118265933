@import '../../assets//stylesheets/global/variable';

.StoreSettings-container{
    .page-title-bar{
        border-bottom: 1px solid #E8E8FF;
        height: 54px;
        padding: 0px 20px;
        z-index: 99;
        background-color: #ffffff;
        display: flex;
        flex-direction: row;
        // justify-content: space-between;
        align-items: center;
        gap: 10px;

        .page-title{
            font-size: 16px;
            color: $primaryColor;
            font-weight: 700;
        }

        .sep{
            width: 1px;
            background-color: #e7e7e7;
            height: 40%;
        }
        
    }
    .settings-body{
        background-color: #ffffff;
        padding: 10px;
        height: calc(100vh - 125px);
        overflow-y: auto;

        .settings-body-child{

            .child-title{
                font-size: 14px;
                font-weight: 600;
                color: $primaryColor;
                padding-bottom: 5px;
                border-bottom: 1px solid #e7e7e7;
            }

            .child-body{
                display: flex;
                gap: 10px;
                margin: 0px 0;
                flex-wrap: wrap;
    
                .tile{
                    width: calc((100% - 60px) / 3);
                    border: 1px solid #F5F7F9;
                    padding: 20px;
                    border-radius: 4px;
                    display: flex;
                    background-color: #F5F7F9;
                    cursor: pointer;
                    height: 120px;

                    &:hover{
                        background-color: #e8ebee;
                    }

                    .tile-icon{
                        padding-right: 12px;
                        i{
                            font-size: 18px;
                        }

                    }
                    .tile-title{
                        font-size: 14px;
                        font-weight: 600;

                        .tile-description{
                            font-weight: 400;
                            line-height: 1.3;
                            font-size: 11px;
                        }
                    }

                }
            }
        }

    }
    .profile-container{
      .main-body{
        background-color: #fff;
      }
    }
}


// eStore css 
// .store-model-container {
//   position: relative;
 
//   height: 88%;
//   padding:20px 20px 40px 0px;
//   // width: 100%;
//   overflow: auto;

//   @media screen and (max-width:1150px) and (min-width:992px) {
//     height: 82%; 
//   }
 
//    .store-main-container{
//     padding: 20px;
//     height: 88%;
//     overflow-y: auto;

//        .model-container{
//         padding: 20px 0px;
//        }
//    }
//    .store-footer{
//     height: 20%;
   
//     position: sticky;
//     bottom: 0;
//     display: flex;
//     justify-content: end;
   
          
//    }
//   .model-head { 
//     height: 40px;
//     margin:  0;
//     // display: flex;
//     // justify-content: space-between;
//     // align-items: center;
//   }

//   .f-item-center {
//    display: flex;
//    align-items: center;
//   }

//   .heading {
//     font-size: 16px;
//     font-weight: 700;
//   }

//   .sub-heading {
//     font-weight: 400;
//   }

//   .text-right  {
//    display: flex;
//    align-items: center;
//    gap: 10px;
//   }

//   .col-right {
//     display: flex;
//     align-items: center;
//     gap : 5px;
//     cursor : pointer
//   }
//   .footer-btn{
//     width: 100%;
//     border-top: 1px solid #e7e7e7;
//     background-color: #fafbff;
//    position: absolute;
//     display: flex;
//     bottom: 0px;
//     padding: 10px 20px 16px 20px;
//     right: 0px;
//     justify-content: end;
//     margin-top: 40px;

   
//   }
// }
// .store-model-container::-webkit-scrollbar {
//   display: none;
// }
// .day-wrapper  {
//   width: 70%;

//    .timing{
//     position: relative;
//     .dropdown-list  {
//       position: absolute;
//       padding: 10px;
//       top: 43px;
//       background-color:#ffffff;
//       border-radius: 10px;
//       cursor: pointer;
//       box-shadow: 10px 10px 5px -4px rgba(219,216,219,1);
//       overflow-y: scroll;
//       z-index: 99;
//       font-size: 16px;
//       font-weight: 400;
//       width: 120px;
//       max-height: 16rem;
//       cursor : pointer;

//       li{
//         list-style: none;
//         padding: 5px;
//         font-size: 14px !important;
//       }
//   }
//    }
    

//     .data-inner-wrapper  {
//       display: flex;
//       align-items: flex-start;
//       gap: 20px;
//     }
// }


// .custom-switch .ant-switch-inner {
//     display: none; /* Hide the switch inner content (labels) */
//   }


//   .languages-row {
//     display: flex;
//     align-items: center;
//     justify-content: space-between;
//      border-bottom: 1px solid #e7e7e7;
//      padding-bottom: 10px;
//   }

//   .primary-laguage-warpper  {
//     display: flex;
//     align-items: center;
//     gap: 10px;
//   }

//   .laguage-label {
//     font-size: 14px;
//   }

//   .linkStyle {
//     a{
//         font-size: 14px;
//         cursor: pointer;
//     }
//   }

//   .primary-label-lang{
//     padding: 3px 8px;
//     background-color: #FFB020;
//     color: #ffffff;
//     height: 20px;
//     text-align: center;
//     border-radius: 44px;
//     font-size: 11px;
//     font-weight: 400;
//     display: flex;
//     align-items: center;
// }
// .color-danger{
//    color: red;
// }


// // eStore css 
// .store-model-container {
//   height: 78%;
//   padding:0px 20px 40px 0px;
//   // width: 100%;
//   overflow: auto;
 
//    .store-main-container{
//     height: 80%;
//     overflow-y: auto;
//    }
//    .store-footer{
//     height: 20%;
   
//     position: sticky;
//     bottom: 0;
//     display: flex;
//     justify-content: end;
   
          
//    }
//   .model-head { 
//     height: 40px;
//     margin:  0;
//     // display: flex;
//     // justify-content: space-between;
//     // align-items: center;
//   }

//   .f-item-center {
//    display: flex;
//    align-items: center;
//   }

//   .heading {
//     font-size: 16px;
//     font-weight: 700;
//   }

//   .sub-heading {
//     font-weight: 400;
//   }

//   .text-right  {
//    display: flex;
//    align-items: center;
//    gap: 10px;
//   }

//   .col-right {
//     display: flex;
//     align-items: center;
//     gap : 5px;
//     cursor : pointer
//   }
//   .footer-btn{
//    position: fixed;
//     display: flex;
//     bottom: 20px;
//     right: 20px;
//     justify-content: end;
//     margin-top: 40px;

   
//   }
// }
.store-model-container::-webkit-scrollbar {
  display: none;
}
.day-wrapper  {
  width: 70%;

   .timing{
    position: relative;
    .dropdown-list  {
      position: absolute;
      padding: 10px;
      top: 43px;
      background-color:#ffffff;
      border-radius: 10px;
      cursor: pointer;
      box-shadow: 10px 10px 5px -4px rgba(219,216,219,1);
      overflow-y: scroll;
      z-index: 99;
      font-size: 16px;
      font-weight: 400;
      width: 120px;
      max-height: 16rem;
      cursor : pointer;

      li{
        list-style: none;
        padding: 5px;
        font-size: 14px !important;
      }
  }
   }
    

    .data-inner-wrapper  {
      display: flex;
      align-items: flex-start;
      gap: 20px;
    }
}


.custom-switch .ant-switch-inner {
    display: none; /* Hide the switch inner content (labels) */
  }


  .languages-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
     border-bottom: 1px solid #e7e7e7;
     padding-bottom: 10px;
  }

  .primary-laguage-warpper  {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .laguage-label {
    font-size: 14px;
  }

  .linkStyle {
    a{
        font-size: 14px;
        cursor: pointer;
    }
  }

  .primary-label-lang{
    padding: 3px 8px;
    background-color: #FFB020;
    color: #ffffff;
    height: 20px;
    text-align: center;
    border-radius: 44px;
    font-size: 11px;
    font-weight: 400;
    display: flex;
    align-items: center;
}
.color-danger{
   color: red;
}

.model-footer  {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  bottom: -50px;
  width: 100%;
}
.model-footer  {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  bottom: -50px;
  width: 100%;
}

.brevo-modal{
  .ant-modal-content {
  
    min-height: 200px;
    width: 460px;

    .ant-modal-body {
      height: 170px;
      padding: 0;

      .information {
        max-height: 170px !important;
        overflow-y: auto;
        padding-top: 10px;

        .ant-row {
          padding-bottom: 12px;

          .ant-col-8 {
            padding-right: 20px;
            text-align: right;
          }
        }

        .input-currency-control {
          .input-text-error {
            border-left: 1px solid $error-red;
            height: 38px;
          }
        }
      }
    }

    .ant-modal-header {
      border-bottom: 1px solid #ddd;
      margin-bottom: 10px;
      padding-top: 15px;

      .title {
        color: #000;
      }
    }

    .form-footer {
      display: flex;
      justify-content: space-between;
      border-top: 1px solid #ddd;
      padding: 10px 20px;
      text-align: right;
    }
  }
}