@import "./../global/variable";

.ant-picker {
  width: 100%;
}

.ant-select-focused {
  .ant-select-selector {
    box-shadow: none!important;
    border-color: #16a085!important;
  }

  &.ant-select {
    .ant-select-selector {
      border-color: #16a085!important;
    }
  }
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #16a085!important;
}

.date-range-container {
  .custom-dropdown-width {
    width: 275px!important;
  }

  .ant-select-selection-placeholder {
    padding-left: 20px!important;
  }
}

.ant-picker {
  &:hover {
    border-color: #16a085!important;
  }
}

.ant-picker-focused {
  border-color: #16a085!important;
  box-shadow: none!important;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #5c5f66;
}


pre {
  overflow: hidden;
  line-height: 14px;
  font-weight: 400;
  font-size: 12px;
  white-space: pre-wrap;       /* Since CSS 2.1 */
  white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
  white-space: -pre-wrap;      /* Opera 4-6 */
  white-space: -o-pre-wrap;    /* Opera 7 */
  word-wrap: break-word;
  font-family:  sans-serif;
}

// .ant-pagination {
//   &.mini {
//     display: flex;
//     justify-content: flex-end;
//     margin-top: 20px;
//   }

//   &.mt10 {
//     margin-top: 10px;
//   }
// }


.ant-pagination{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 0px;

    .ant-pagination-total-text{
    }
    .ant-pagination-prev .ant-pagination-prev{
      line-height: 0px;
    }
    .ant-pagination-item{
      height: 32px;
      justify-content: center;
      width: 32px;
      display: flex;
      align-items: center;

      a{
        padding: 6px;
      }

    }
    &.active{
      background: rgba(0, 117, 255, 0.07);
      border-radius: 12px;
    }


    .ant-pagination-options{
      .ant-select-selector{
        height: 34px;
      }
    }

}

.ant-drawer-title {
  text-align: left;
  font-size: 16px;
  color: #08173d;
  font-weight: bold;
}


.select-error{
  &.ant-select{
    width: 100%;
  }
  .ant-select-selector{
    border-color: red !important;
    // width: 100%;
  }

}


.error-box{
  &.ant-input{
    width: 100%;
    border-color: red !important;
  }

}