@import '../../assets//stylesheets/global/variable';

.settings-container {

    .page-title-bar{
        border-bottom: 1px solid #E8E8FF;
        height: 54px;
        padding: 0px 20px;
        z-index: 99;
        background-color: #ffffff;
        display: flex;
        flex-direction: row;
        // justify-content: space-between;
        align-items: center;
        gap: 10px;

        .page-title{
            font-size: 16px;
            color: $primaryColor;
            font-weight: 700;
        }

        .sep{
            width: 1px;
            background-color: #e7e7e7;
            height: 40%;
        }
        
    }

    .settings-body{
        background-color: #ffffff;
        padding: 20px;
        height: calc(100vh - 125px);
        overflow-y: auto;

        .settings-body-child{
            border-bottom: 1px solid #F1F1F1;
            margin-bottom: 30px;

            .child-title{
                font-size: 14px;
                font-weight: 700;
                color: $darkBlueColor;
            }
            .child-subTitle{
                font-size: 12px;
                font-weight: 400;
                color: $lightGrayColor;
            }
            .child-body{
                display: flex;
                gap: 10px;
                margin: 10px 0;
                flex-wrap: wrap;
    
                .tile{
                    width: calc((100% - 60px) / 3);
                    border: 1px solid #F1F1F1;
                    padding: 20px;
                    border-radius: 10px;
                    display: flex;
                    background-color: #fff;
                    cursor: pointer;
                    height: 120px;

                    &:hover{
                        background-color: #F8F8F8;
                    }

                    .tile-icon{
                        padding-right: 12px;
                        i{
                            font-size: 18px;
                        }

                    }
                    .tile-title{
                        font-size: 14px;
                        font-weight: 700;
                        color: $darkBlueColor;

                        .tile-description{
                            font-weight: 400;
                            line-height: 1.3;
                            font-size: 12px;
                            color: $lightGrayColor;
                        }
                    }

                }
            }
        }

    }
}
.remark-modalms .ant-modal-content .ant-modal-body {
    max-height: 700px;
    overflow-y: auto;
  }