.dash_container{
  height: calc(100vh - 68px);
  // overflow: auto;
}

.dashboard-wrapper {
  &.view-container {
    height: unset;
    padding: 0px;

    .ant-row{
      background-color: #eff2fe;

      .ant-spin-nested-loading{
        background-color: #ffffff;
        border-radius: 0px 0px 6px 6px;

      }
    }
  }

  .inner-view {
      box-shadow: 0 10px 10px rgba(0, 0, 0, 0.22), 0 0 8px rgba(0, 0, 0, 0.10);
      margin-bottom: 20px;

      .inner-heading-title {
        display: inline-block;
        font-size: 16px;
        padding-top: 20px;
        padding-left: 18px;
        padding-bottom: 20px;
        color: #58596C;
        font-weight: 500;
      }

      .title {
        color: #000;
        font-size: 14px;
        text-align: center;
        padding-top: 20px;
        padding-bottom: 10px;
      }

      .inner-table-caption {
        // color: #000
        font-size: 15px;
        padding-top: 20px;
        padding-bottom: 20px;
        // text-align: center;
        padding-left: 18px;
        font-size: 16px;
        color: #58596C;
        font-weight: 500;
      }

      .grid-data-container {
        margin-left: 20px;
        margin-right: 20px;
        padding-bottom: 30px;

        table {
          width: 100%;

          thead {
            tr {
              background-color: #f3f3f3;
              border-bottom: 2px solid #e9ecf0;
              height: 30px;

              th {
                color: #39414b;

                &:first-child {
                  padding-left: 10px;
                }
              }
            }
          }

          tbody {
            tr {
              height: 30px;
              td {
                border-bottom: 1px solid #e9ecf0;

                &:first-child {
                  padding-left: 10px;
                }
              }
            }
          }
        }

      
      }

      &.tabular-data {
        height: 440px;
        overflow-y: auto;
        background-color: #ffffff;
        padding-right: 12px;
      }

      .ant-col-24 .inner-view{
        box-shadow: none;;
      }
 

    }

  .summary-details {
    background-color: #ffffff;
    padding-top: 20px;

    .info {
      display: inline-block;
      text-align: center;
      width: 75%;

      .symbol {
        font-size: 20px;
        font-weight: 400;
      }

      .value {
        font-size: 19px;
      }

      .timeline {
        color: #a2a7b1;
        display: inline-block;
        font-size: 13px;
      }
    }
  }

  .ant-col-24 {
    .inner-view {
      margin: 0px !important;
      box-shadow: none;
      border-radius: 6px;
    }
  }

  .ant-col-8 {
    .inner-view {
      height: 400px;
      overflow: scroll;
      margin-left: 10px;
      table{
        width: 99%;
        margin-left: 5px;
      }
      margin-right: 10px;
    }
  }

  .ant-col-12 {
    .inner-view {
      margin-right: 20px;
      border-radius: 6px;
      box-shadow: none;
    }

   

    &:nth-child(even) {
      .inner-view {
        margin-right: 0;
      }
    }
  }
}

.todo-container {
  background: #fff;
  border-radius: 4px;
  margin-top: 10px;
  padding-bottom: 20px;
  padding-right: 15px;
  padding-left: 15px;
  position: relative;

  .task-list {
    list-style-type: none;

    li {
      align-items: flex-end;
      background-color: #FBFBFB;
      border-bottom: 1px solid #ecebeb;
      color: #656b79;
      display: flex;
      padding: 10px;

      .undo-btn {
        background-color: #e9ecf0;
        border: 0;
        color: #656b79;
        cursor: pointer;
        display: none;
        padding-right: 5px;
        padding-left: 5px;
        position: absolute;
        right: 20px;
      }

      span {
        &:first-child {
          border-radius: 3px;
          color: #fff;
          display: inline-block;
          margin-right: 10px;
          margin-left: 10px;
          padding: 0 10px;
          text-transform: capitalize;
        }
      }

      .danger {
        background-color: #d0281c;
      }

      .success {
        background-color: #004d00;
      }

      .warning {
        background-color: #c36424;
      }

      .info {
        background-color: #395565;
      }

      .primary {
        background-color: #2f6af1;
      }

      .task-action {
        background-color: #E9ECF0;
        border-radius: 4px;
        display: none;
        position: absolute;
        right: 20px;

        i {
          cursor: pointer;
          margin-right: 5px;
          margin-left: 5px;
          padding-left: 5px;
          padding-right: 5px;
          width: 30px;
        }
      }

      &:hover {
        .task-action {
          display: inline-block;
        }

        .undo-btn {
          display: inline-block;
        }
      }

      .cancel-btn {
        align-items: center;
        background-color: #acb0b5;
        border-radius: 4px;
        color: #3e3e3e;
        cursor: pointer;
        display: inline-flex;
        font-size: 16px;
        height: 32px;
        justify-content: center;
        width: 32px;
      }

      .save-btn {
        align-items: center;
        background-color: #2c7045;
        border-radius: 4px;
        color: #fff;
        cursor: pointer;
        display: inline-flex;
        font-size: 16px;
        height: 32px;
        justify-content: center;
        width: 32px;
      }

      &:first-child {
        border-top: 1px solid #ecebeb;
      }
    }
  }

  .add-task-btn {
    background-color: #4697CE;
    border: 0;
    color: #fff;
    cursor: pointer;
    border-radius: 3px;
    height: 32px;
    margin-left: 20px;
    padding-left: 10px;
    padding-right: 10px;
    position: absolute;
    top: 13px;
    left: 360px;

    &:focus {
      outline: 0;
    }
  }

  .form-action-control {
    display: flex;
    align-items: flex-end;

    input {
      margin-right: 10px;
    }
  }
}

.tiles-dashboard{
  margin-top: 10px;
}

.row-data {
  display: flex;
  justify-content: space-between;
  // align-items: center;
  background-color: #ffffff;
  border-radius: 6px 6px 0 0;

  .date-range-container {
    display: inline-flex;
    margin-right: 20px;
    position: relative;

    .fa-calendar {
      font-size: 14px;
      line-height: 1.25;
      position: absolute;
      top: 2px;
      left: 10px;
    }

    .custom-dropdown-width {
      width: 260px;

      .ant-select-selection {
        background-color: #E9ECF0;
        padding-left: 25px;

        .ant-select-selection__placeholder {
          color: #3d414a;
        }

        &:hover {
          background-color: #c4c7cb;
        }
      }
    }
  }
}

.count {
  align-items: center;
  background-color: #4697CE;
  border-radius: 50%;
  color: #fff!important;
  display: inline-flex;
  height: 18px;
  justify-content: center;
  margin-left: 10px;
  width: 18px;
  text-align: center;

  &.done {
    background-color: #7DCC93;
  }
}

.apexcharts-menu-icon{
  display: none;
}

.dropdown-button-calender{
  margin-right: 20px !important;
  &:hover{
    color: #000;
  }
}

.default-image-top-transaction{
  width:100%;
  height: 360px;
}


.new-checks { padding-top: 20px;}
.new-checks .checkbox:checked ~ .background-color{
	background-color: #fff;
}
.new-checks span{ font-size: 13px; font-weight: 400; padding-top: 5px; line-height: 18px; display: block;}

.new-checks [type="checkbox"]:checked,
.new-checks [type="checkbox"]:not(:checked),
.new-checks [type="radio"]:checked,
.new-checks [type="radio"]:not(:checked){
	position: absolute;
	left: -9999px;
	width: 0;
	height: 0;
	visibility: hidden;
}
.new-checks .checkbox:checked + label,
.new-checks .checkbox:not(:checked) + label{
	position: relative;
	width: 70px;
	display: inline-block;
	padding: 0;
	margin: 0 auto;
	text-align: center;
	margin: 17px 0;
	margin-top: 100px;
	height: 6px;
	border-radius: 4px;
	// background-image: linear-gradient(298deg, var(#4ca185), var(#fff));
  background: linear-gradient(#ffffff, #dddddd); 
	z-index: 100 !important;
}
.new-checks .checkbox:checked + label:before,
.new-checks .checkbox:not(:checked) + label:before {
	position: absolute;
	font-family: 'unicons';
	cursor: pointer;
	top: -17px;
	z-index: 2;
	font-size: 18px;
	line-height: 40px;
	text-align: center;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	-webkit-transition: all 300ms linear;
	transition: all 300ms linear; 
}
.new-checks .checkbox:not(:checked) + label:before {
	content: '\eac1';
	left: 0;
	color: #000;
  background: linear-gradient(#ffffff, #e7e7e7); 
	// background-color: #f1f1f1;
	box-shadow: 0 4px 4px rgba(0,0,0,0.15), 0 0 0 1px rgba(26,53,71,0.07);
}
.new-checks .checkbox:checked + label:before {
	content: '\eb8f';
	left: 30px;
	color: #000;
	background-color: #fff;
	box-shadow: 0 4px 4px rgba(26,53,71,0.25), 0 0 0 1px rgba(26,53,71,0.07);
}

.new-checks .checkbox:checked ~ .section .container .row .col-12 p{
	color: var(--dark-blue);
}


.new-checks .checkbox-toolss:checked + label,
.new-checks .checkbox-toolss:not(:checked) + label{
	position: relative;
	display: inline-block;
	padding: 15px 10px 15px 70px;
	width: 95% !important;
	font-size: 20px;
	line-height: 20px;
	letter-spacing: 1px;
	margin: 0 auto; font-weight: 400;
	margin-left: 0px;
	margin-right: 0px;
	margin-bottom: 25px;
	text-align: center;
	border-radius: 4px;
	overflow: hidden;
	cursor: pointer;
	// text-transform: uppercase;
	color: #000;
	-webkit-transition: all 300ms linear;
	transition: all 300ms linear; 
  min-height: 70px;
}
.new-checks .checkbox-toolss:not(:checked) + label{
	// background-color: #f9f9f9;
  // 
  // background: #38bb90;
	// box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  background: rgb(235, 235, 235);
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
.new-checks .checkbox-toolss:checked + label{
	background-color: transparent;
	box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    // color: #fff !important; background: #38bb90;
    color: rgb(63, 62, 62) !important; background: #fff;
}
.new-checks .checkbox-toolss:not(:checked) + label:hover{
	box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
.new-checks .checkbox-toolss:checked + label::before,
.new-checks .checkbox-toolss:not(:checked) + label::before{
	position: absolute;
	content: '';
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border-radius: 4px;
	/* background-image: linear-gradient(298deg, var(#4ca185), var(#51a35d)); */
    background: linear-gradient(#ffffff, #d8d8d8); 
	z-index: -1;
}
.new-checks .checkbox-toolss:checked + label .uil,
.new-checks .checkbox-toolss:not(:checked) + label .uil{
	font-size: 20px;
	line-height: 24px;
	display: block;
	padding-bottom: 10px; color: #000;
}
.cltys1{ position: absolute; left: 10px; top: 20px; font-size: 40px;}

.new-checks .dontaion{background: url(../../assets/images/donationh-icons.png) no-repeat center top;
  background-size: 100%; width: 45px; height: 60px;
}
.new-checks .checkbox-toolss:checked + label .dontaion {background: url(../../assets/images/donationh-icons.png) no-repeat center top;
    background-size: 100%; 
}


.new-checks .income-ex{background: url(../../assets/images/income-exh.png) no-repeat center top;
  background-size: 100%; width: 45px; height: 60px;
}
.new-checks .checkbox-toolss:checked + label .income-ex {background: url(../../assets/images/income-exh.png) no-repeat center top;
    background-size: 100%; 
}
.new-checks .balance-sheet{background: url(../../assets/images/balance-sheeth.png) no-repeat center top;
  background-size: 100%; width: 45px; height: 60px;
}
.new-checks .checkbox-toolss:checked + label .balance-sheet {background: url(../../assets/images/balance-sheeth.png) no-repeat center top;
    background-size: 100%; 
}


.new-checks .pl-icon{background: url(../../assets/images/plh-icon.png) no-repeat center top;
  background-size: 100%; width: 45px; height: 60px;
}
.new-checks .checkbox-toolss:checked + label .pl-icon {background: url(../../assets/images/plh-icon.png) no-repeat center top;
    background-size: 100%; 
}

.libe-box{ width:100%; float: right; background: #797878; height:1px; margin: 8px 0px 8px 0%; } 
.new-checks .checkbox-toolss:checked + label .libe-box{  background: #1f1e1e; } 
.lafr{ font-size: 18px !important;}


.account-integsmd{ margin:10px;  
  position: relative; 
border: 1px  solid #f0f0f0 !important; 
-webkit-box-shadow: 0px 7px 30px 0px rgba(235,235,235,1);
-moz-box-shadow: 0px 7px 30px 0px rgba(235,235,235,1);
box-shadow: 0px 7px 30px 0px rgba(235,235,235,1);
background: #fff; width:auto;
border-radius: 6px; min-height: 300px;
}
.account-integsmd p{ padding: 5px 10px 5px 20px !important;}
.top-box-hedd{ position: relative; 
height: 60px; 
border-bottom: solid 1px #f0f0f0;
padding: 10px 20px 5px; font-size: 20px; font-weight: 600;
}


.ant-spin-container,
.inner-table-caption{
  // padding-right: 10px !important;
}

.box-middle-txt{
  display: flex;
  align-items: center;
  justify-content: center;
}

$fs-11: 11px;

$green-header: #698c54eb;
$green-body: #54753feb;

$darkGray-header: #39516c;
$darkGray-body: #2b3d51;

$blue-header: #234f88;
$blue-body: #063875;

$orange-header: #88532b;
$orange-body: #88532b;

$magenta-header: #6f223c;
$magenta-body: #6f223c;

$purple-header: #442452;
$purple-body: #442452;

// $lightBlue-body: #dad8dc;
// $lightRed-body: #ffcd58dc;
// $lightOrange-body: #ff9736dc;
// $lightGreen-body: #cc7338dc;

$lightBlue-body: #ffffff;
$lightRed-body: #ffffff;
$lightOrange-body: #ffffff;
$lightGreen-body: #ffffff;

.tiles-wrapper-dashboard {
    display: flex;
    flex-wrap: wrap;
    margin: 20px 0 0;

    .tile-container-dashboard {
        background: #ffffff;
        cursor: pointer;
        width: 20%;
        margin: 0 10px 10px 0;
        border-radius: 4px;
        height: 90px;
        // padding-left: 8px;
        // padding-right: 8px;
        flex-grow: inherit;
        // flex-basis: 226px;

        // border-radius: 0px, 10px, 0px, 10px;
        // box-shadow: 0 10px 10px rgba(0, 0, 0, 0.22), 0 0 8px rgba(0, 0, 0, 0.10);
        &.tile1{
          border-bottom:2px solid #5555E3;
          // background-color: ;
        }
        &.tile2{
          
          border-bottom:2px solid #BF4CC8
         
        }
        &.tile3{
          
          border-bottom:2px solid #1CB980
          
        }
        &.tile4{
          
          border-bottom:2px solid #FF000D
          
        }
        &.tile5{
          
          border-bottom:2px solid #0075FF
         
        }
        &.tile6{
          
          border-bottom:2px solid #F6773D
          // background-color: ;
        }
        &:hover {

            .tile-body-dashboard {
                i{
                    // color: #fff;
                }
            }
        }

        &:last-child {
            margin-right: 0;
        }

        .tile-header-dashboard {
            font-size: $fs-11;
            background: #999;
            padding: 6px 10px;
            color: #fff;
            text-transform: uppercase;
            border-radius: 2px;
        }

        .tile-body-dashboard {
            padding: 10px;
            height: 90px;
            display: flex;
            // flex-direction: column;
            justify-content: space-between;
            align-items: center;

          .left-tile-body-dashboard{
            padding-top: 0;
            display: flex;
            flex-direction: column;
          }
          .tile-name-dashboard{
            font-size: 14px;
            font-weight: bold;
          }
            .ant-icons{
                font-size: 24px;
                line-height: 30px;
            }
            .tile-name{
                font-size: 12px;
            }

            // i {
            //     // font-size: 24px;
            //     // color: rgba(255, 255, 255, .6);

            //     * {
            //         font-size: 32px;
            //     }
            // }
            .count-value-dashboard {
                display: inline-flex;
                // padding: 20px 0px;
                padding-top:0 ;
                // flex-direction: column;
                
            
                :last-child{
                    margin-left: auto;
                    margin-top: auto;

                    .ant-btn{
                        background-color: #ecf5ff;
                        border-color: #ecf5ff;
                        color:#0C1636;
                        font-size: small;
                        padding: 5px 8px;

                        :active{
                            background-color: #ecf5ff;
                            border-color: #ecf5ff;
                        }
                        :hover{
                            opacity: 0.5;
                        }
                    }
                }
            // &.tile-count-info {
            //     display: flex;
            //     height: 124px;
            //     flex-direction: column;
            //     justify-content: space-between;
                
            // }

                .tile-count-dashboard {
                    font-size: 14px;
                    font-weight: 700;
                    // padding: 0px;
                    border-radius: 10px;
                    color:#0C1636;
                   
 

                    // &::after {
                    //     clear: both;
                    //     content: ' ';
                    // }

                    .icon-currency-dashboard{
                        .fa{
                            font-size: 12px;
                            padding-right: 3px;
                            font-weight: 700;
                            color:#0C1636 !important;
                        }
                    }
                }
            }
            
        }

        .tile-footer-dashboard {
            font-size: $fs-11;
            padding: 6px 10px;
            text-align: right;
            color: #fff;
            text-transform: uppercase;
            border-radius: 2px;
        }
    }


    // .tile-container.default {
    //     background: #dcdcdc;
    //     border-radius: 4px;
    //     .tile-header,
    //     .tile-footer {
    //         background: #999;
    //     }
    // }

    // .tile-container.green-tile {
    //     background: $lightRed-body;
    //     border-radius: 4px;
    //     .tile-header,
    //     .tile-footer {
    //         background: $green-header;
    //     }
    // }

    // .tile-container.darkGray-tile {
    //     background: $lightBlue-body;
    //     border-radius: 4px;

    //     .tile-header,
    //     .tile-footer {
    //         background: $darkGray-header;
    //     }
    // }

    // .tile-container.blue-tile {
    //     background: $lightOrange-body;
    //     border-radius: 4px;
    //     .tile-header,
    //     .tile-footer {
    //         background: $blue-header;
    //     }
    // }

    // .tile-container.orange-tile {
    //     background: $orange-body;
    //     border-radius: 4px;
    //     .tile-header,
    //     .tile-footer {
    //         background: $orange-header;
    //     }
    // }

    // .tile-container.magenta-tile {
    //     background: $lightGreen-body;
    //     border-radius: 4px;
    //     .tile-header,
    //     .tile-footer {
    //         background: $magenta-header;
    //     }
    // }

    // .tile-container.purple-tile {
    //     background: $purple-body;
    //     border-radius: 4px;
    //     .tile-header,
    //     .tile-footer {
    //         background: $purple-header;
    //     }
    // }
}

.view-dashboard{
  height: unset;
  padding:20px 0 0;
  border-radius: 2px;
  .inner-view-dashboard{
    height: 304px;
    // width: 700px;
    background-color: #ffffff;

    .row-data-dashboard{
      // height: 300px;
      // width: 700px;
      // color: #ffffff;
      background-color: #ffffff;
      display: flex;
      justify-content: space-between;


    }
    .inner-heading-title{
      font-size: 15px;
      padding-top: 10px;
      // padding-bottom: 20px;
      padding-left: 18px;
      font-size: 16px;
      color: #58596C;
      font-weight: 500;
  
    }
    
  }
  
  .inner-view-dashboard-b{
    // width: ;
    background-color: #ffffff;
    height: 350px;
    .row-data-dashboard-b{
      // height: ;
      width: fit-content;
      // color: #ffffff;
      background-color: #ffffff;


    }
    .inner-heading-title-b{
      font-size: 15px;
      padding-top: 20px;
      padding-bottom: 20px;
      padding-left: 18px;
      font-size: 16px;
      color: #58596C;
      font-weight: 500;
  
    }
  }

  
  
}

.dashboard-welcome-heading{
  font-size: 20px;
    font-weight: 700;
    margin: 16px;
}
@media only screen and (min-width: 1920px){
  .tiles-wrapper-dashboard {
    display: flex;
    flex-wrap: wrap;
    margin: 20px 0 0 0;

    .tile-container-dashboard {
        background: #ffffff;
        cursor: pointer;
        width: 20%;
        margin: 0 10px 10px 0;
        border-radius: 4px;
        height: 90px;
        // padding-left: 8px;
        // padding-right: 8px;
        flex-grow: inherit;
        // flex-basis: 290px;

        // border-radius: 0px, 10px, 0px, 10px;
        // box-shadow: 0 10px 10px rgba(0, 0, 0, 0.22), 0 0 8px rgba(0, 0, 0, 0.10);

        &:hover {

            .tile-body-dashboard {
                i{
                    // color: #fff;
                }
            }
        }

        &:last-child {
            margin-right: 0;
        }

        .tile-header-dashboard {
            font-size: $fs-11;
            background: #999;
            padding: 6px 10px;
            color: #fff;
            text-transform: uppercase;
            border-radius: 2px;
        }

        .tile-body-dashboard {
            padding: 10px;
            height: 90px;
            display: flex;
            // flex-direction: column;
            justify-content: space-between;
            align-items: center;

          .left-tile-body-dashboard{
            padding-top: 0;
            display: flex;
            flex-direction: column;
          }
          .tile-name-dashboard{
            font-size: 14px;
            font-weight: bold;
          }
            .ant-icons{
                font-size: 24px;
                line-height: 30px;
            }
            .tile-name{
                font-size: 12px;
            }

            // i {
            //     // font-size: 24px;
            //     // color: rgba(255, 255, 255, .6);

            //     * {
            //         font-size: 32px;
            //     }
            // }
            .count-value-dashboard {
                display: inline-flex;
                // padding: 20px 0px;
                padding-top:0 ;
                // flex-direction: column;
                
            
                :last-child{
                    margin-left: auto;
                    margin-top: auto;

                    .ant-btn{
                        background-color: #ecf5ff;
                        border-color: #ecf5ff;
                        color:#0C1636;
                        font-size: small;
                        padding: 5px 8px;

                        :active{
                            background-color: #ecf5ff;
                            border-color: #ecf5ff;
                        }
                        :hover{
                            opacity: 0.5;
                        }
                    }
                }
            // &.tile-count-info {
            //     display: flex;
            //     height: 124px;
            //     flex-direction: column;
            //     justify-content: space-between;
                
            // }

                .tile-count-dashboard {
                    font-size: 14px;
                    font-weight: 700;
                    // padding: 0px;
                    border-radius: 10px;
                    color:#0C1636;
                   
 

                    // &::after {
                    //     clear: both;
                    //     content: ' ';
                    // }

                    .icon-currency-dashboard{
                        .fa{
                            font-size: 12px;
                            padding-right: 3px;
                            font-weight: 700;
                            color:#0C1636 !important;
                        }
                    }
                }
            }
            
        }

        .tile-footer-dashboard {
            font-size: $fs-11;
            padding: 6px 10px;
            text-align: right;
            color: #fff;
            text-transform: uppercase;
            border-radius: 2px;
        }
    }


    // .tile-container.default {
    //     background: #dcdcdc;
    //     border-radius: 4px;
    //     .tile-header,
    //     .tile-footer {
    //         background: #999;
    //     }
    // }

    // .tile-container.green-tile {
    //     background: $lightRed-body;
    //     border-radius: 4px;
    //     .tile-header,
    //     .tile-footer {
    //         background: $green-header;
    //     }
    // }

    // .tile-container.darkGray-tile {
    //     background: $lightBlue-body;
    //     border-radius: 4px;

    //     .tile-header,
    //     .tile-footer {
    //         background: $darkGray-header;
    //     }
    // }

    // .tile-container.blue-tile {
    //     background: $lightOrange-body;
    //     border-radius: 4px;
    //     .tile-header,
    //     .tile-footer {
    //         background: $blue-header;
    //     }
    // }

    // .tile-container.orange-tile {
    //     background: $orange-body;
    //     border-radius: 4px;
    //     .tile-header,
    //     .tile-footer {
    //         background: $orange-header;
    //     }
    // }

    // .tile-container.magenta-tile {
    //     background: $lightGreen-body;
    //     border-radius: 4px;
    //     .tile-header,
    //     .tile-footer {
    //         background: $magenta-header;
    //     }
    // }

    // .tile-container.purple-tile {
    //     background: $purple-body;
    //     border-radius: 4px;
    //     .tile-header,
    //     .tile-footer {
    //         background: $purple-header;
    //     }
    // }
}
}
@media only screen and (max-width: 1440px){
  .tiles-wrapper-dashboard {
    display: flex;
    flex-wrap: wrap;
    margin: 20px 0 0 0;

    .tile-container-dashboard {
        background: #ffffff;
        cursor: pointer;
        width: 20%;
        margin: 0 10px 10px 0;
        border-radius: 4px;
        height: 90px;
        // padding-left: 8px;
        // padding-right: 8px;
        flex-grow: inherit;
        // flex-basis: 318px;

        // border-radius: 0px, 10px, 0px, 10px;
        // box-shadow: 0 10px 10px rgba(0, 0, 0, 0.22), 0 0 8px rgba(0, 0, 0, 0.10);

        &:hover {

            .tile-body-dashboard {
                i{
                    // color: #fff;
                }
            }
        }

        &:last-child {
            margin-right: 0;
        }

        .tile-header-dashboard {
            font-size: $fs-11;
            background: #999;
            padding: 6px 10px;
            color: #fff;
            text-transform: uppercase;
            border-radius: 2px;
        }

        .tile-body-dashboard {
            padding: 10px;
            height: 90px;
            display: flex;
            // flex-direction: column;
            justify-content: space-between;
            align-items: center;

          .left-tile-body-dashboard{
            padding-top: 0;
            display: flex;
            flex-direction: column;
          }
          .tile-name-dashboard{
            font-size: 14px;
            font-weight: bold;
          }
            .ant-icons{
                font-size: 24px;
                line-height: 30px;
            }
            .tile-name{
                font-size: 12px;
            }

            // i {
            //     // font-size: 24px;
            //     // color: rgba(255, 255, 255, .6);

            //     * {
            //         font-size: 32px;
            //     }
            // }
            .count-value-dashboard {
                display: inline-flex;
                // padding: 20px 0px;
                padding-top:0 ;
                // flex-direction: column;
                
            
                :last-child{
                    margin-left: auto;
                    margin-top: auto;

                    .ant-btn{
                        background-color: #ecf5ff;
                        border-color: #ecf5ff;
                        color:#0C1636;
                        font-size: small;
                        padding: 5px 8px;

                        :active{
                            background-color: #ecf5ff;
                            border-color: #ecf5ff;
                        }
                        :hover{
                            opacity: 0.5;
                        }
                    }
                }
            // &.tile-count-info {
            //     display: flex;
            //     height: 124px;
            //     flex-direction: column;
            //     justify-content: space-between;
                
            // }

                .tile-count-dashboard {
                    font-size: 14px;
                    font-weight: 700;
                    // padding: 0px;
                    border-radius: 10px;
                    color:#0C1636;
                   
 

                    // &::after {
                    //     clear: both;
                    //     content: ' ';
                    // }

                    .icon-currency-dashboard{
                        .fa{
                            font-size: 12px;
                            padding-right: 3px;
                            font-weight: 700;
                            color:#0C1636 !important;
                        }
                    }
                }
            }
            
        }

        .tile-footer-dashboard {
            font-size: $fs-11;
            padding: 6px 10px;
            text-align: right;
            color: #fff;
            text-transform: uppercase;
            border-radius: 2px;
        }
    }


    // .tile-container.default {
    //     background: #dcdcdc;
    //     border-radius: 4px;
    //     .tile-header,
    //     .tile-footer {
    //         background: #999;
    //     }
    // }

    // .tile-container.green-tile {
    //     background: $lightRed-body;
    //     border-radius: 4px;
    //     .tile-header,
    //     .tile-footer {
    //         background: $green-header;
    //     }
    // }

    // .tile-container.darkGray-tile {
    //     background: $lightBlue-body;
    //     border-radius: 4px;

    //     .tile-header,
    //     .tile-footer {
    //         background: $darkGray-header;
    //     }
    // }

    // .tile-container.blue-tile {
    //     background: $lightOrange-body;
    //     border-radius: 4px;
    //     .tile-header,
    //     .tile-footer {
    //         background: $blue-header;
    //     }
    // }

    // .tile-container.orange-tile {
    //     background: $orange-body;
    //     border-radius: 4px;
    //     .tile-header,
    //     .tile-footer {
    //         background: $orange-header;
    //     }
    // }

    // .tile-container.magenta-tile {
    //     background: $lightGreen-body;
    //     border-radius: 4px;
    //     .tile-header,
    //     .tile-footer {
    //         background: $magenta-header;
    //     }
    // }

    // .tile-container.purple-tile {
    //     background: $purple-body;
    //     border-radius: 4px;
    //     .tile-header,
    //     .tile-footer {
    //         background: $purple-header;
    //     }
    // }
}
}
@media only screen and (max-width: 1280px){
  .tiles-wrapper-dashboard {
    display: flex;
    flex-wrap: wrap;
    margin: 20px 0 0 0;

    .tile-container-dashboard {
        background: #ffffff;
        cursor: pointer;
        width: 20%;
        margin: 0 10px 10px 0;
        border-radius: 4px;
        height: 90px;
        // padding-left: 8px;
        // padding-right: 8px;
        flex-grow: inherit;
        // flex-basis: 278px;

        // border-radius: 0px, 10px, 0px, 10px;
        // box-shadow: 0 10px 10px rgba(0, 0, 0, 0.22), 0 0 8px rgba(0, 0, 0, 0.10);

        &:hover {

            .tile-body-dashboard {
                i{
                    // color: #fff;
                }
            }
        }

        &:last-child {
            margin-right: 0;
        }

        .tile-header-dashboard {
            font-size: $fs-11;
            background: #999;
            padding: 6px 10px;
            color: #fff;
            text-transform: uppercase;
            border-radius: 2px;
        }

        .tile-body-dashboard {
            padding: 10px;
            height: 90px;
            display: flex;
            // flex-direction: column;
            justify-content: space-between;
            align-items: center;

          .left-tile-body-dashboard{
            padding-top: 0;
            display: flex;
            flex-direction: column;
          }
          .tile-name-dashboard{
            font-size: 14px;
            font-weight: bold;
          }
            .ant-icons{
                font-size: 24px;
                line-height: 30px;
            }
            .tile-name{
                font-size: 12px;
            }

            // i {
            //     // font-size: 24px;
            //     // color: rgba(255, 255, 255, .6);

            //     * {
            //         font-size: 32px;
            //     }
            // }
            .count-value-dashboard {
                display: inline-flex;
                // padding: 20px 0px;
                padding-top:0 ;
                // flex-direction: column;
                
            
                :last-child{
                    margin-left: auto;
                    margin-top: auto;

                    .ant-btn{
                        background-color: #ecf5ff;
                        border-color: #ecf5ff;
                        color:#0C1636;
                        font-size: small;
                        padding: 5px 8px;

                        :active{
                            background-color: #ecf5ff;
                            border-color: #ecf5ff;
                        }
                        :hover{
                            opacity: 0.5;
                        }
                    }
                }
            // &.tile-count-info {
            //     display: flex;
            //     height: 124px;
            //     flex-direction: column;
            //     justify-content: space-between;
                
            // }

                .tile-count-dashboard {
                    font-size: 14px;
                    font-weight: 700;
                    // padding: 0px;
                    border-radius: 10px;
                    color:#0C1636;
                   
 

                    // &::after {
                    //     clear: both;
                    //     content: ' ';
                    // }

                    .icon-currency-dashboard{
                        .fa{
                            font-size: 12px;
                            padding-right: 3px;
                            font-weight: 700;
                            color:#0C1636 !important;
                        }
                    }
                }
            }
            
        }

        .tile-footer-dashboard {
            font-size: $fs-11;
            padding: 6px 10px;
            text-align: right;
            color: #fff;
            text-transform: uppercase;
            border-radius: 2px;
        }
    }


    // .tile-container.default {
    //     background: #dcdcdc;
    //     border-radius: 4px;
    //     .tile-header,
    //     .tile-footer {
    //         background: #999;
    //     }
    // }

    // .tile-container.green-tile {
    //     background: $lightRed-body;
    //     border-radius: 4px;
    //     .tile-header,
    //     .tile-footer {
    //         background: $green-header;
    //     }
    // }

    // .tile-container.darkGray-tile {
    //     background: $lightBlue-body;
    //     border-radius: 4px;

    //     .tile-header,
    //     .tile-footer {
    //         background: $darkGray-header;
    //     }
    // }

    // .tile-container.blue-tile {
    //     background: $lightOrange-body;
    //     border-radius: 4px;
    //     .tile-header,
    //     .tile-footer {
    //         background: $blue-header;
    //     }
    // }

    // .tile-container.orange-tile {
    //     background: $orange-body;
    //     border-radius: 4px;
    //     .tile-header,
    //     .tile-footer {
    //         background: $orange-header;
    //     }
    // }

    // .tile-container.magenta-tile {
    //     background: $lightGreen-body;
    //     border-radius: 4px;
    //     .tile-header,
    //     .tile-footer {
    //         background: $magenta-header;
    //     }
    // }

    // .tile-container.purple-tile {
    //     background: $purple-body;
    //     border-radius: 4px;
    //     .tile-header,
    //     .tile-footer {
    //         background: $purple-header;
    //     }
    // }
}
}
@media only screen and (min-width: 1921px){
  .tiles-wrapper-dashboard {
    display: flex;
    flex-wrap: wrap;
    margin: 20px 0 0 0;

    .tile-container-dashboard {
        background: #ffffff;
        cursor: pointer;
        width: 20%;
        margin: 0 10px 10px 0;
        border-radius: 4px;
        height: 90px;
        // padding-left: 8px;
        // padding-right: 8px;
        flex-grow:unset;
        flex-basis: auto;

        // border-radius: 0px, 10px, 0px, 10px;
        // box-shadow: 0 10px 10px rgba(0, 0, 0, 0.22), 0 0 8px rgba(0, 0, 0, 0.10);

        &:hover {

            .tile-body-dashboard {
                i{
                    // color: #fff;
                }
            }
        }

        &:last-child {
            margin-right: 0;
        }

        .tile-header-dashboard {
            font-size: $fs-11;
            background: #999;
            padding: 6px 10px;
            color: #fff;
            text-transform: uppercase;
            border-radius: 2px;
        }

        .tile-body-dashboard {
            padding: 10px;
            height: 90px;
            display: flex;
            // flex-direction: column;
            justify-content: space-between;
            align-items: center;

          .left-tile-body-dashboard{
            padding-top: 0;
            display: flex;
            flex-direction: column;
          }
          .tile-name-dashboard{
            font-size: 14px;
            font-weight: bold;
          }
            .ant-icons{
                font-size: 24px;
                line-height: 30px;
            }
            .tile-name{
                font-size: 12px;
            }

            // i {
            //     // font-size: 24px;
            //     // color: rgba(255, 255, 255, .6);

            //     * {
            //         font-size: 32px;
            //     }
            // }
            .count-value-dashboard {
                display: inline-flex;
                // padding: 20px 0px;
                padding-top:0 ;
                // flex-direction: column;
                
            
                :last-child{
                    margin-left: auto;
                    margin-top: auto;

                    .ant-btn{
                        background-color: #ecf5ff;
                        border-color: #ecf5ff;
                        color:#0C1636;
                        font-size: small;
                        padding: 5px 8px;

                        :active{
                            background-color: #ecf5ff;
                            border-color: #ecf5ff;
                        }
                        :hover{
                            opacity: 0.5;
                        }
                    }
                }
            // &.tile-count-info {
            //     display: flex;
            //     height: 124px;
            //     flex-direction: column;
            //     justify-content: space-between;
                
            // }

                .tile-count-dashboard {
                    font-size: 14px;
                    font-weight: 700;
                    // padding: 0px;
                    border-radius: 10px;
                    color:#0C1636;
                   
 

                    // &::after {
                    //     clear: both;
                    //     content: ' ';
                    // }

                    .icon-currency-dashboard{
                        .fa{
                            font-size: 12px;
                            padding-right: 3px;
                            font-weight: 700;
                            color:#0C1636 !important;
                        }
                    }
                }
            }
            
        }

        .tile-footer-dashboard {
            font-size: $fs-11;
            padding: 6px 10px;
            text-align: right;
            color: #fff;
            text-transform: uppercase;
            border-radius: 2px;
        }
    }


    // .tile-container.default {
    //     background: #dcdcdc;
    //     border-radius: 4px;
    //     .tile-header,
    //     .tile-footer {
    //         background: #999;
    //     }
    // }

    // .tile-container.green-tile {
    //     background: $lightRed-body;
    //     border-radius: 4px;
    //     .tile-header,
    //     .tile-footer {
    //         background: $green-header;
    //     }
    // }

    // .tile-container.darkGray-tile {
    //     background: $lightBlue-body;
    //     border-radius: 4px;

    //     .tile-header,
    //     .tile-footer {
    //         background: $darkGray-header;
    //     }
    // }

    // .tile-container.blue-tile {
    //     background: $lightOrange-body;
    //     border-radius: 4px;
    //     .tile-header,
    //     .tile-footer {
    //         background: $blue-header;
    //     }
    // }

    // .tile-container.orange-tile {
    //     background: $orange-body;
    //     border-radius: 4px;
    //     .tile-header,
    //     .tile-footer {
    //         background: $orange-header;
    //     }
    // }

    // .tile-container.magenta-tile {
    //     background: $lightGreen-body;
    //     border-radius: 4px;
    //     .tile-header,
    //     .tile-footer {
    //         background: $magenta-header;
    //     }
    // }

    // .tile-container.purple-tile {
    //     background: $purple-body;
    //     border-radius: 4px;
    //     .tile-header,
    //     .tile-footer {
    //         background: $purple-header;
    //     }
    // }
}
}
.custom-Skeleton{
.ant-skeleton-content{
  padding: 0 !important;
}
}