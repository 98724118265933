@import '~antd/dist/antd.css';
@import '../common.scss';

.signup-wrapper {
  background: url('./../../assets/images/Top_Banner_Image.jpg') no-repeat;
  background-size: cover;

  .signup-panel {
    @include white-wrapper;
    border-radius: 6px;
    height: calc(100vh - 40px);
    margin-top: 20px;
    margin-bottom: 20px;
    position: relative;
    width: 700px;
  }

  .heading-title {
    justify-content: center;
    border-bottom: 1px solid #e8e8e8;
    display: flex;
    display: -ms-flexbox;
    margin-bottom: 0;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .mandatory-msg {
    display: flex;
    display: -ms-flexbox;
    font-size: 12px;
    justify-content: flex-end;
    opacity: .8;
    padding-top: 5px;
    padding-right: 58px;
  }

  .signup-form-container {
    max-height: calc(100vh - 175px);
    overflow-y: auto;
    padding: 20px 50px 0 30px;

    .signup-field-align {
      font-weight: 600;
    }

    .country-code {
      font-weight: 600;
    }

    .signup-action-container {
      border-top: 1px solid #e8e8e8;
      display: flex;
      justify-content: space-between;
      padding: 15px 20px;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
    }
  }

  .ant-select-selection {
    color: #dfdde9;
  }

  .ant-select-selection:hover {
    border-color: $green-btn-border;
  }

  .ant-select-selection:focus {
    border-color: $green-btn-border;
  }

  .signup-field-align {
    text-align: end;
    padding: 0 30px;
  }

  form .ant-input-group .ant-select, form .ant-input-group .ant-cascader-picker {
    width: 100%;
  }

  .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
    border-top: 1px solid $white;
    border-right: 1px solid $white;
    border-left: 1px solid $white;
    border-bottom: 1px solid #e8e8e8;
    color: #a9adb7;
    background-color: $white;
  }

  .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab:hover {
    color: #6f7177;
  }

  .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
    border-top: 1px solid #e8e8e8;
    border-left: 1px solid #e8e8e8;
    border-right: 1px solid #e8e8e8;
    border-bottom: 1px solid $white;
    color: #6f7177;
    background-color: $white;
  }

  .pl-8 {
    padding-left: 8px;
  }

  hr {
    border-top: 1px solid #e8e8e8;
    border-bottom: none;
    border-right: none;
    border-left: none;
    margin-bottom: 20px;
  }

  .signup-cancel {
    @include default-btn-style;
  }

  .signup-cancel:hover {
    background-color: #cbd2db;
  }

  .signup-create-account {
    @include green-btn-style;
  }

  .signup-create-account:hover {
    background-color: #357360;
  }
}
.pop-bgsm .ant-modal-content{
  background: linear-gradient(to left, #2c7045 40%, #fff 40%);
}

.foots{ position: fixed; bottom: 0px; padding: 25px 10% 15px;
width: 100%; left: 0px; background: #fff; border-top: solid 1px #e9ecf0; height: 60px;}

.headspce{border-Bottom: 1px solid #e5e5e5; width:56%; padding-Top:50px; }
.fotyeu{border-Top: 1px solid #e5e5e5; width:56%;}
.payfont{position: absolute; top: 0px; left: 0px; font-Size: 24px; color: #003e73;}
.pci-logo{position:absolute; top: 0px; left: 45%;}
.pop-bgsm .ant-modal-close-x{ color: #fff;}
.popupfont{ font-weight: 500; font-size: 15px; text-align: right; color: #222;}

.pop-bgsm .ant-modal-header{ background: initial; border-bottom: 0px !important;}
.pop-bgsm .ant-modal-content .ant-modal-body{ max-height:initial ;}

.tescrt{color: #8edcab; font-size: 15px; }
.spoi{font-size: 16px;  display: inline-block; color: #8edcab; padding-right: 18px; width: 40px; text-align:center;}
.spois{font-size: 16px;  display: inline-block; color: #000; padding-right: 18px; width: 40px; text-align:center;}
.lirty{color:#fff;font-size: 16px; font-Weight:600; border-Bottom:solid 1px #329355; padding:2px 42px 10px !important; margin-bottom: 0px;}
.lirtys{color:#fff;font-size: 16px; font-Weight:600; border-Bottom:solid 1px #e4e4e4; padding:10px 42px 10px; margin-bottom: 0px;}
.tuyicar{ width: 220px; border-radius: 50px; margin: 0px ; color: #fff; 
  overflow: hidden; height: 40px; line-height: 40px;}
.tuyicar .leftb{background: #2c7045; cursor: pointer; float: left; width: 50%; text-align: center; height: 40px; }
.tuyicar .actives{background: #434e54;}
.tuyicar .rightb{background: #2c7045;cursor: pointer; 
  float: left; width: 50%; text-align: center; height: 40px;}

  // .pop-bgsm .ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
  //   background-color: #1d5d35;
  //   border: 1px solid #13f064;
  //   color: #fff; 
  // }
  // .pop-bgsm .ant-select-arrow{ color: #fff;}
  // .pop-bgsm .ant-select-dropdown{ background-color: #1d5d35; color: #fff;}
  // .pop-bgsm .ant-picker { background-color: #1d5d35; color: #fff;  border: 1px solid #13f064;}
  // .pop-bgsm .ant-input-number { background-color: #1d5d35; color: #fff;  border: 1px solid #13f064;}
  // .pop-bgsm .ant-picker-suffix{ color: #fff;}
  .lertpi .ant-select-single:not(.ant-select-customize-input) .ant-select-selector{background-color: #e4e7eb;}
  .ifheight iframe{ height: auto;}
  @media only screen and (max-width: 1200px) {
    .ifheight iframe{ height: 200px;}
  }

@media only screen and (max-width: 767px) {
  .ifheight iframe{ height: 250px;}
  .ifheight iframe label{font-size: 13px;}
  .pop-bgsm .ant-modal-content{
     background: linear-gradient(to left, #fff 40%, #fff 40%) none;
    // background: #fff !important ;
 background: linear-gradient(to top, #2C7045 50%, #ffffff 50%);

  }
  .moptp{ margin-top: 50px;}
  .pci-logo{right: 20px; left: initial;}
  .fotyeu{border-Top: 1px solid #e5e5e5; width:100%; background: #fff; padding: 10px;}
  .payfont{position: absolute; top: 0px; left: 0px; font-Size: 18px; color: #003e73;}
  .headspce{border-Bottom: 1px solid #e5e5e5; width:100%; padding-Top:50px; }
  .pop-bgsm .ant-modal{width: 90% !important;}
  .pop-bgsm .ant-modal-close-x{ color: #000;}
  .popupfont{ text-align: left; font-size: 14px;}
  .tescrt{ 
    // color: #000;
  }
  .lirty{ 
    // color: #000; border-Bottom: solid 1px #232323; 
    padding: 2px 42px 10px;
    margin-bottom: 0px;}
  .spoi{ 
    // color: #000;
  }
  .information .pl35{ padding-left: 0px;}
  .information .pl40{ padding-left: 0px;}
  
}

@media only screen and (max-width: 400px) {

  // .ifheight iframe{ height: 300px !important;}
}

// @media only screen and (max-width: 767px){label{width:100%; font-size:14px; text-align:left; } #ccnumfield{width:100%;} #ccexpirymonth{width:42%;} #ccexpiryyear{width:42%;}}
