@import '~antd/dist/antd.css';
@import '../../common.scss';


$text-color: #58596C;
$primary-color: #0075FF;

.success-button {
  float: right;
}

.cancel-button {
  float: left;
}

.ant-legacy-form-item {
  margin-bottom: 10px;
}

.ant-checkbox-checked .ant-checkbox-inner {
  @include green-btn-style;
}

.ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner:hover {
  @include green-border;
}

.address-label {
  margin-bottom: 20px;
  color:$text-color
}

.ant-tabs-tab-active {
  background: #fff;
  color: #727475;
  border-color: #e8e8e8 !important;
  border-bottom: 1px solid #fff !important;
}

.tab-view {
  min-height: 260px;
}

.drawer-title {
  text-align: left;
  font-size: 16px;
  color: $text-color !important;
  text-decoration: 'bold';
}

//required filed code
.text-input-label {
  color: $text-color;
  .required {
    color: #e00000;
    display: inline-block;
    padding-left: 1px;
  }
  
}

.text-input-box{
  // min-height: 40px;
  // .ant-select-selector{
  //   min-height: 40px;
  //   display: flex;
  //   align-items: center;
  // }
}

.ant-drawer-body {


  .custom-dropdown-width {
    &.cell-dropdown {
      // margin-top: -22px;
    }
  }
  
}


.tab-head-label {
  // color: $text-color;
  font-size: 14px;

  :hover{
    color:red !important;
  }

}


.radio-grp{
  .ant-radio-inner {
    &:after {
      background-color: $primary-color !important;
    }
  }
  
  .ant-radio-checked .ant-radio-inner {
    border-color: $primary-color !important;
  }
  
  .ant-radio:hover .ant-radio-inner,
  .ant-radio-input:focus {
    border-color: $primary-color !important;
  }
}



.customer-tab-container {
  .ant-tabs-bar {
    border-bottom: 0;
  }

  .ant-tabs-nav {
    border-bottom: 1px solid #e8e8e8;

    .ant-tabs-tab {
      // border-bottom-color: transparent!important;
      padding: 2px 25px!important;
      color: $text-color !important;
      :hover{
        color: $primary-color ;
      }


      &.ant-tabs-tab-active {
        border-color: transparent!important;
        z-index: 90;

        span {
          color: #0075FF;
        }

        &:before {
          background-color: #0075FF;
          z-index: 99;
        }
      }

      &:before {
        border-radius: 0!important;
        top: unset!important;
        bottom: -1px!important;
        // left: -1px!important;
        height: 3px;
        width: 100%;
      }
    }
  }

  &.secondary-tab {
    .ant-tabs-bar {
      margin-bottom: 2px;
    }

    // &:before {
    //   border-radius: 0;
    //  top: unset!important;
    //   bottom: 0px!important;
    //   left: 0px!important;
    //   height: 0px;
    //   width: 100%;
    // }
    .ant-tabs-nav-wrap {
      background-color: #fff;
      border-radius: 6px;
      box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.05);
      padding-right: 10px;
      padding-left: 10px;
    }

    .ant-tabs-nav {
      border-bottom: transparent;

      .ant-tabs-tab {
        // background: #16a085;
        // border-color: transparent!important;
        padding: 6px 20px!important;  border-radius: 3px; color: #333;  margin: 0px 3px;
      }
     
    }
  }

  &.manage-bottom-space {
    .ant-tabs-nav-container {
      margin-bottom: 15px;
      margin-left: -10px;
    }
  }

  &.todo-tabs {
    .ant-tabs-nav-container {
      margin-top: 10px;
      margin-bottom: 15px;

      .ant-tabs-nav-wrap {
        padding-left: 0;

        .ant-tabs-tab {
          margin-right: 5px;
          padding-right: 20px;
          padding-left: 15px;

          &.ant-tabs-tab-active {
            &:before {
              bottom: 0!important;
            }
          }
        }
      }
    }
  }
}


.custom-customer-drawer {
  .ant-drawer-header {
    background: #fff;
    position: sticky;
    top: 0;
    z-index: 9999;
  }

  .ant-drawer-body {
    position: relative;

    .tab-view {
      padding-bottom: 50px;
    }

    .form-footer {
      background: #fff;
      // position: fixed;
      // bottom: 0;
      margin-top: 0 px;
      padding-right: 0px;
      padding-bottom: 0px;
      padding-left: 24px;
      padding-left: 24px;
      // width: 740px;

      .ant-btn-primary {
        margin-right: 25px;
      }
    }
  }
}

.login-form-checkbox{
  color: $text-color;
  & .ant-checkbox-checked .ant-checkbox-inner {
    background-color: $primary-color;
    border-color: $primary-color;
    :hover{
      color: $primary-color;
    }
    :focus{
      color: $primary-color;
    }
  }
}
.textbox-pd{
  height: 38px;
    padding: 0px 90px 0px 11px;
}