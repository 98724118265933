.ledger-details-container {
    margin: 20px 0;
  
    .ledger-details {
      background-color: #fff;
      padding-top: 10px;
      padding-bottom: 50px;
      padding-left: 15px;
  
      .address-info {
        width: 150px;
        line-height: 2.2;
      }
  
      .ledger-identity-number {
        .ant-col-10 {
          color: #39414b;
          text-align: right;
          padding-right: 20px;
          padding-bottom: 10px;
        }
  
        .ant-col-14 {
          padding-bottom: 10px;
        }
      }
    }
  }
  .expendedView{
    .ledger-details-container{
        margin: 0;
    }
  }

 .ledger-account-container{
   padding-right: 10px;
  .ag-react-container{
    width: 100%;

    .table-header-column-container{
      display: flex;
      flex: 1 1 auto;
      overflow: hidden;
      align-items: center;
      text-overflow: ellipsis;
      align-self: stretch;
  
      .table-header-column{
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
       }
  
    }
  }

  .search-container{
    padding-bottom: 10px;

    .align-end {
      text-align: end;
    }
  }  
 } 
  