@import "../../assets//stylesheets//global/variable";

.chooseProductPage{
    background-color: #F5F5F5;
    height: 100vh;
    
    .headerContainer{
        background-color: #fff;
        padding: 1rem 0;

        .contaniner{
            max-width: 1200px;
            margin: 0 auto;
        }
    }
    .chooseProductContainer{

        .contaniner{
            max-width: 1200px;
            margin: 0 auto;

            .headingContent{
                text-align: center;
                margin: 3rem auto 0;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                h2{
                    font-size: 2.25rem;
                    margin-bottom: 0.313rem;
                    color: #071028;
                    font-weight: 700;
                }
                p{  
                    font-size: 0.875rem;
                    font-weight: 500;
                    color: #58596C;
                    width: 40%;
                }
            }

            .chooseProduct{
                margin: 3rem auto 0;
                display: grid;
                grid-template-columns: auto auto;
                gap: 1.5rem;
                width: 60%;

                .product{
                    padding: 1.5rem;
                    border-radius: 10px;
                    border: 2px solid transparent;
                    background:linear-gradient(white, white), radial-gradient(circle at top left, #FF59F8,#AE72FF, #90E9ED, #5CD167, #FFEE00);
                    background-clip:padding-box, border-box;
                    background-origin: border-box;
                    display: flex;
                    gap: 0.625rem;

                    .productName{
                        display: flex;
                        flex-direction: column;
                        gap: 0.625rem;

                        h3{
                            font-size: 1.5rem;
                            margin-bottom: 0;
                            color: #071028;
                            font-weight: 700;
                        }
                        p{
                            font-size: 0.875rem;
                            color: #031639; 
                        }
                        button{
                            cursor: pointer;
                            background-color: #fff;
                            border: 1px solid #0075FF;
                            border-radius: 4px;
                            color:#0075FF;
                            font-weight: 600;
                            font-size: 0.875rem;
                            width: fit-content;

                            &:hover{
                                background-color: #0075FF;
                                color: #ffffff;
                            }
                        }
                    }
                }
            }
        }
    }
}

.choose-product-modal{
    .ant-modal-content{
        margin-top: 20vh;
    }

    .chooseProductPopup{
    
        .chooseProductHeading{
            display: flex;
            align-items: center;
            gap:1rem;
    
            h2{
                font-size: 1.5rem;
                margin-bottom: 0;
            }
        }
        .chooseProductText{
            margin: 0.625rem 0 0;
            
            p{
                font-size: 0.875rem;
                color: #58596C;
                font-weight: 500;
    
                span{
                    font-weight: 700;
                }
    
            }
        }
        .formArea{
            margin: 1.25rem 0 0;
    
            input{
                border: 1px solid #F1F1F1;
                border-radius: 0.375rem;
                padding: 1.125rem;
                color: #959595;
                outline: none;
                font-size: 14px;
                width: 100%;
            }
            input::placeholder {
                color: #959595;
                font-size: 0.875rem;
            }
        }
        .dataPart{
            margin: 1rem 0 0;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 0.2rem;
            
    
            .data{
                font-size: 0.875rem;
                color: #071028;
    
                span{
                    color: #0075FF;
                }
            }
        }
        .selectionBox{
            margin: 1rem 0;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 0.5rem;
    
            label{
               color: #031639;
               font-size: 1rem;
               font-weight: 600;
            }
        }

        .buttonArea button{
            cursor: pointer;
            background-color: #0075FF;
            border: 1px solid #0075FF;
            border-radius: 4px;
            color:#fff;
            font-weight: 600;
            font-size: 0.875rem;
            width: -webkit-fill-available;
            height: 48px;
        }
    }
}