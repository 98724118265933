.logout-container {
  background-color: rgba(#16a085, .2);
  height: 100vh;
  padding: 100px;

  .center-view {
    box-shadow: 0 2px 2px rgba(0, 0, 0, .3);
    border-radius: 2px;
    margin-right: auto;
    margin-left: auto;
    overflow: hidden;
    width: 60%;

    .top-header {
      background-image: linear-gradient(#0ba383, #09a987);
      color: #fff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 30px;

      .title {
        font-size: 16px;
        font-weight: 600;
        text-align: center;
      }

      .checkmark {
        border-radius: 50%;
        box-shadow: inset 0 0 0 #16a085;
        display: block;
        height: 56px;
        margin: 20px auto;
        stroke: #16a085;
        stroke-miterlimit: 10;
        stroke-width: 3;
        width: 56px;
        animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;

        .checkmark-circle {
          fill: none;
          stroke: #fff;
          stroke-dasharray: 166;
          stroke-dashoffset: 166;
          stroke-miterlimit: 10;
          stroke-width: 2;
          animation: checked 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
        }

        .checkmark-check {
          stroke-dasharray: 48;
          stroke-dashoffset: 48;
          transform-origin: 50% 50%;
          animation: checked 0.3s cubic-bezier(.65, 0, .45, 1) 0.8s forwards;
        }
      }

      @keyframes checked {
        100% {
          stroke-dashoffset: 0;
        }
      }

      @keyframes scale {
        0%, 100% {
          transform: none;
        }

        50% {
          transform: scale3d(1.1, 1.1, 1);
        }
      }

      @keyframes fill {
        100% {
          box-shadow: inset 0 0 0 30px #fff;
        }
      }
    }

    .content {
      align-items: center;
      background-color: #fff;
      display: flex;
      min-height: 100px;
      justify-content: center;

      p {
        color: #2c2c2c;
        font-size: 16px;
        text-align: center;
      }
    }
  }

  .footer {
    align-items: center;
    background-color: #f3f3f3;
    border-top: 1px solid rgba(18, 129, 107, 0.21);
    display: flex;
    font-size: 14px;
    justify-content: space-between;
    padding: 20px 50px;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;

    .copy-right {
      font-weight: 500;
    }

    .footer-links {
      list-style-type: none;
      margin-right: 100px;
      margin-bottom: 0;

      li {
        display: inline-flex;
        position: relative;

        a, span {
          color: #656b79;
          cursor: pointer;
          font-weight: 500;
          margin-right: 20px;

          &:hover {
            color: #16a085;
            transform: translateY(-2px);
          }
        }

        &:last-child {
          a, span {
            margin-right: 0;
          }

          &:after {
            content: none;
          }
        }

        &:after {
          content: '';
          background-color: #8890a3;
          height: 18px;
          position: absolute;
          top: 3px;
          right: 8px;
          width: 1px;
        }
      }
    }

    .social-links {
      list-style-type: none;
      margin-bottom: 0;

      li {
        display: inline-flex;
        margin-right: 5px;

        a {
          background-color: #2c7045;
          color: #fff;
          border-radius: 2px;
          line-height: 24px;
          height: 24px;
          text-align: center;
          vertical-align: middle;
          width: 24px;

          &:hover {
            background-color: #4EA286;
          }
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}
