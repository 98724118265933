// global scss
@import "global/gutters";
@import "global/variable";
@import "global/mixin";
@import "global/button";
@import "global/ant-table";
@import "global/component";
@import "global/tabs";
@import "common/custom_table";
@import "common/button_dropdown";
@import "common/custom_modal";
@import "common/input-control";
@import "~@flaticon/flaticon-uicons/css/all/all";

$text-color: #58596c;

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  font-family: "Segoe UI", "Droid Sans", Tahoma, Arial, sans-serif;
  font-size: 12px;
  font-weight: 400;
  overflow: hidden;
  -webkit-font-smoothing: antialiased;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-size: 12px;
  // overflow-y: hidden;
}

::selection {
  background: #31bdbc;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 6px;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #aaa;
}
::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

a {
  color: #4697ce;
}

.w-100 {
  width: 100% !important;
}

.text-red {
  color: $error-red;
}

.page-title {
  color: #1f1e1e;
  font-size: 14px;
  text-align: left;
}

.vab {
  vertical-align: bottom;
}

.mt-5 {
  margin-top: -5px;
}

.right-align {
  display: flex;
  justify-content: flex-end;
}

.ant-modal-wrap {
  z-index: 9999;
}

.ant-notification {
  z-index: 99999 !important;
}

.ant-popover-placement-top {
  z-index: 9999;
}

.ant-popover {
  z-index: 9999;
}

.ant-tabs {
  overflow: unset;
}

.ck .ck-editor__main {
  border: 1px solid #c4c4c4 !important;
  max-height: 100px !important;
  overflow-y: auto !important;

  &:focus {
    box-shadow: none;
    outline: none;
  }

  p {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    line-height: 1.5;
  }
}

.ck.ck-editor__main>.ck-editor__editable:not(.ck-focused) {
  padding: 5px 10px;
}

.ck.ck-editor__main {
  .ck-content.ck-editor__editable {
    min-height: 95px;

    &.ck-focused {
      &:focus {
        border-color: #16a085 !important;
      }
    }
  }
}

.ant-btn-primary {
  background-color: $secondaryColor;
  border-color: $secondaryColor;

  &:hover,
  &:focus {
    background-color: $secondaryColor;
    border-color: $secondaryColor;
    color: #fff;
  }

  >span {
    color: #fff;
  }
}

// .ant-drawer {
//   .form-footer {
//     display: flex;
//     justify-content: space-between;
//     border-top: 1px solid #ddd;
//     padding-top: 20px;
//     margin-left: -25px;
//     margin-right: -20px;
//     padding-left: 25px;
//     padding-right: 20px;
//   }
// }

.ag-cell-inline-editing {
  .ant-cascader-picker {
    .ant-input {
      margin-top: -5px;
      padding-top: 0;
      padding-left: 8px;
    }

    .ant-cascader-picker-arrow {
      margin-top: -8px;
    }
  }
}

.incomplete {
  .ant-progress-inner {
    background-color: #a59d9d;
    border-radius: 3px;

    .ant-progress-bg {
      background-color: #339966 !important;
      border-radius: 3px;
      height: 16px !important;
    }
  }
}

.ant-progress {
  .ant-progress-outer {
    cursor: pointer;
    padding-right: 0;
    position: relative;
    vertical-align: middle;

    .ant-progress-inner {
      background-color: #a59d9d;
      border-radius: 2px;

      .ant-progress-bg {
        // background-color: #1d6541
        background-color: $secondaryColor;
        border-radius: 3px;
        height: 25px !important;

        &.complete {
          background-color: #1d6541;
        }

        &.incomplete {
          background-color: #339966;
        }
      }
    }

    +.ant-progress-text {
      color: #fff;
      // cursor: pointer;
      font-size: 12px;
      font-weight: 600;
      margin-left: 0;
      position: absolute;
      left: 50%;
      top: 6px;
      text-align: center;
      transform: translateX(-50%);
      width: 100%;
    }
  }
}

.ant-input,
input,
textarea {

  &:focus,
  &:hover {
    border-color: #16a085 !important;
    box-shadow: none;
  }
}

textarea {

  &:focus,
  &:hover {
    border-color: #16a085 !important;
    box-shadow: none;
    outline: none;
  }
}

.ant-select-selection {

  &:focus,
  &:hover,
  &:active {
    border-color: #16a085 !important;
    box-shadow: none !important;
    outline: 0 !important;
  }
}

.ant-select-focused {
  &.ant-select-open {
    box-shadow: none !important;
    outline: 0 !important;
  }
}

.agGridWrapper,
.ag-root-wrapper {
  .ag-header-row {
    background: #f3f3f3;
    color: #39414b;
    font-size: 14px;

    .ag-header-cell {
      &:after {
        height: 100%;
        margin-top: 0;
      }

      .ag-react-container {}
    }
  }

  .ag-center-cols-container {
    .ag-row {
      font-size: 13px;
    }
  }
}

.required {
  color: #e00000;
  display: inline-block;
  padding-left: 1px;
}

.essential {
  color: #629b3b;
  // display: inline-block;
  padding-left: 1px;
}

.leave {
  color: #ece583;
  // display: inline-block;
  padding-left: 1px;
}

.action-btn {
  background-color: #e9ecf0;
  border: 0;
  border-radius: 3px;
  cursor: pointer;
  // display: inline-flex;
  height: 38px;
  justify-content: center;
  margin-right: 5px;
  width: 120px;

  &.small {
    background-color: #cccfd2;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
    height: 25px;
    margin-right: 0;
    width: 15px;
    display: flex;
    align-items: center;

    &:focus,
    &:active,
    &:hover {
      background-color: #cccfd2;
      box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
      // color: #16a085;
    }
  }

  .anticon {
    height: 12px;
    width: 14px;
  }

  &:hover {
    background-color: #cbd2db;
  }
}

.text-center {
  text-align: center;
}
.amount-TextRight {
  text-align: right;
}
.ag-cell-focus {
  border-color: #16a085 !important;
}

.validation-failed-modal {
  .ant-modal {
    .ant-modal-close {
      top: -10px;
      right: -10px;
    }

    .ant-modal-header {
      border-bottom: 1px solid #e5e5e5;
      padding: 10px 15px;

      .title {
        text-align: left;

        .fa-ban {
          color: #f00;
          font-size: 20px;
          vertical-align: middle;
        }
      }
    }

    .ant-modal-body {
      padding: 0 !important;

      .warning-message {
        border-bottom: 1px solid #e5e5e5;
        padding: 20px 20px 20px 20px;
        line-height: 1;
      }

      .button-action {
        padding: 15px;
        text-align: center;
      }
    }
  }
}

.added-item-dropdown-modal {
  .ant-modal-header {
    border-bottom: 1px solid #e5e5e5;
    padding: 15px;
  }

  .ant-modal-body {
    padding: 20px 0 0 0;
  }

  .modal-footer {
    border-top: 1px solid #e5e5e5;
    padding: 20px;
    text-align: center;
  }
}

.new-invoice-modal {
  position: relative;
  height: 100vh;

  .action-button-group {
    background: #fff;
    border-top: 1px solid #e5e5e5;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
    margin-left: -15px;
    padding: 7px 20px;
    position: sticky;
    bottom: -20px;
    width: calc(100% + 30px);
    z-index: 99;

    &.package-action-btn {
      margin-left: 20px;
      width: calc(100% - 40px);

      .ant-col-offset-19 {
        .grey-button {
          background-color: #e9ecf0;
        }
      }
    }
  }
}

.ant-modal-content {
  margin-top: -80px;
  // min-height: 600px !important;

  .ant-modal-header {
    border-bottom: 1px solid #e5e5e5 !important;
    padding-top: 15px !important;
    padding-bottom: 10px !important;

    .ant-modal-title {
      font-size: 16px;
      font-weight: 600;
      opacity: 1;
      display: flex;

      .modal-sub-title {
        font-size: 14px;
        font-weight: 400;
      }
    }
    .dis-col{
  flex-direction: column;
  width: 100%;
  color:#000000;
    }
  }

  .ant-modal-body {
    padding: 15px 25px;
  }
}

.ant-select-focused {
  outline: 0 !important;
}

.ant-select-selection--single {
  outline: 0 !important;
  box-shadow: none !important;
}

.ant-select-selection-selected-value {
  width: 100% !important;
}

.supplier-payment-modal {
  .ant-modal-content {
    min-height: 600px;
    position: relative;
  }

  .ant-modal-body {
    max-height: 560px;
    padding: 0;

    form.ant-legacy-form {
      max-height: 550px;
      overflow-y: auto;
      padding: 20px 20px 60px 20px;

      .input-currency-control {
        .input-text-error {
          border-left: 1px solid $error-red;
          height: 38px;
        }
      }
    }
  }

  .form-footer {
    background: #fff;
    border-top: 1px solid #d8dcde;
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 15px 20px;
  }

  .sales-invoice {
    .ant-row {
      margin-bottom: 15px;

      .ant-col-8 {
        text-align: right;
        padding-right: 20px;
      }

      .input-currency-control {
        .currency-tag {
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;

          .fa {
            vertical-align: sub;
          }
        }

        .ant-input {
          margin-left: 40px;
          width: 89%;
          height: 38px;
        }
      }
    }
  }
}



.input-currency-control {
  position: relative;
  height: 38px;

  .currency-tag {
    background: #e7e7e7;
    height: 38px;
    // margin-top: 8px;
    // margin-left: 8px;
    line-height: 24px;
    position: absolute;
    top: 8px;
    left: 6px;
    text-align: center;
    width: 40px;
    z-index: 9;
    display: flex;
    justify-content: center;
    align-items: center;

    .fa {
      vertical-align: sub;
    }
  }

  .currency-tag2 {
    background: #e7e7e7;
    height: 38px;
    margin-top: -11px;
    line-height: 24px;
    position: absolute;
    top: 0;
    left: 0;
    text-align: center;
    width: 40px;
    z-index: 9;
    padding-top: 4px;

    .fa {
      vertical-align: sub;
    }
  }

  .currency-tag-inven {
    background: #e7e7e7;
    height: 40px;
    margin-top: -13px;
    line-height: 24px;
    position: absolute;
    top: 0;
    left: 0;
    text-align: center;
    width: 42px;
    z-index: 9;
    padding-top: 4px;

    .fa {
      vertical-align: sub;
    }
  }

  input[type="number"] {
    border-left-color: transparent;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    margin-left: 40px;
    width: 88%;
    -moz-appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &:focus {
      border-left-color: #51a35d;
    }
  }
}

.fa-file-pdf-o,
.fa-file-excel-o {
  font-size: 14px;
}

.ant-table-row-expand-icon {

  &:active,
  &:hover,
  &:focus,
  &:visited {
    color: #51a35d;
  }
}

.button-group-action {
  border-top: 1px solid #e5e5e5;
  margin-right: -25px;
  margin-left: -25px;
  margin-bottom: -5px;
  padding-top: 10px;

  .align-footer {
    display: flex;
    justify-content: space-between;
    padding-right: 20px;
    padding-left: 20px;
  }

  &.mrl0 {
    margin-right: 0;
    margin-left: 0;
  }
}

.remark-modal {
  .ant-modal-content {

    .ant-modal-body {
      padding-right: 10px;
      padding-left: 10px;
      max-height: fit-content;
      // overflow: auto;

      .inner-content {
        // max-height: 470px;
        height:auto;
        overflow-y: auto;
        padding-right: 20px;
        padding-left: 20px;
      }

      .inner-contentl {
        // max-height: 470px;
        // height: 470px;
        overflow-y: auto;
        padding-right: 20px;
        padding-left: 20px;
      }
    }
  }

  .button-group-action {
    margin-right: -10px;
    margin-left: -10px;
  }
}

.show-setting-email-template {

  .ant-modal-content {
   

    .ant-modal-body {
      padding: 0;
      max-height: 650px;

      .inner-content {
        margin: 10px auto;
        width: 90%;
        height: auto;
        overflow-y: auto;

        .ant-row {
          &.ant-legacy-form-item {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .button-group-action {
    margin: 10px 0;
  }
}

.ant-select-selection__clear {
  right: 25px;
}

.ant-select-dropdown--single {
  &.dropdownCustomStyle {
    width: 475px !important;
  }
}

.ant-select-selection__choice__content {
  margin-top: -1px;
}

.ant-select-selection__choice__remove {
  margin-top: 1px;
}

.text-right {
  padding-right: 20px;
  text-align: left;
  &.pr5 {
    padding-right: 5px;
  }
}

.price-list-modal {
  .modal-footer {
    @include common-footer-modal;
  }
}

.required-field {
  position: relative;

  .ant-legacy-form-item-label {
    >label {
      &:after {
        content: "*";
        color: #e00000;
      }
    }
  }

  #Price_list_component_percentage {
    border-radius: 0;
    border-right-color: transparent;
    margin-right: 25px;
    width: calc(100% - 30px);
  }

  .percentage-mark {
    background: #e7e7e7;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    position: absolute;
    top: 0px;
    right: 0;
    width: 32px;
    text-align: center;
    height: 38px;
    line-height: 38px;
  }
}

.warehouse-modal-control {
  +.modal-footer {
    @include common-footer-modal;
  }
}

.employee-modal-control {
  
  .ant-legacy-form-item-control {
    position: relative;
    line-height: 2;
  }

  +.modal-footer {
    @include common-footer-modal;
    margin-top: 20px;
  }
}

.form-item-customize {
  .ant-legacy-form-item-label-left {
    line-height: 22px;
  }
}

.billing-address-control {
  +.modal-footer {
    @include common-footer-modal;
  }
}

.create-project-control {
  +.modal-footer {
    @include common-footer-modal;
  }
}

.create-new-control {
  +.modal-footer {
    @include common-footer-modal;
  }
}

.cke_editor_editor1_dialog {
  z-index: 99999 !important;

  .cke_dialog {
    .cke_dialog_body {
      border: 1px solid #e5e5e5;
      box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
    }
  }
}

.tooltip-title {
  border-bottom: 1px dotted #f00;
}

.tooltip-mark {
  i {
    color: $secondaryColor;
    font-size: 11px;
  }
}

.cke_dark_background {

  &:focus,
  &:hover,
  &:active {
    border-color: #51a35d;
  }

  a.cke_smile,
  a.cke_specialchar {

    &:focus,
    &:hover,
    &:active {
      border-color: #51a35d !important;
    }
  }
}

.cke_dialog_tabs {

  .cke_dialog_tab_selected,
  .cke_dialog_tab,
  .cke_dialog_ui_input_select {
    &:focus {
      border-color: #51a35d !important;
      border-bottom-color: #fff !important;
    }
  }
}

.cke_dialog_ui_input_select {
  &:focus {
    border-color: #51a35d !important;
  }
}

.tiles-modal {
  .ant-modal-content {
    min-height: 780px;
    
    .ant-modal-body{
      padding: 0 25px 15px ;
      max-height: 650px; 
    }
    .ant-pagination.mini {
      position: absolute;
      width: calc(100% - 40px);
      right: 40px;
      bottom: 15px;

      .ant-pagination-options {
        display: inline-flex;
        margin-right: -20px;

        .ant-pagination-options-size-changer {
          width: 75px;
        }

        .ant-pagination-options-quick-jumper {
          width: 100px;
        }
      }
    }
  }

  .customer-details-table {
    .ag-header {
      border-top: 1px solid #d8dcde;
      margin-top: -1px;
      max-width: 940px;
      position: fixed;
      width: 100%;
      z-index: 99;
    }

    .ag-body-viewport {
      padding-top: 30px;
    }
  }

  &.paid-invoice {
    .customer-details-table {
      .ag-header {
        max-width: 840px;
      }
    }
  }
}

.custom-row {
  display: flex;
  justify-content: space-between;
}

.caps-lock-msg-container {
  position: relative;

  .caps-lock-msg {
    position: absolute;
    top: -18px;
    left: 0;
    text-align: center;

    .fa-warning {
      color: #fc0;
      margin-right: 5px;
    }
  }
}

.download-template-btn {
  align-items: center;
  background-color: #2c7045 !important;
  display: inline-flex;
  height: 35px;
  line-height: 30px;
  margin-bottom: 20px;
  padding: 10px;

  a {
    color: #fff;

    &:hover,
    &:focus,
    &:active,
    &:visited {
      color: #fff;
    }
  }

  &:hover {
    background-color: #4ca185 !important;
  }
}

.ant-upload-drag-container {
  .anticon-inbox {
    color: #4ca185 !important;
  }
}

.template-stepbar-modal {
  padding: 20px;

  .ant-steps-item-container{
    display: flex;
    align-items: center;;
  }

  .steps-content {
    margin-top: 50px;
    padding-right: 100px;
    padding-left: 100px;

    .ant-upload-list {
      .ant-upload-list-item {
        .ant-upload-list-item-info {
          .ant-upload-text-icon {
            display: none;
          }
        }
      }
    }
  }

  .ant-steps-item-finish {
    .ant-steps-item-icon {
      border-color: #2c7045;

      .anticon-check {
        color: #2c7045 !important;
      }
    }

    .ant-steps-item-title {
      &:after {
        background-color: #4ca185 !important;
      }
    }
  }
}

.nested-table {
  .ant-table-tbody {
    .ant-table-row-level-0 {
      .table-cell-action {
        display: inline-flex;
        margin-top: 0;
        text-align: center;

        a {
          &:last-child {
            margin-right: 0;
          }
        }
      }

      .ant-table-row-spaced {
        display: none;
      }

      .ant-table-row-indent {
        display: none;
      }

      .ant-table-row-expand-icon {
        margin-right: 15px;
      }

      td {
        &:first-child {
          width: 150px;
        }
      }
    }

    .ant-table-row-level-1 {
      background-color: #f3f2f2;

      .ant-table-row-expand-icon {
        margin-right: 0;
      }

      .table-cell-action {
        display: inline-flex;
        margin-top: 0;
        padding-left: 0;

        a {
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }

    .ant-table-row-expanded {
      &:after {
        content: "-";
        font-size: 20px;
        font-weight: 700;
        line-height: 9px;
      }
    }

    .ant-table-row-collapsed {
      &:after {
        content: "+";
        font-size: 20px;
        font-weight: 700;
        line-height: 11px;
      }
    }
  }

  .ant-table-thead {
    .ant-table-row-cell-break-word {
      background-color: #fafafa !important;
    }
  }

  &.stock-adjustment {
    .ant-table-tbody {
      .leftAlignCell {
        padding-left: 15px !important;
      }

      .editable-row {
        .editable-cell {
          width: 150px;

          .ant-legacy-form-item {
            padding-left: 0;
            position: relative;

            .ant-legacy-form-item-control-wrapper {
              padding-left: 0;

              .ant-legacy-form-item-control {
                padding-left: 0;

                .ant-legacy-form-item-children {
                  display: inline-block;
                  position: absolute;
                  top: -19px;

                  input {
                    margin-top: 0;
                    height: 24px;
                  }
                }
              }
            }
          }
        }
      }

      .ant-table-row-level-0 {
        .editable-cell {
          .ant-legacy-form-item {
            .ant-legacy-form-item-control-wrapper {
              .ant-legacy-form-item-control {
                .ant-legacy-form-item-children {
                  top: -19px;
                }
              }
            }
          }
        }
      }
    }
  }

  &.product-price-table {
    @extend .stock-adjustment;
  }
}

.fa-edit {
  &:before {
    content: "\270E";
    transform: rotate(90deg);
  }
}

.mark-dropdown-control {
  .ant-select-selection {
    background-color: #e7e7e7;
    border-right-width: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    margin-top: 1px;
  }

  .cell-dropdown {
    margin-top: -24px;

    .ant-select-selector {
      border-right-width: 0;
    }
  }
}

.create-data-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .progressbar-modal-heading {
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 0;
    padding-top: 10px;
    padding-bottom: 20px;
    text-align: center;
    width: 80%;
  }

  &.contact-first-modal {
    .progressbar-modal-heading {
      padding-top: 0;
    }

    img {
      margin-bottom: 20px;
    }
  }
}

.markup-arrow {
  padding-right: 2px;
  padding-left: 2px;
}

.action-cell {
  margin-top: 3px;

  .fa {
    align-items: center;
    border-radius: 2px;
    color: #fff;
    cursor: pointer;
    display: inline-flex;
    height: 28px;
    justify-content: center;
    padding: 5px;
    width: 32px;

    &.fa-edit,
    &.fa-thumbs-up {
      background-color: #107360;
      border-color: #0f6a58;
      margin-right: 5px;
    }

    &.fa-trash,
    &.fa-remove,
    &.fa-thumbs-down {
      background-color: #e75a5a;
      border-color: #e75a5a;
    }
  }
}

.leftAlignCell {
  text-align: left !important;
  padding-left: 15px !important;
}

.myWarehouse-listing {
  .ag-row {
    .action-cell {
      margin-top: -1px;
      display: block;
    }

    &:hover {
      .action-cell {
        background-color: #ecf0f1;
        display: block;
      }
    }
  }
}

.priceList-dropdown {
  margin-top: 2px;

  .ant-select-selector {
    height: 25px !important;

    .ant-select-selection-item {
      line-height: 22px;
    }
  }
}

.priceList-datepicker {
  margin-top: 2px;

  .ant-picker {
    height: 25px;

    input {
      height: 23px;
    }
  }
}

.cellInputControl {
  background-color: transparent !important;
  border-top-width: 0 !important;
  border-bottom-width: 0 !important;
  border-left-width: 0 !important;
  border-radius: 0 !important;

  &.ag-cell-focus {
    border: 1px solid #16a085 !important;
  }

  &.ag-cell.ag-cell-inline-editing {
    height: 36px;
  }

  .ag-react-container {
    width: 80%;
    margin-top: 5px;
    margin-left: 8px;
    height: 20px;
    position: relative;

    textarea,
    input {
      border-color: #d9d9d9;
      border-radius: 2px;
      height: 25px;
      line-height: 16px;
      min-height: unset;
    }

    .ant-input-number {
      height: 23px;
    }
  }
}

.ant-input-number-focused {
  border-color: #16a085;
  box-shadow: none;
}

.lastActionCell {
  &.ag-cell-focus {
    border-color: transparent !important;
    border-left-color: #bdc3c7 !important;
  }
}

.tax-dropdown {
  width: 170px;

  .ant-select-dropdown-menu {
    background-color: #fff;
    width: 170px;
    display: block;

    li {
      position: relative;

      .fa-trash {
        position: absolute;
        top: 3px;
        right: 6px;
      }
    }
  }
}

.import-details-modal {
  overflow: hidden;
  margin-top: -20px;

  .steps-content {
    padding: 0;
  }

  .second-step {

    display: flex;
    flex-direction: column;
    gap: 20px;

    .secont-step-heding {
      display: flex;
      font-weight: 700;
      align-items: start;
      font-size: 16px;

    }

    .second-step-content {
      display: flex;
      justify-content: space-between;
      align-items: start;

      .ant-upload-list {
        margin-top: 30px;
        border-radius: 5px;
        height: 60px;
        display: flex;
        align-items: center;
        width: 100%;

        div {
          width: 100%;
        }

        .ant-upload-list-item {
          border: 1px solid #CFCFCF;

        }

        .anticon svg {
          font-size: 20px !important;
        }
      }

      .right-item {
        align-items: start;
        height: 200px;
        width: 70%;

        .ant-upload {
          border-radius: 12px;
          background-color: #ECF5FF;
          border: 1px dashed #0075FF;
        }

        .anticon svg {
          font-size: 50px;
        }

        .error{
          border: 1px dashed #c93333;

          .ant-upload-btn{
            border: none;
          }
        }
      }

      .left-item {
        display: flex;
        gap: 10px;
        flex-direction: column;
        align-items: start;
        justify-content: center;
        height: 350px;
        width: 25%;
        padding-left: 30px;
        border-radius: 12px;
        border: 1px solid var(--V1-Primary-GrayStroke, #D1D0D6);
        background: var(--White, #FFF);

        img {
          max-width: 100%;
          height: auto;

        }

        p {
          text-align: left;
          font-weight: 500;
        }

      }
    }
  }

  .third-step {
    display: flex;
    width: 100%;
    flex-direction: column;

    .third-step-heading {
      display: flex;
      margin-bottom: 10px;

      div {
        width: 25%;
        text-align: left;
        font-weight: bold;
        font-size: 16px;
      }
    }

    .third-step-content {
      display: flex;
      flex-direction: column;
      overflow: auto;
      max-height: 66vh;

      li {
        display: flex;
        width: 100%;
        margin-bottom: 10px;

        .content-value {
          width: 25%;
          text-align: left;
        }

        .content-value-error {
          width: 25%;
          color: red;
          display: flex;
          padding-left: 10px;
          align-items: center;
          text-align: left;
        }

        .ant-select {
          width: 100%;
        }

        .ant-select-selector {
          width: 100%;
        }
      }
    }
  }

  .error-page {
    display: flex;
    flex-direction: column;
    gap: 30px;
    max-width: 90%;

    .part-1 {
      border: 1px dotted #FFB020;
      height: 40px;
      width: 100%;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #FFF9E5;
      font-size: 12px;
      font-weight: 600;
    }

    .part-2 {
      border: 1px dotted #1CB980;
      // height: 40px;
      width: 100%;
      border-radius: 8px;
      display: flex;
      // justify-content: space-around;
      align-items: center;
      background-color: #F2FFF8;
      font-size: 12px;
      font-weight: 500;
      gap: 20px;

      padding: 30px;

      .error-page-img {
        width: 20%;
      }

      .error-info {
        text-align: left;
        width: 40%;
      }

      .heading-count {
        font-size: 40px;
        color: #1CB980;
      }
    }

    .part-3 {
      border: 1px dotted #F85353;
      // height: 40px;
      width: 100%;
      border-radius: 8px;
      display: flex;
      gap: 20px;
      padding: 30px;

      // justify-content: center;
      // justify-content: space-around;

      align-items: center;
      background-color: #FFFBFB;
      font-size: 12px;
      font-weight: 500;

      .error-page-img {
        width: 20%;
      }

      .error-info {
        text-align: left;
        width: 40%;
      }
      .error-record-btn{
        width: 40%;
        display: flex;
        align-items: center;
        justify-content: center;
        button {
          display: flex;
          width: 205px;
          padding: 11px 29px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          color: white;
          border-radius: 4px;
          background: #F85353;
          border: 1px solid #F85353;
        }
      }

      .heading-count {
        font-size: 40px;
        color: #F85353;
      }
    }
  }

  .ant-modal-body {
    // display: flex;
  }

  .ant-steps {
    width: 50%;
    // display: flex;
  }

  .ant-modal-content {
    height: 100vh;

    .ant-modal-title {
      text-transform: capitalize;
    }

    .steps-action {
      border-top: 1px solid #ddd;
      margin-left: -45px;
      padding-top: 10px;
      padding-right: 20px;
      padding-bottom: 10px;
      position: absolute;
      bottom: 0;
      width: 100%;
    }
  }
}

.information-details {
  color: #f00;
  font-style: italic;
  font-weight: 500;
  margin-top: 10px;
}

.action-listing-dropdown {
  z-index: 9999999 !important;

  .cell-action-dropdown {
    max-height: 328px;
  }
}

.ant-input-affix-wrapper-focused {
  border-color: #16a085;
  box-shadow: none;
}

.ant-input-affix-wrapper {
  // width: 300px;
  height: 38px;
  display: flex;
  align-items: center;
  padding: 4px 4px;

  &:hover {
    border-color: #16a085;
  }
}

.ant-checkbox-checked {
  &:after {
    border-color: #16a085;
  }
}

.ag-theme-balham {
  .ag-ltr {
    .ag-cell {
      border-right: 1px solid #d8dcde;
    }

    .status-cell {
      line-height: 26px;

      &.payment {
        line-height: 32px;
      }
    }

    .progressbar-cell {
      line-height: 20px;
    }

    .sales-order-progressbar {
      line-height: 0;
      margin-top: -1px;
    }

    .supplier-details-cell {
      line-height: 1.5;
    }

    .arrow-cell {
      line-height: 8px !important;

      .arrow-icon {
        cursor: pointer;
        margin-left: -5px;
        padding: 5px;
      }

      &.ag-cell-focus {
        border-color: transparent !important;
        border-right-color: #d8dcde !important;
      }
    }
  }
}

.supplier-action-cell {
  text-align: center;

  .fa {
    margin-right: 8px;
    padding-left: 3px;
    padding-right: 3px;
    vertical-align: middle;

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      cursor: pointer;
      opacity: 0.7;
    }
  }
}


.measure_input{
  width: calc(100% / 3);

  .ant-input-number-handler-wrap{
    display: none;
  }

  .ant-input-number-input-wrap{
    height: 32px;

    .ant-input-number-input{
      height: 32px;
      padding: 4px !important;
    }
  }
}

.measure_unit_select{
  &.ant-select{
    width: 25%;

    .ant-select-selector{
      background-color: #e7e7e7;
      min-height: 34px !important;
      padding-right: 0px;
      padding-left: 0px;
    }
  }
}

.documentTitleDropdown {
  .custom-dropdown-width {
    .ant-select-selector {
      .ant-select-selection-placeholder {
        text-align: left;
      }

      .ant-select-selection-item {
        text-align: left;
      }
    }
  }

  .ant-select-dropdown-placement-bottomLeft {
    text-align: left;
  }
}

.customer-details-from-dropdown {
  margin-top: 6px;

  .inner {
    margin-top: 15px;

    .billing-address {
      padding-left: 0;
    }
  }

  .value {
    color: #656b79;
    font-weight: 700;
  }
}

.details-from-dropdown {
  .billing-address {
    padding-left: 0;
  }
}

.rfq-listing-data {
  .ant-table-row-level-1 {
    background-color: #f3f2f2;

    .ant-table-cell {
      border-left-color: #e6e6e6 !important;
      border-bottom-color: #e6e6e6 !important;
      border-right-color: #e6e6e6 !important;

      &:first-child {
        .cursor-pointer {
          display: inline-block;
          padding-left: 35px;
        }

        .ant-table-row-expand-icon {
          height: 1px;
        }
      }
    }
  }
}

.warehouse-icon {
  vertical-align: baseline;
}

.stock-summary {
  .filter-action-control {
    display: flex;
    justify-content: space-between;
    padding-bottom: 15px;

    .cell-dropdown {
      .ant-select-selector {
        height: 36px;
        margin-top: 5px;
      }

      .ant-select-arrow {
        margin-top: 6px;
      }
    }
  }
}

.detail {
  height: 20px;
  padding: 0 10px;
}

.upload-profile-pic {
  padding: 0px;
  position: relative;
  display: inline-block;
  width: 90px;
  height: 90px;

  img {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto;
    text-align: center;
    max-width: 100%;
    max-height: 100%;
  }

  .user-img {
    // border: 3px solid #fff;
    cursor: pointer;
    // height: 90px;
    // max-width: 100px;
    // width: 100%;
    width: 90px;
    height: 80px;
  }

  .camera-icon {
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 1px 1px 0 rgba(65, 69, 73, 0.3),
      0 1px 3px 1px rgba(65, 69, 73, 0.15);
    height: 24px;
    padding: 3px;
    position: absolute;
    bottom: 20px;
    right: -9px;
    width: 24px;

    .fa-camera {
      vertical-align: text-top;
    }
  }
}

.upload-profile-name{
  display: flex;
  cursor: pointer;

  &:hover{
    .upload_btn{
      opacity: 1;
    }
  }

  .user_name {
    width: 40px;
    height: 40px;
    background-color: #C1DAFF;
    color: #0075FF;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    letter-spacing: 1px;
    
  }

  .upload_btn{
    width: min-content;
    height: min-content;
    border-radius: 4px;
    box-shadow: 0 1px 1px 0 rgba(65, 69, 73, 0.3), 0 1px 3px 1px rgba(65, 69, 73, 0.15);
    padding: 1px;
    background-color: #ffffff;
    position: relative;
    bottom: -23px;
    right: 13px;
    opacity: 0;
  }
}

.Upload-profile-modal {
  .ant-modal-body {
    .form-footer {
      border-top: 1px solid #e5e5e5;
      display: flex;
      justify-content: flex-end;
      margin-right: -25px;
      margin-bottom: -5px;
      margin-left: -25px;
      padding-top: 10px;
      padding-right: 25px;
    }

    .crop-footer {
      display: flex;
      justify-content: space-between;
      background-color: #ffffff;
      border-top: 1px solid #e5e5e5;
      padding-top: 10px;
    }
  }
}

.add-remove-column {
  height: 270px;
  overflow-x: hidden;
  overflow-y: auto;

  .ant-checkbox-wrapper-disabled {
    .ant-checkbox-disabled {
      .ant-checkbox-inner {
        border-color: #4ca185 !important;

        &:after {
          border-color: #fff;
        }
      }
    }
  }
}

.ant-picker-dropdown {
  z-index: 99999999 !important;
}

.note-information {
  color: #39403e;
  font-style: italic;
  font-weight: 500;
  // position: absolute;
  // top: 65px;
  // bottom: 18px;
  // left: 15px;
  width: 100%;
  background: #fff6b6;
  padding-left: 3px;
}

.currency-converter-modal {
  display: inline-flex;
  // padding-left: 10px;
  position: relative;

  .icon {
    &:hover {
      .fa-exchange {
        color: #16a085;
      }
    }
  }

  .fa-exchange {
    vertical-align: baseline;
  }

  .content {
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.3);
    display: block;
    padding: 15px;
    position: absolute;
    top: 42px;
    right: 0;
    width: 300px;
    z-index: 99;

    &:after {
      content: "";
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 5px solid #fff;
      position: absolute;
      top: -5px;
      right: 10px;
    }

    .form-icon-group {
      display: inline-flex;
      width: 100%;

      .fa {
        background-color: #e7e7e7;
        height: 32px;
        line-height: 32px;
        margin-top: -5px;
        text-align: center;
        width: 40px;
      }

      .ant-input {
        margin-top: -5px;
        margin-right: 10px;
      }
    }
  }

  &.pl0 {
    padding-left: 0;
  }
}

.bidding-supplier-popup {
  .ant-modal-content {
    .ant-modal-body {
      overflow-y: hidden;

      .modal-content {
        .rfqBidding-supplier-list {
          box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
          padding-top: 0;
          padding-bottom: 0;
          position: relative;
          max-height: 360px;
          min-height: 150px;
          overflow-y: auto;

          .ant-table-container {
            border: 0;
          }

          .action-dropdown-btn {
            position: absolute;
            bottom: -8px;
            left: 0;
            width: 100%;
            background: transparent;
          }
        }
      }
    }
  }

  .ant-table-content {
    table {
      .ant-table-tbody {
        .ant-table-cell.ant-select {
          border-right: 0 !important;
        }

        td {
          .ant-table-cell.document-dropdown-cell {
            border-right: 0 !important;
          }

          &.document-dropdown-cell {
            .ant-legacy-form-item-control {
              line-height: unset;
            }
          }

          span.document-dropdown-cell {
            .ant-upload-list-item {
              .anticon-paper-clip {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}

.currency-icon {
  display: inline-block;
  background-color: #e7e7e7;
  height: 32px;
  line-height: 32px;
  margin-top: -5px;
  text-align: center;
  width: 15%;

  &.w20 {
    width: 20%;
  }
}

.li-button {
  background: none;
  border: none;
}

.ant-dropdown-menu-item-active {
  .li-button {
    background: none;
    border: none;
    color: #655d5d;
  }
}

.ant-select-dropdown {
  padding: 0 !important;
  z-index: 999999;

  .ant-select-item {
    padding-right: 5px;
    padding-left: 5px;
    position: relative;

    .custom-dropdown-option {
      display: inline-block;
      padding-right: 15px;
      white-space: normal;
      word-break: break-word;
    }

    .option-delete-icon {
      display: none;
    }

    &:hover {
      .option-delete-icon {
        display: inline-block;
        position: absolute;
        top: 2px;
        right: 5px;
      }

      .favourite-mark {
        display: none;
      }
    }
  }
}

.ant-drawer {
  z-index: 9999 !important;
}

.log-activity-modal {
  .ant-modal-content {
    .ant-modal-body {
      max-height: 520px;
      padding-right: 0;

      .modal-content {
        max-height: 450px;
        overflow-y: auto;
        padding-right: 20px;
      }
    }
  }

  .modal-footer {
    border-top: 1px solid #e5e5e5;
    margin-left: -25px;
    padding: 10px 15px;

    .ant-legacy-form-item-children {
      display: flex;
      justify-content: space-between;
    }
  }

  &.customer-listing {
    .ant-modal-content {
      height: 593px;
    }
  }
}

.log-communication-modal {
  .ant-modal-body {
    padding-bottom: 0;
  }

  .modal-footer {
    border-top: 1px solid #e5e5e5;
    margin-left: -25px;
    margin-right: -25px;
    padding: 10px 15px;

    .ant-legacy-form-item-children {
      display: flex;
      justify-content: space-between;
    }
  }
}

.pay-invoice-modal {
  .ant-modal-content {
    // min-height: 565px;
    min-height: 665px;
    width: 600px;

    .ant-modal-body {
      // max-height: 515px;
      max-height: 515px;
      padding: 0;

      .information {
        max-height: 450px;
        overflow-y: auto;
        padding-top: 10px;

        .ant-row {
          padding-bottom: 12px;

          .ant-col-8 {
            padding-right: 20px;
            text-align: right;
          }
        }

        .input-currency-control {
          .input-text-error {
            border-left: 1px solid $error-red;
            height: 38px;
          }
        }
      }
    }

    .ant-modal-header {
      border-bottom: 1px solid #ddd;
      margin-bottom: 10px;
      padding-top: 15px;

      .title {
        color: #000;
      }
    }

    .form-footer {
      display: flex;
      justify-content: space-between;
      border-top: 1px solid #ddd;
      padding: 10px 20px;
      text-align: right;
    }
  }
}

.sale-invoice-modal{
  .ant-modal-content {
    // min-height: 565px;
    min-height: 400px;
    height: 400px;
    width: 600px;

    .ant-modal-body {
      // max-height: 515px;
      max-height: 515px;
      padding: 0;

      .information {
        max-height: 450px;
        overflow-y: auto;
        padding-top: 10px;

        .ant-row {
          padding-bottom: 12px;

          .ant-col-8 {
            padding-right: 20px;
            text-align: right;
          }
        }

        .input-currency-control {
          .input-text-error {
            border-left: 1px solid $error-red;
            height: 38px;
          }
        }
      }
    }

    .ant-modal-header {
      border-bottom: 1px solid #ddd;
      margin-bottom: 10px;
      padding-top: 15px;

      .title {
        color: #000;
      }
    }

    .form-footer {
      display: flex;
      justify-content: space-between;
      border-top: 1px solid #ddd;
      padding: 10px 20px;
      text-align: right;
    }
  }
}

.custom-dropdown-width {
  .ant-select-selector {
    .ant-select-selection-item {
      .fa-trash {
        cursor: pointer;
        display: none;

        &:before {
          content: "\f00d";
        }
      }

      .fa-lock {
        cursor: pointer;
        display: none;

        &:before {
          content: "\f00d";
        }
      }
    }

    .favourite-mark {
      display: none;
    }
  }
}

.billing-address-information {
  // border-left: 3px solid #16a085;
  border-left: 3px solid $secondaryColor;
  margin-bottom: 10px;
  color: $text-color;

  strong {
    line-height: 1;
    padding-left: 10px;
  }

  ul {
    margin-top: 10px;
    margin-left: 30px;
  }
}

.shipment-information-modal {
  .ant-modal-content {
    .ant-modal-body {
      padding: 0;

      .modal-table-container {
        padding-bottom: 0;

        .shipment-info-tab {
          .ant-tabs-bar {
            margin-right: 20px;
            margin-left: 20px;
          }
        }
      }
    }
  }
}

.package-info {
  display: flex;
  font-size: 14px;
}

.text-left {
  text-align: left;
}

.manage-user-modal {
  .ant-modal-content {
    min-height: 600px;
  }

  .ant-modal-body {
    padding: 0;

    .new-user-form {
      min-height: 495px;
      max-height: 495px;
      // overflow-y: auto;
      padding-top: 15px;

      .custom-tabs-components {
        &.create {
          .ant-tabs {
            .ant-tabs-content {
              height: 356px !important;
              overflow-y: auto;
              padding-bottom: 20px;
            }
          }
        }

        .ant-tabs {
          .ant-tabs-content {
            height: 415px;
            overflow-y: auto;
            padding-bottom: 20px;
          }
        }
      }

      .ant-row {
        margin-bottom: 12px;
      }
    }
  }
}

.supplierNameCell {
  overflow: unset;
  white-space: normal;

  .bidding-supplier-name-cell {
    line-height: 1.5;

    .supplier-name {
      font-weight: 500;
    }

    .address-details {
      font-size: 12px;
      color: #717171;
    }
  }
}

.bidding-supplier-address {
  .ant-modal-content {
    min-height: 445px;
    width: 600px;

    .ant-modal-body {
      padding: 0;

      .information {
        max-height: 435px;
        overflow-y: auto;
        padding-top: 10px;

        .ant-row {
          padding-bottom: 12px;

          .ant-col-8 {
            padding-right: 20px;
            text-align: right;
          }
        }
      }
    }

    .ant-modal-header {
      border-bottom: 1px solid #ddd;
      margin-bottom: 10px;
      padding-top: 15px;

      .title {
        color: #000;
      }
    }

    .form-footer {
      display: flex;
      justify-content: space-between;
      border-top: 1px solid #ddd;
      padding: 10px 20px;
      text-align: right;
    }
  }
}

.tax-rate-input {
  display: inline-flex;
  height: 32px;
  width: 100%;

  span {
    background-color: #ddd;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    font-weight: 500;
    height: 32px;
    line-height: 32px;
    text-align: center;
    width: 32px;
  }

  InputNumber {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.ant-select-dropdown {
  .ant-select-item-option-selected {
    .ant-select-item-option-state {
      color: #16a085;
    }
  }
}

.notification-popup {
  top: 50px !important;

  .ant-popover-content {
    // box-shadow: 0 2px 2px rgba(0, 0, 0, .3);
    transform: translateX(16px);

    .ant-popover-arrow {
      top: -4px;
      right: 18px;
    }

    .ant-popover-title {
      border-bottom-color: #dadada;
      text-align: center;
    }
  }

  .ant-popover-inner-content {
    max-height: 500px;
    overflow-y: auto;
    padding-top: 0;
    padding-right: 0;
    padding-left: 0;

    .recent-tx-content {
      width: 450px;

      .ant-row {
        border-bottom: 1px solid #f0f0f0;
        padding: 5px 16px;

        &:last-child {
          border-bottom: 0;
          padding-bottom: 0;
        }
      }
    }
  }
}

.inventory-variant-table {
  margin-bottom: 60px;

  .ag-header-row {
    .ag-header-cell {
      align-items: center;
      display: inline-flex;
      font-size: 13px;
      height: 33px !important;
      line-height: 1.2;
      padding-right: 5px;
      padding-left: 5px;
      white-space: normal !important;
    }
  }

  .ag-cell-value {
    padding-right: 5px;
    padding-left: 5px;
  }

  .variant-column {
    line-height: 20px !important;
    text-align: left;
  }
}

.ant-modal-close-icon,
.anticon-close {
  -webkit-transition: 0.3s ease-out;
  -moz-transition: 0.3s ease-out;
  transition: 0.3s ease-out;

  &:hover {
    transform: rotateZ(180deg);
  }
}

.cke_dialog_container {
  z-index: 9999999 !important;
}

.package-type-details {
  padding-bottom: 10px;

  .modal-table-container {
    border: 20px solid #e9ecf0;
    border-bottom: 0;
    background-color: #fff;
    width: 100%;
    padding: 15px 15px 20px 15px;

    &:first-child {
      border-top: 0;
    }

    table {
      tbody {
        tr {

          td,
          th {
            padding: 5px;
            vertical-align: top;
          }

          .description-cell {
            padding: 10px;
            text-align: left;
            width: 500px;
            word-break: break-all;

            pre {
              font-family: "Segoe UI", "Droid Sans", Tahoma, Arial, sans-serif;
              font-size: 13px;
              line-height: 1.4;
              margin-bottom: 0;
            }
          }

          .text-left {
            text-align: left;
          }
        }
      }
    }
  }
}

.ant-select-allow-clear {
  &:hover {
    .ant-select-clear {
      color: #aba5a5;
      font-size: 14px;
      margin-top: 1px !important;
      right: 28px;
    }
  }
}

.table-action-container {
  .action-btn {
    align-items: center;
    font-size: 14px;
    background-color: #efefef;

    // &:hover {
    //   opacity: 0.9;
    //   background-color: #cfcfcf;
    // }
  }

  .action-icon-btn {
    align-items: center;
    font-size: 18px;
    width: 50px;
    margin-right: 10px;
    border: 0px;
    // box-shadow: -1px -1px 1px rgb(207, 207, 207);
    // background-color: white;
    cursor: pointer;

    &:hover {
      opacity: 0.9;
      background-color: #cfcfcf;
    }
  }
}

.ledger-account-list-modal {
  .ant-modal-content {
    .ant-modal-body {
      max-height: 535px;
      padding-right: 10px;

      .ledger-account-table {
        .ag-row.ag-row-level-0 {
          .ag-cell {
            line-height: 26px;
          }
        }
      }

      .ag-header {
        border-top: 1px solid #d8dcde;
        margin-top: -1px;
        max-width: 947px;
        position: fixed;
        width: 100%;
        z-index: 99;
      }

      .ag-body-viewport {
        padding-top: 32px;
      }

      .ant-pagination.mini {
        margin-right: 16px;
        border-top: 1px solid #ddd;
        padding-top: 15px;
        margin-top: -8px;
      }
    }
  }
}

.mt-7 {
  margin-top: -7px !important;
}

.mt-12 {
  margin-top: -12px !important;
}

.remarks-container {
  display: flex;
  flex-direction: column;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.favourite-mark {
  color: #ed9900;
  font-size: 13px;
  position: absolute;
  top: 3px;
  right: 5px;
}

.delete-list-item {
  position: absolute;
  right: 5px;
  bottom: 8px;
}

.bank-info {
  .ag-body-viewport {
    //  max-height: 320px;
    //  height: 320px;
    // overflow: auto;
  }
}

.audit-trail-drawer {
  .ant-drawer-body {
    .ant-timeline-label {
      .ant-timeline-item-label {
        top: 0;
        left: 15px;
        width: unset;
      }

      .ant-timeline-item-tail {
        border-color: #ddd;
        border-width: 1px;
        top: 18px;
        left: 30%;
      }

      .ant-timeline-item-head-custom {
        margin-left: 0;
        left: 30%;

        .ant-timeline-item-head-blue {
          color: #2c7045;
          border-color: #2c7045;
        }
      }

      .ant-timeline-item-content {
        top: 0;
        left: 30%;
        width: 60%;

        p {
          display: inline-block;
          padding-right: 4px !important;
        }
      }
    }
  }
}

.ant-tooltip {
  z-index: 9999;
}

.inventory-drawer {
  .ant-drawer-body {
    padding-top: 0;
  }

  .inventory-tabs {
    .ant-tabs-bar {
      background: #fff;
      padding-top: 10px;
      position: sticky;
      top: 0;
      z-index: 9999;
    }
  }
}

.ant-select-dropdown {
  .ant-select-item-option {
    &[name="primary"] {
      background-color: #2f6af1;
      color: #fff;
    }

    &[name="info"] {
      background-color: #395565;
      color: #fff;
    }

    &[name="warning"] {
      background-color: #c36424;
      color: #fff;
    }

    &[name="success"] {
      background-color: #004d00;
      color: #fff;
    }

    &[name="danger"] {
      background-color: #d0281c;
      color: #fff;
    }
  }
}

.ant-switch {
  &.ant-switch-checked {
    .ant-switch-inner {
      position: relative;

      &:before {
        content: "Yes";
        color: #fff;
        font-size: 11px;
      }
    }
  }

  .ant-switch-inner {
    position: relative;

    &:before {
      content: "No";
      color: rgba(#000, 0.7);
      font-size: 11px;
    }
  }
}

.justify-space-between {
  justify-content: space-between;
}

.comt .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab.ant-tabs-tab-active {
  background-color: #16a085 !important;
  color: #fff;
}

.comt .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active:before {
  background-color: #16a085 !important;
}

.comt .ant-tabs-bar {
  padding-bottom: 20px;
  color: #333;
}

// .comt .primary-tab-container .secondary-tab .ant-tabs-nav .ant-tabs-tab{border:solid 1px #16a085 ; padding:4px 20px !important;}
.comt .ant-tabs-tab {
  border: solid 1px #16a085 !important;
}

.chebox {
  padding-top: 4px;
}

.heights {
  height: initial !important;
}

.inercont {
  .inner-contentls {
    // max-height: 470px;
    height: 470px;
    overflow-y: auto;
    padding-right: 20px;
    padding-left: 20px;
  }

  .ant-select-dropdown div {
    height: auto !important;
  }
}

.text-agright {
  text-align: right;
}

.popup-height {
  height: 500px !important;
  overflow-y: auto !important;
}

.circles-ling {
  color: #107360;
  text-align: center;
  margin-top: 8px;
  font-size: 16px;
  transform: rotate(180deg);
}

.circles-ling a {
  color: #107360;
}

.circles-lings {
  height: 20px;
  width: 20px;
  background: #107360;
  margin: 7px auto 0px;
  border: 50%;
  overflow: hidden;
  position: relative;
}

.circles-lings span {
  position: absolute;
  left: 10px;
  top: 0px;
  height: 20px;
  width: 20px;
  background: #ffffff;
}

.ant-radio-inner {
  &:after {
    background-color: #107360 !important;
  }
}

.ant-radio-checked .ant-radio-inner {
  border-color: #107360 !important;
}

.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus {
  border-color: #107360 !important;
}

.req-popup {
  min-height: 600px !important;
}

.top-button {
  background-color: rgba(221, 221, 221, 0.2);
  border: 1px solid #ddd;
  border-radius: 0;
  box-shadow: none;
  margin: 0;
  position: relative;
  text-align: center;
  padding: 2px 4px;
  float: right;
  margin: 10px 0 5px 0;
}

.placeholder-button {
  background-color: rgba(221, 221, 221, 0.2);
  border: 1px solid #ddd;
  text-align: center;
  vertical-align: middle;
  padding: 7px 5px;
  float: right;
  height: 34px;
  cursor: pointer;
}

.save-but {
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #f8f8f8;
  height: 32px;
  width: 40px;
  text-align: center;
  line-height: 30px;
}

// .new-search .ant-select-multiple .ant-select-selector::after{ display: none;}
// .new-search .ant-select-selection-search{display: none;}
.integs {
  float: left;
  margin: 25px 25px 0px 0px;
  width: 300px;
  overflow: hidden;
  position: relative;
}

.integs:nth-child(4) {
  margin-right: 0px;
}

.integ div.accounting-market-place .ant-card-extra {
  width: 100%;
  text-align: center;
  margin: 0px auto !important;
}

.cr {
  width: 200px;
  padding: 4px;
  position: absolute;
  text-align: center;
  color: #f0f0f0;
}

.cr-top.cr-left,
.cr-bottom.cr-right {
  transform: rotate(-45deg);
}

.cr-green {
  background-color: #2c7045;
}

.cr-top {
  top: 20px;
}

.cr-left {
  left: -60px;
}

.but-geray {
  background-color: #e9ecf0;
}

.bankInfo-card {
  background-color: #626288;
  padding: 15px;
  color: #ffffff;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 5px;
  margin-bottom: 10px;

  .notification-count {
    border-radius: 10px;
    background-color: #8c93bf;
    text-align: center;
    height: 22px;
    margin-right: 8px;
  }

  .card-menu {
    text-align: center;
    height: 22px;
    font-size: 15px;
    margin-left: 5px;
    cursor: pointer;
  }

  .account-type {
    font-size: 10px;
    background-color: #8c93bf;
    border-radius: 9px;
    padding: 1px 7px 2px 7px;
    margin-left: -5px;
  }

  .download-tx {
    cursor: pointer;
    border-radius: 10px;
    background-color: #8c93bf;
    text-align: center;
    height: 22px;
    padding: 1px 4px 1px 4px;
  }
}

.import-bank-reco-label {
  text-align: right;
  padding-right: 30px;
}

.withdrawal-button {
  background: #ccdd1e !important;
}

.deposit-button {
  background: #88c440 !important;
}

.ant-picker-clear {
  position: absolute;
  top: 50%;
  right: 25px !important;
  color: rgba(0, 0, 0, 0.25);
  background: #fff;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  cursor: pointer;
  opacity: 0;
  -webkit-transition: opacity 0.3s, color 0.3s;
  transition: opacity 0.3s, color 0.3s;
}

.material-ui-rc-color-picker {
  z-index: 99999 !important;
  background-color: cadetblue;
}

.new-pic .upload-profile-pic .user-img {
  width: 60px;
  height: 60px;
  margin: 0px auto;
}

.ag-cell-value,
.ag-group-value {
  overflow: initial;
}

.fan-bank {
  margin-top: 40px;
  position: fixed;
  background: #fff;
  bottom: 0px;
  width: 92.2%;
  text-align: center;
}

.foot-fix {
  position: fixed;
  bottom: 0px;
  padding: 25px 5% 15px;
  width: 100%;
  left: 0px;
  background: #fff;
  border-top: solid 1px #e9ecf0;
}

.foot-bottoms {
  padding-bottom: 150px !important;
}

@media only screen and (max-width: 600px) {
  .pay-invoice-modal .ant-modal-content {
    width: 98%;
    margin: 0px auto;
  }
}

.remark-modalm .ant-modal-content .ant-modal-body .inner-content {
  height: 600px;
}

.mle .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active::before {
  border-right: 4px solid #2c7045 !important;
  height: 45px;
}

.mle .primary-tab-container .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active:before {
  background-color: initial;
}

.mle .button-group-action {
  margin-bottom: 0px;
}

.mle .ant-btn {
  border-color: #d9d9d9;
}

.mle .ant-btn {
  background-color: #e9ecf0;
}

.mle .button-group-action {
  position: absolute;
  bottom: -70px;
  width: 90%;
  background: #fff;
}

.mle {
  position: relative;
}

.mle .ant-legacy-form-item-control {
  line-height: 28px;
}

.mle .ant-tabs .ant-tabs-left-content {
  border-left: 1px solid #afafaf;
}

.mle .primary-tab-container.secondary-tab .ant-tabs-nav-wrap {
  padding-right: 0px;
}

// .paylist{ width: 50%;}

.product-scroll {
  height: 600px;
  padding: 10px 10px 100px;
  overflow-y: auto;
}

.addfoot {
  position: absolute;
  left: 0%;
  width: 99.4%;
  padding: 0px 0px 10px;
  bottom: 0;
  background: #fff;
}

@media only screen and (max-width: 767px) {
  .paylist .ant-modal {
    width: 96% !important;
    margin: 0px auto;
  }

  .paylist form .ant-select {
    width: 90% !important;
  }
}

.store-icon {
  background: url(../images/store.png) no-repeat center top;
  height: 18px;
  width: 18px;
  margin: 0px auto;
  position: relative;
  top: 4px;
}

.gens {
  padding: 0px 20px;
  height: auto !important;
}

.gens .inner-wrapper {
  max-width: 100%;
  margin: 0px auto;
  background: #fff;
  border-radius: 8px;
  padding: 10px 20px;
}

.gens .page-details-container {
  margin-top: 20px !important;
  box-shadow: 0px 0px 00px #cccccc !important;
  border-radius: 0px 0px 0px 0px !important;
  border-top: solid 0px #f0f0f0;
}

.gens .setup-form-details {
  width: 100% !important;
}

.gens .ant-row {
  padding-bottom: 0px !important;
}

.gens .next-scroll {
  height: 400px;
  overflow-y: scroll;
}

.gens .steps-action {
  border-top: solid 1px #e5e5e5;
  padding-top: 15px;
}

.gens .steps-content {
  min-height: 400px;
}

.kiosk-scroll {
  height: 210px;
  overflow-y: auto;
}

.headr .ant-card-head-wrapper {
  display: inline-block;
}

.integsm {
  float: right;
  margin: 85px 0px 0px 25px;
  width: 300px;
  overflow: hidden;
  position: relative;
  border: 0px solid #f0f0f0 !important;
  -webkit-box-shadow: 0px 7px 30px 0px rgba(235, 235, 235, 1);
  -moz-box-shadow: 0px 7px 30px 0px rgba(235, 235, 235, 1);
  box-shadow: 0px 7px 30px 0px rgba(235, 235, 235, 1);
  background: #fff;
  border-radius: 6px;
}

.prod-ama {
  padding: 90px 0px 90px;
}

.prod-ama h3 {
  font-size: 45px;
  font-weight: 700;
  padding: 40px 0px 15px;
  margin: 0px;
}

.prod-ama h4 {
  font-size: 24px;
  font-weight: 400;
  padding: 15px 0px 20px;
  margin: 0px;
}

.prod-ama ul {
  margin: 0px;
  padding: 0px 0px 0px 25px;
  list-style: none;
  font-size: 16px;
}

.prod-ama ul li {
  margin: 5px 0px;
  padding: 0px 0px 0px 20px;
  position: relative;
  list-style: none;
}

.prod-ama ul li span {
  position: absolute;
  left: 0px;
  top: -2px;
}

.prod-amab {
  padding: 30px 0px 0px;
  font-size: 18px;
}

.line-box {
  height: 3px;
  width: 50px;
  background: #2c7045;
  margin: 10px 0px;
}

.prod-ama ul li i {
  font-size: 8px;
}

.thunrt {
  margin-top: 40px;
}

.view-container-wrappe .shadesama {
  margin-top: 0px;
  background: initial;
}

.view-container-wrappe .shadesama .customer-details {
  background: linear-gradient(172deg, #fff 60%, #fffaf2 60%);
  background-color: initial;
  padding-bottom: 30px;
}

.shadesama {
  margin-top: 0px;
}

.shadesama .customer-details {
  background: linear-gradient(172deg, #fff 60%, #fffaf2 60%);
  background-color: initial;
  padding-bottom: 30px;
}

.shadeseba {
  margin-top: 0px;
}

.shadeseba .customer-details {
  background: linear-gradient(172deg, #fff 60%, #f0fff6 60%);
  background-color: initial;
}

.shadeswal {
  margin-top: 0px;
}

.shadeswal .customer-details {
  background: linear-gradient(172deg, #fff 60%, #f2faff 60%);
  background-color: initial;
}

// .upload-profile-pic {
//   width: initial;
// }

.shadeseasy {
  margin-top: 0px;
}

.shadeseasy .customer-details {
  background: linear-gradient(172deg, #fff 60%, #f1f3fe 60%);
  background-color: initial;
}

.shadesship {
  margin-top: 0px;
}

.shadesship .customer-details {
  background: linear-gradient(172deg, #fff 60%, #f7f7f7 60%);
  background-color: initial;
}

.shadesship .prod-ama {
  padding: 40px 0px 40px;
}

.amazon-box-border {
  border: solid 1px #666;
  padding: 20px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.ama-hed {
  font-size: 30px;
  font-weight: 700;
  line-height: 22px;
  padding-bottom: 17px;
}

.ama-hed span {
  font-size: 18px;
}

.ama-heds {
  font-size: 16px;
  color: rgb(139, 139, 139);
}

.ama-hed1 {
  font-size: 30px;
  font-weight: 700;
  line-height: 22px;
  padding-bottom: 10px;
  color: #3548c9;
}

.ama-hed1 span {
  font-size: 18px;
  color: #000;
}

.cir-der {
  background: #15a08b;
  border-radius: 4px;
  font-size: 12px !important;
  color: #fff !important;
  font-weight: 400;
  padding: 2px 5px;
}

.but-buttons {
  margin-bottom: 10px;

  input {
    background: rgb(250, 250, 250);
    height: 38px;
    background: linear-gradient(0deg,
        rgba(250, 250, 250, 1) 0%,
        rgba(255, 255, 255, 1) 100%);
    border: solid 1px #c0c1c3;
    border-radius: 3px;
    font-weight: normal;
  }

  button {
    background: #fafafb;
    height: 40px;
    color: #a4a4a4;
    border-color: #bebfc1;
    border: solid 1px #bebfc1;
    width: 33.3%;
    box-shadow: inset 0 0.0625rem 0 #fff;

    &:hover {
      background-color: #eeeff1;
      box-shadow: inset 0 0.0625rem 0.3125rem #c4c5c7;
      color: #6b7a8a;
      border-color: #bebfc1;
      border: solid 1px #bebfc1;
    }

  }
}

.but-buttons .ant-slider-track {
  background-color: #2c7045;
}

.but-buttons .ant-slider-handle.ant-tooltip-open {
  border-color: #2c7045;
}

.but-buttons .ant-slider:hover .ant-slider-track {
  background-color: #4ca185;
}

.but-buttons .ant-slider-handle:focus {
  box-shadow: 0 0 0 5px #4ca185;
}

.but-buttons .ant-slider-handle {
  border-color: #2c7045;
  border: solid 2px #2c7045;
}

.left-roud {
  height: 20px;
  width: 20px;
  position: absolute;
  left: 50%;
  top: 10px;
  margin-left: -10px;
  background: url(../images/sq-box.png) no-repeat center;
}

.mr-roud {
  height: 20px;
  width: 20px;
  background: url(../images/cir-box.png) no-repeat center;
  position: absolute;
  left: 50%;
  top: 10px;
  margin-left: -10px;
}

.right-roud {
  height: 20px;
  width: 20px;
  background: url(../images/rou-box.png) no-repeat center;
  position: absolute;
  left: 50%;
  top: 10px;
  margin-left: -10px;
}

.leftd-roud1 {
  height: 14px;
  width: 20px;
  background: #434343;
  position: absolute;
  left: 10px;
  top: 12px;
  border-radius: 0px;
}

.mrd-roud1 {
  height: 14px;
  width: 20px;
  background: #434343;
  position: absolute;
  left: 50%;
  top: 12px;
  margin-left: -10px;
  border-radius: 0px;
}

.rigt-roud1 {
  height: 14px;
  width: 20px;
  background: #434343;
  position: absolute;
  right: 10px;
  top: 12px;
  border-radius: 0px;
}

.cirty {
  border: solid 1px #afb0b4;
  border-radius: 2px;
  padding: 0px;
  width: 25px;
}
.but-buttons .material-ui-rc-color-picker-wrap{display: block;}
.but-buttons .material-ui-rc-color-picker-trigger {
  height: 30px;
  width: 100%;
  border: 1px solid #000;
  opacity: 1;
}

.user-status .material-ui-rc-color-picker-trigger {
  height: 35px;
  width: 235px;
}

.team-and-free {
  padding: 15px;
}

.newdrawes {
  width: initial !important;
}

.newdrawes .ant-drawer.ant-drawer-open .ant-drawer-mask {
  height: initial !important;
}

.socilaspace {
  margin: 0px 5px;
}

// ==========Left Side menu 30/11/21=============
.but-buttons .choose-file {
  height: 100px;
  width: 150px;
  padding: 33px 0px 0px 28px;
}

// ======================shipping check===========
.new-check .checkbox:checked~.background-color {
  background-color: #fff;
}

.new-check span {
  font-size: 13px;
  font-weight: 400;
  padding-top: 5px;
  line-height: 18px;
  display: block;
}

.new-check [type="checkbox"]:checked,
.new-check [type="checkbox"]:not(:checked),
.new-check [type="radio"]:checked,
.new-check [type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
  width: 0;
  height: 0;
  visibility: hidden;
}

.new-check .checkbox:checked+label,
.new-check .checkbox:not(:checked)+label {
  position: relative;
  width: 70px;
  display: inline-block;
  padding: 0;
  margin: 0 auto;
  text-align: center;
  margin: 17px 0;
  margin-top: 100px;
  height: 6px;
  border-radius: 4px;
  // background-image: linear-gradient(298deg, var(#4ca185), var(#fff));
  background: linear-gradient(#4ca185, #51a35d);
  z-index: 100 !important;
}

.new-check .checkbox:checked+label:before,
.new-check .checkbox:not(:checked)+label:before {
  position: absolute;
  font-family: "unicons";
  cursor: pointer;
  top: -17px;
  z-index: 2;
  font-size: 18px;
  line-height: 40px;
  text-align: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  -webkit-transition: all 300ms linear;
  transition: all 300ms linear;
}

.new-check .checkbox:not(:checked)+label:before {
  content: "\eac1";
  left: 0;
  color: #fff;
  background-color: #f1f1f1;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(26, 53, 71, 0.07);
}

.new-check .checkbox:checked+label:before {
  content: "\eb8f";
  left: 30px;
  color: #fff;
  background-color: #fff;
  box-shadow: 0 4px 4px rgba(26, 53, 71, 0.25), 0 0 0 1px rgba(26, 53, 71, 0.07);
}

.new-check .checkbox:checked~.section .container .row .col-12 p {
  color: var(--dark-blue);
}

.new-check .checkbox-tools:checked+label,
.new-check .checkbox-tools:not(:checked)+label {
  position: relative;
  display: inline-block;
  padding: 8px 10px 8px 70px;
  width: 315px;
  font-size: 18px;
  line-height: 20px;
  letter-spacing: 1px;
  margin: 0 auto;
  font-weight: 700;
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 25px;
  text-align: left;
  border-radius: 4px;
  overflow: hidden;
  cursor: pointer;
  // text-transform: uppercase;
  color: #000;
  -webkit-transition: all 300ms linear;
  transition: all 300ms linear;
  min-height: 70px;
}

.new-check .checkbox-tools:not(:checked)+label {
  background-color: #f9f9f9;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}

.new-check .checkbox-tools:checked+label {
  background-color: transparent;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  color: #fff !important;
  background: #4ca185;
}

.new-check .checkbox-tools:not(:checked)+label:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.new-check .checkbox-tools:checked+label::before,
.new-check .checkbox-tools:not(:checked)+label::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  /* background-image: linear-gradient(298deg, var(#4ca185), var(#51a35d)); */
  background: linear-gradient(#4ca185, #51a35d);
  z-index: -1;
}

.new-check .checkbox-tools:checked+label .uil,
.new-check .checkbox-tools:not(:checked)+label .uil {
  font-size: 24px;
  line-height: 24px;
  display: block;
  padding-bottom: 10px;
  color: #fff;
}

.cltys {
  position: absolute;
  left: 10px;
  top: 15px;
  font-size: 40px;
}

.cltyss {
  -ms-transform: rotate(180deg);
  /* IE 9 */
  transform: rotate(180deg);
}

.new-check .free-shop {
  background: url(../images/free-shippingh-icon.png) no-repeat center top;
  background-size: 100%;
  width: 45px;
  height: 60px;
}

.new-check .checkbox-tools:checked+label .free-shop {
  background: url(../images/free-shipping-icon.png) no-repeat center top;
  background-size: 100%;
}

.new-check .flat-rate {
  background: url(../images/flat-rate.png) no-repeat center top;
  background-size: 100%;
  width: 45px;
  height: 60px;
}

.new-check .checkbox-tools:checked+label .flat-rate {
  background: url(../images/flat-rateh.png) no-repeat center top;
  background-size: 100%;
}

.new-check .rate-weight {
  background: url(../images/rate-weight.png) no-repeat center top;
  background-size: 100%;
  width: 45px;
  height: 60px;
}

.new-check .checkbox-tools:checked+label .rate-weight {
  background: url(../images/rate-weighth.png) no-repeat center top;
  background-size: 100%;
}

.new-check .rate-price {
  background: url(../images/rate-prices.png) no-repeat center top;
  background-size: 100%;
  width: 45px;
  height: 60px;
}

.new-check .checkbox-tools:checked+label .rate-price {
  background: url(../images/rate-pricesh.png) no-repeat center top;
  background-size: 100%;
}

.new-check .carrier-rate {
  background: url(../images/carrier-rate.png) no-repeat center top;
  background-size: 100%;
  width: 45px;
  height: 60px;
}

.new-check .checkbox-tools:checked+label .carrier-rate {
  background: url(../images/carrier-rateh.png) no-repeat center top;
  background-size: 100%;
}

.new-check .pickup {
  background: url(../images/pickup.png) no-repeat center top;
  background-size: 100%;
  width: 45px;
  height: 60px;
}

.new-check .checkbox-tools:checked+label .pickup {
  background: url(../images/pickuph.png) no-repeat center top;
  background-size: 100%;
}

.new-check .local-delivery {
  background: url(../images/local-delivery.png) no-repeat center top;
  background-size: 100%;
  width: 50px;
  height: 60px;
}

.new-check .checkbox-tools:checked+label .local-delivery {
  background: url(../images/local-deliveryh.png) no-repeat center top;
  background-size: 100%;
}

.shp-box {
  margin: 0px;
  width: 100px;
}

.shp-box img {
  float: left;
  width: 30px;
  margin-right: 6px;
}

// ======================shipping check===========

.ltiy input {
  padding-left: 45px;
}

.new-heigt input {
  font-size: 14px;
  padding-top: 3px !important;
  padding-bottom: 2px !important;
}

.new-tables {
  margin: 0px;
  padding: 10px 0px;
}

.new-tables table {
  width: 100%;
  border: solid 1px #e4e4e4;
}

.new-tables table tr {
  border-bottom: solid 1px #e4e4e4;
}

.new-tables table tr th {
  border-right: solid 1px #e4e4e4;
  padding: 10px 15px;
  background: #fafafa;
}

.new-tables table tr td {
  border-right: solid 1px #e4e4e4;
  padding: 10px 15px;
  background: #fff;
  font-weight: 400;
}

.account-box1 {
  padding: 20px 0px 0px 30px;
}

.account-box1 ul {
  list-style: none;
  padding: 0px 0px 20px;
  margin: 0px;
}

.account-box1 ul li {
  position: relative;
  padding: 5px 0px 5px 30px;
  font-size: 18px;
}

.account-box1 ul li span {
  position: absolute;
  left: 0px;
  top: 6px;
}

.account-box {
  padding: 150px 0px 0px 30px;
}

.account-box ul {
  list-style: none;
  padding: 0px;
  margin: 0px;
}

.account-box ul li {
  position: relative;
  padding: 5px 0px 5px 30px;
  font-size: 18px;
}

.account-box ul li span {
  position: absolute;
  left: 0px;
  top: 6px;
}

.accunt-box {
  // -webkit-box-shadow: 0px 7px 30px 0px rgba(235,235,235,1);
  // -moz-box-shadow: 0px 7px 30px 0px rgba(235,235,235,1);
  // box-shadow: 0px 7px 30px 0px rgba(235,235,235,1);
  -webkit-box-shadow: 0px 13px 25px -13px rgba(191, 191, 191, 1);
  -moz-box-shadow: 0px 13px 25px -13px rgba(191, 191, 191, 1);
  box-shadow: 0px 13px 25px -13px rgba(191, 191, 191, 1);
  background: #fff;
  position: relative;
  border-radius: 6px;
  width: 400px;
  margin: 130px 0px 0px;
  padding: 3px 30px 30px;
}

.accunt-box-circles {
  height: 150px;
  width: 150px;
  border-radius: 50%;
  position: absolute;
  margin-left: -75px;
  left: 50%;
  top: -75px;
  background: rgb(104, 103, 103);
  -webkit-box-shadow: 0px 16px 25px -8px rgba(153, 153, 153, 1);
  -moz-box-shadow: 0px 16px 25px -8px rgba(153, 153, 153, 1);
  box-shadow: 0px 16px 25px -8px rgba(153, 153, 153, 1);
}

.acc-button {
  width: 180px;
  margin: 10px auto;
  height: 45px;
  color: #fff;
  text-align: center;
  line-height: 45px;
  font-size: 18px;
}

.acc-button a {
  color: #fff;
  text-decoration: none;
  border-radius: 3px;
  display: block;
  background: #2c7045;
}

.acc-button a:hover {
  background: #4ca185;
}

.acc-icon {
  padding: 10px 0px 0px;
  text-align: center;
  font-size: 34px;
}

.acc-icon a {
  padding: 0px;
  margin: 0px 5px;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: inline-block;
  text-decoration: none;
  color: #000;
}

.acc-icon a:hover {
  color: #4ca185;
}

.account-integsm {
  margin: 10px;
  position: relative;
  border: 1px solid #f0f0f0 !important;
  -webkit-box-shadow: 0px 7px 30px 0px rgba(235, 235, 235, 1);
  -moz-box-shadow: 0px 7px 30px 0px rgba(235, 235, 235, 1);
  box-shadow: 0px 7px 30px 0px rgba(235, 235, 235, 1);
  background: #fff;
  width: auto;
  border-radius: 6px;
  min-height: 300px;
}

.top-box-hed {
  position: relative;
  height: 60px;
  border-bottom: solid 1px #f0f0f0;
  padding: 10px 20px;
  font-size: 20px;
}

.top-box-icon {
  position: absolute;
  right: 20px;
  top: 2px;
  font-size: 30px;
}

.top-box-sms {
  position: absolute;
  right: 70px;
  top: 17px;
  font-size: 14px;
}

.cont-padd20 {
  padding: 10px 20px;
}

.cont-padd20 ul {
  list-style: none;
  margin: 0px;
  padding: 0px;
}

.cont-padd20 ul li {
  padding: 6px 0px 6px 20px;
  position: relative;
  margin: 0px;
  font-size: 16px;
}

.cont-padd20 ul li span {
  position: absolute;
  left: 0px;
  top: 12px;
  font-size: 10px;
}

.border-box {
  border: solid 1px #dfdfdf;
  width: 100%;
}

.dg-buts {
  border: solid 0px #f1f1f1;
  background: #f2f3f7;
  padding: 3px 15px;
  float: left;
  margin: 3px;
  // font-weight: 400;
  border-radius: 3px;
}

.dg-buts:hover {
  background: rgb(206, 206, 206);
}

.compain-conter {
  border-radius: 0px;
  border: solid 1px #e4e4e4;
  margin: 10px auto;
  background: #f7f7f7;
}

.compain-boxer {
  padding: 20px 0px 0px 40px;
  // height: 150px;

  margin: 0px 10px;
  border-radius: 0px;
}

// .compan-boxa{
//   background: #048f73;
//    text-align: center;
//   height: 150px; width: 100%;
//   line-height: 150px; color: #fff; font-size: 60px; border-radius: 8px 0px 0px 8px;}
.compan-hed {
  height: 35px;
  font-size: 14px;
  color: #202020;
  padding: 10px 0px 0px;
  text-transform: uppercase;
  font-weight: 700;
}

.compan-text {
  font-size: 16px;
  font-weight: 400;
  padding-top: 10px;
}

.compan-text span {
  font-size: 16px;
  font-weight: 700;
}

.compan-cirles {
  position: relative;
  padding: 0px 0px 0px 15px;
  margin: 0px 0px;
}

.compan-cirles span {
  position: absolute;
  height: 11px;
  width: 11px;
  border-radius: 0%;
  left: 0px;
  top: 9px;
  background: #02a1ea;
}

.compn-line {
  height: 157px;
  width: 2px;
  background: #e4e4e4;
  position: absolute;
  right: 0px;
  top: 15px;
}

.compn-lines {
  position: relative;
  padding: 60px 30px 10px 50px;
}

.compn-lines1 {
  position: absolute;
  left: 25px;
  top: 45px;
}

.compn-lines2 {
  position: absolute;
  line-height: 14px;
  top: 60px;
  left: 0px;
  padding-right: 0px;
  width: 100%;
}

.compn-lines2 span {
  font-weight: 400;
}

.ship-line-color {
  height: 15px;
  background: #f3f4f6;
  width: 100%;
  margin: 0px 0px 20px;
}

.com-ds-box {
  border: solid 1px #e1e1e1;
  padding: 5px;
  overflow-y: auto;
  max-height: 120px;
}

// .share-buts{ position: fixed; right: 30px; top: 75px;}
.share-buts {
  height: 36px;
  width: 120px;
  background: #87bc58;
  line-height: 34px;
  color: #fff;
  position: fixed;
  right: 30px;
  bottom: 6px;
  display: block;
  border-radius: 50px;
  z-index: 9;
  border: 0px;
  font-size: 17px;
  text-align: center;
  cursor: pointer;
}

.share-buts:hover {
  background: #518028;
}

.share-boxs {
  margin: 0px;
  padding: 0px;
}

.share-boxs ul {
  list-style: none;
  border-top: solid 0px #ccc;
  border-bottom: solid 1px #ccc;
  padding: 10px 0px;
}

.share-boxs ul li {
  float: left;
  width: 22%;
  text-align: center;
  margin: 20px 1.5%;
}

.share-boxs ul li .pics {
  height: 40px;
  width: 36px;
  margin: 0px auto;
}

.share-boxs ul li .pics img {
  width: 100%;
}

.share-boxs ul li a {
  color: #666;
  text-decoration: none;
}

.share-input {
  height: 40px;
  width: 100%;
  border: solid 1px #ccc;
  border-radius: 3px;
  width: 65%;
  float: left;
}

.share-butts {
  position: relative;
  padding: 10px 0px;
  width: 100%;
}

.share-butts span {
  position: absolute;
  left: 10px;
  top: 0px;
  padding: 0px 5px;
  background: #fff;
  font-size: 13px;
  z-index: 1;
}

.share-butt {
  background: #87bc58;
  padding: 0px 25px;
  border-radius: 50px;
  color: #fff;
  height: 40px;
  line-height: 40px;
  border: 0px;
  width: 30%;
  float: right;
  cursor: pointer;
}

.share-butt:hover {
  background: #649936;
  color: #fff;
}

.compan-boxanew {
  background: #17226c;
  width: 100%;
  margin-left: 0px;
  text-align: center;
  color: #fff;
  border-radius: 0px 0px 0px 0px;
}

.compan-boxanew i {
  text-align: center;
  font-size: 60px;
  padding: 8px 0px;
}

.compan-boxanew h3 {
  font-size: 18px;
  color: #fff;
  padding: 10px;
  margin: 0px;
}

.compan-hedt {
  font-size: 14px;
  color: #202020;
  padding: 10px 0px 10px 10px;
  font-weight: 700;
}

.top-border {
  border-top: solid 1px #fff;
}

.bott-border {
  border-bottom: solid 1px #fff;
}

.compain-boxer .ant-progress .ant-progress-outer .ant-progress-inner {
  background-color: #d6d6d6;
  border: solid 1px #ccc;
  border-radius: 10px;
}

.compain-boxer .ant-progress .ant-progress-outer .ant-progress-inner .ant-progress-bg {
  background-color: #98d9eb;
  border-radius: 10px;
}

.compain-boxer .ant-progress-success-bg {
  background-color: #02a1ea;
  height: 16px !important;
  border-radius: 10px;
}

.compain-boxer .ant-progress .ant-progress-outer {
  cursor: initial;
}

.kiosk-fonts {
  margin: 0px;
  font-size: 20px;
}

.kiosk-fonts ul {
  margin: 0px;
  padding: 0px 30px 0px 30px;
  list-style: none;
}

.kiosk-fonts ul li {
  margin: 5px 0px;
  padding: 20px 0px 30px 120px;
  border-bottom: solid 2px #f3f4f6;
  position: relative;
}

.kiosk-fonts ul li span {
  width: 100px;
  position: absolute;
  left: 0px;
  top: 63%;
  margin-top: -50px;
}

.kiosk-fonts ul li span img {
  width: 100%;
}

.kiosk-fonts h2 {
  padding-left: 30px;
}

.newr-logo {
  width: 200px;
  text-align: center;
  height: 150px;
  position: relative;
  display: inline-block;
}

.newr-logo img {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
  text-align: center;
  max-width: 100%;
  max-height: 100%;
}

.cicon-hov:hover {
  color: #048f73;
}

.top-box-icon1 {
  position: absolute;
  left: 20px;
  top: 11px;
  font-size: 24px;
}

.send-smss .ant-select-selector {
  max-height: 170px;
  overflow: auto;
}

.pay-box-por {
  position: relative;
  height: 100%;
  background: #f2f2f2 url(../images/doller-bg.jpg) repeat center top;
  background-size: cover;
  // background: rgb(241, 241, 241);
}

.pay-box-porls {
  position: relative;
  height: 100%;
  background: #f2f2f2 url(../images/doller-bg.jpg) repeat center top;
  background-size: cover;
  display: grid;
  // background: rgb(241, 241, 241);
}


.pay-ment-box {
  border: 0px solid #f0f0f0 !important;
  -webkit-box-shadow: 0px 7px 30px 0px rgba(235, 235, 235, 1);
  -moz-box-shadow: 0px 7px 30px 0px rgba(235, 235, 235, 1);
  box-shadow: 0px 7px 30px 0px rgba(235, 235, 235, 1);
  background: #fff;
  border-radius: 10px;
  margin: 5% auto 7%;
  padding: 0px;
  position: relative;
  width: 500px;
}

.pay-ment-box-form {
  border: 0px solid #f0f0f0 !important;
  -webkit-box-shadow: 0px 7px 30px 0px rgba(235, 235, 235, 1);
  -moz-box-shadow: 0px 7px 30px 0px rgba(235, 235, 235, 1);
  box-shadow: 0px 7px 30px 0px rgba(235, 235, 235, 1);
  background: #fff;
  border-radius: 10px;
  margin: 5% auto;
  padding: 0px;
  position: relative;
  width: 750px;
}

.pading-rii {
  padding-right: 20px;
}

.pay-ment-boxcp {
  border: 0px solid #f0f0f0 !important;
  -webkit-box-shadow: 0px 7px 30px 0px rgba(235, 235, 235, 1);
  -moz-box-shadow: 0px 7px 30px 0px rgba(235, 235, 235, 1);
  box-shadow: 0px 7px 30px 0px rgba(235, 235, 235, 1);
  background: #fff;
  border-radius: 10px;
  margin: 0% auto 2%;
  padding: 0px;
  position: relative;
  width: 500px;
}

.pay-ment-boxcpc {
  border: 0px solid #f0f0f0 !important;
  -webkit-box-shadow: 0px 7px 30px 0px rgba(235, 235, 235, 1);
  -moz-box-shadow: 0px 7px 30px 0px rgba(235, 235, 235, 1);
  box-shadow: 0px 7px 30px 0px rgba(235, 235, 235, 1);
  background: #fff;
  border-radius: 10px;
  margin: 0% auto 2%;
  padding: 0px;
  position: relative;
  width: 500px;
}

.logosizef {
  font-size: 22px;
}

.logity {
  padding-top: 13px;
}

@media only screen and (max-width: 767px) {
  .pay-ment-box {
    width: 90%;
    padding: 30px;
  }

  .pay-ment-box-form {
    width: 98%;
    padding: 30px 0px;
    margin: 0px auto;
  }

  .pading-rii {
    padding-right: 0px;
  }

  .pay-ment-boxcp {
    width: 100%;
  }

  .pay-ment-boxcpc {
    width: 98%;
    margin: 0px auto 2%;
    overflow: hidden;
  }

  .ltiuy .ant-col-8 {
    display: block;
    -ms-flex: 100%;
    flex: 100%;
    max-width: 100%;
  }

  .logity {
    padding-top: 0px;
  }

  .pay-ment-box-form .container::before {
    display: initial;
  }

  .ltiuy .ant-col-1 {
    display: block;
    -ms-flex: 20%;
    flex: 20%;
    max-width: 20%;
  }

  .ltiuy .ant-col-2 {
    display: block !important;
    -ms-flex: 20%;
    flex: 20%;
    max-width: 20%;
  }

  .ltiuy .ant-col-12 {
    display: block;
    -ms-flex: 71%;
    flex: 71%;
    max-width: 71%;
  }

  .logosizef {
    font-size: 18px;
  }

  .ltiuy .newaddr {
    padding: 10px 0px 0px 0px;
    text-align: center;
  }

  .ltiuy .newaddr span {
    padding-right: 5px;
    display: inline-block;
  }

  .ltiuy .newaddr div {
    display: inline-block;
    // float: left;
  }

  .ltiuy .litlyu {
    display: none !important;
  }

  .pay-ment-box-form iframe {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important;
    height: 1000px;
  }

  .pay-ment-box-form div {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important;
  }

  .pay-ment-box-form .container {
    width: 100% !important;
  }
}

.goal-text {
  font-size: 11px;
  background: #fff;
  padding: 1px 5px;
  border-radius: 4px;
  color: #000;
  position: relative;
  top: -3px;
}

.font-28 {
  font-size: 28px;
}

.pointers {
  width: 100px;
  height: 40px;
  position: relative;
  background: rgb(255, 255, 255);
}

.pointers:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 0;
  border-left: 20px solid white;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
}

.pointers:before {
  content: "";
  position: absolute;
  right: -20px;
  bottom: 0;
  width: 0;
  height: 0;
  border-left: 20px solid rgb(255, 255, 255);
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
}

.right-text-coms {
  float: right;
  font-weight: 600;
}

.com-bocsli {
  height: 70px;
  position: relative;
}

.com-bocsli h3 {
  position: absolute;
  top: 50%;
  text-align: center;
  left: 0%;
  -ms-transform: translate(0%, -50%);
  transform: translate(0%, -50%);
  width: 100%;
  font-weight: 400;
}

.pay-ment-box .ant-tabs-nav .ant-tabs-tab-active {
  color: #79a067;
}

.pay-ment-box .ant-tabs-ink-bar {
  background-color: #79a067;
}

.pay-but {
  height: 45px;
  width: 90px;
  text-align: center;
  margin: 10px;
  border-radius: 3px;
  float: left;
  font-size: 18px;
  border: solid 1px #b2b3b5;
  background-color: initial;
  cursor: pointer;
}

.pay-butl {
  height: 45px;
  width: 200px;
  text-align: center;
  margin: 10px;
  border-radius: 3px;
  float: left;
  font-size: 18px;
  border: solid 1px #b2b3b5;
  background-color: initial;
  cursor: pointer;
}

.pay-but2 {
  border: 0px;
  background-color: #eaeaea;
  height: 50px;
  width: 95.1%;
  margin: 35px 10px 10px 10px;
  line-height: 50px;
}

.pay-but2 span {
  color: #000;
}

.pay-but3 {
  border: 0px;
  background-color: #2c7145;
  height: 50px;
  width: 95.1%;
  color: #fff;
  font-size: 20px;
  text-align: center;
  margin: 10px;
  cursor: pointer;
}

.pay-ment-box .ant-tabs-nav .ant-tabs-tab {
  width: 40%;
  text-align: center;
}

.pay-ment-box .ant-tabs-nav {
  width: 100%;
}

.pay-buth {
  border: solid 1px #79a067;
  background: #2c7145;
  color: #fff;
}

.pay-text {
  //  text-align: center;
  padding: 10px 30px 0px;
  overflow: hidden;
}

.pay-card-pic {
  text-align: center;
  padding: 0px 0px 10px;
}

.pay-card-pic img {
  width: 180px;
}

.pay-footer-box {
  background: #e9e9e9;
  color: #333;
  padding: 10px 0px;
  margin-left: 0px !important;
  margin-right: 0px;
  margin-top: 15px;
  position: fixed;
  width: 100%;
  bottom: 0px;
}

@media only screen and (max-width: 600px) {
  .pay-box-por .ant-col-2 {
    display: none;
  }

  .pay-box-por .ant-col-1 {
    flex: 0 0 15%;
    max-width: 15%;
  }

  .pay-box-por .ant-col-13 {
    flex: 0 0 74%;
    max-width: 74%;
  }

  .pay-box-por .ant-col-8 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  //  .pay-box-por .ant-col-8 span{ padding-right: 4px;}

  .pay-box-por .brand-logo {
    width: 50px;
  }

  // .pay-box-por .billing-address{ display: table-column;}
  .pay-footer-box {
    position: initial;
  }

  .pay-footer-box .ant-col-9 {
    flex: 0 0 100%;
    max-width: 100%;
    text-align: center !important;
  }

  .pay-footer-box .ant-col-9 span {
    float: initial !important;
  }

  .pay-footer-box .ant-col-6 {
    flex: 0 0 100%;
    max-width: 100%;
    text-align: center !important;
  }

  .logosizef {
    text-align: center;
  }
}

@media only screen and (max-width: 490px) {
  .pay-text {
    padding: 10px 0px 0px;
  }

  .pay-ment-box {
    padding: 5px 15px 15px;
  }

  .pay-box-por .ant-col-2 {
    display: none;
  }

  .pay-box-por .ant-col-1 {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .pay-box-por .ant-col-13 {
    flex: 0 0 70%;
    max-width: 70%;
  }

  .pay-box-por .ant-col-8 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  // .pay-box-por .ant-col-8 span{ float: left;}

  .pay-box-por .brand-logo {
    width: 50px;
  }

  // .pay-box-por .billing-address{ display: table-column;}
  .pay-footer-box {
    position: initial;
  }

  .pay-footer-box .ant-col-9 {
    flex: 0 0 100%;
    max-width: 100%;
    text-align: center !important;
  }

  .pay-footer-box .ant-col-9 span {
    float: initial !important;
  }

  .pay-footer-box .ant-col-6 {
    flex: 0 0 100%;
    max-width: 100%;
    text-align: center !important;
  }
}

@media only screen and (max-width: 400px) {
  .pay-but {
    margin: 5px 0.5%;
    width: 24%;
  }

  .pay-butl {
    width: 48%;
    margin: 5px 0.5%;
  }

  .pay-fot .ant-row .ant-col-9 {
    width: 100% !important;
    flex: 0 0 100%;
    max-width: 100%;
    text-align: center !important;
    float: initial !important;
    padding: 5px 0px;
  }

  .pay-fot .ant-row .ant-col-9 span {
    float: initial !important;
  }

  .pay-fot .ant-row .ant-col-6 {
    width: 100% !important;
    flex: 0 0 100%;
    max-width: 100%;
    text-align: center !important;
  }
}

@media only screen and (max-width: 340px) {
  // .pay-but{ margin: 5px; width: 70px;}
}

.new-tablesc {
  margin: 0px;
  padding: 10px 0px;
}

.new-tablesc table {
  width: 100%;
  border: solid 1px #e4e4e4;
}

.new-tablesc table tr {
  border-bottom: solid 1px #e4e4e4;
}

.new-tablesc table tr th {
  border-right: solid 1px #e4e4e4;
  padding: 10px 15px;
  background: #fafafa;
}

.new-tablesc table tr td {
  border-right: solid 1px #e4e4e4;
  padding: 10px 15px;
  background: #fff;
  font-weight: 400;
}

.new-tablesc table tr th:nth-child(1) {
  width: 40px;
}

.new-tablesc table tr th:nth-child(7) {
  width: 60px;
}

.newdrawes .ant-picker-suffix {
  display: none;
}

.dark-inss {
  //  background: #fff;
  height: 100%;
  position: relative;
  color: #a0a2c0;
  padding: 15px 0px 10px;
  background: url(../images/donationweb-bg.jpg) no-repeat;
  background-size: 100%;
}

.dark-inssp {
  //  background: #fff;
  height: 100vh;
  position: relative;
  color: #a0a2c0;
  padding: 15px 0px 10px;
  background: url(../images/donationweb-bg.jpg) no-repeat;
  background-size: 100%;
}

.dark-inss ul {
  padding: 10px 0px 0px;
  font-size: 16px;
  list-style: none;
}

.dark-inss ul li {
  list-style: none;
  padding: 10px 0px;
  cursor: pointer;
  border-bottom: solid 0px #363749;
  border-radius: 50px;
  // -webkit-box-shadow: 0 0 20px rgba(89, 102, 122, 0.15);
  // box-shadow: 0 0 20px rgba(89, 102, 122, 0.15);
  -webkit-box-shadow: 0px 10px 11px -13px rgba(171, 169, 171, 1);
  -moz-box-shadow: 0px 10px 11px -13px rgba(171, 169, 171, 1);
  box-shadow: 0px 10px 11px -13px rgba(171, 169, 171, 1);

  // background: #fff;
  width: 47%;
  float: left;
  margin: 10px 1.5%;
  color: #0f67d9;
  text-align: center;
  font-size: 18px;
  border: solid 1px #dfdfdf;
  //   text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.14);
  //  box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.2);
}

// .dark-inss div{padding-left: 25px;}
.dark-inss ul li:hover {
  // background: #e9ffd5;
  background: linear-gradient(#ffffff, #f1f1f1);
  cursor: pointer;
}

.active-prject {
  // background: #f7f7f7;
  background: linear-gradient(#ffffff, #f1f1f1);
  color: #0f67d9;
}

.dark-inss ul label {
  margin: 0px;
  padding: 0px 15px;
  font-size: 20px;
}

.new-contaner1 {
  border: 0px solid #f0f0f0 !important;
  -webkit-box-shadow: 0px 7px 30px 0px rgba(235, 235, 235, 1);
  -moz-box-shadow: 0px 7px 30px 0px rgba(235, 235, 235, 1);
  box-shadow: 0px 7px 30px 0px rgba(235, 235, 235, 1);
  background: #fff;
  border-radius: 10px;
  margin: 5% auto 0px;
  padding: 20px;
  width: 600px;
}

.medl-hedl {
  text-align: center;
  font-size: 26px;
  color: #000000;
  margin: 0px auto 0px auto;
  width: 96%;
  padding: 5px 0px;
  display: block;
  border-radius: 6px;
}

.medl-hedl span {
  border-bottom: solid 1px #87bc59;
}

.loty .input-text {
  width: 84% !important;
}

.newregis .currency-tag {
  padding: 3px 0px 0px;
}

.newregis input {
  padding-left: 45px;
}

.confirm-boxs {
  margin: 110px auto 0px;
  width: 800px;
  background: #fff;
  -webkit-box-shadow: 0px 7px 30px 0px rgba(235, 235, 235, 1);
  -moz-box-shadow: 0px 7px 30px 0px rgba(235, 235, 235, 1);
  box-shadow: 0px 7px 30px 0px rgba(235, 235, 235, 1);
}

.confirm-boxs1 {
  margin: 0px auto 0px;
  width: 800px;
  background: #fff;
  -webkit-box-shadow: 0px 7px 30px 0px rgba(235, 235, 235, 1);
  -moz-box-shadow: 0px 7px 30px 0px rgba(235, 235, 235, 1);
  box-shadow: 0px 7px 30px 0px rgba(235, 235, 235, 1);
}

.done-popup {
  background: #0ba685;
  // background-image: linear-gradient(#0ba383, #09a987);
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px;
}

.done-popup .checkmark {
  border-radius: 50%;
  box-shadow: inset 0 0 0 #16a085;
  display: block;
  height: 56px;
  margin: 20px auto;
  stroke: #0ba685;
  stroke-miterlimit: 10;
  stroke-width: 2;
  width: 56px;
  animation: fill 0.4s ease-in-out 0.4s forwards,
    scale 0.3s ease-in-out 0.9s both;
}

.done-popup .checkmark-circle {
  fill: none;
  stroke: #fff;
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-miterlimit: 10;
  stroke-width: 2;
  animation: checked 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.done-popup .checkmark-check {
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  transform-origin: 50% 50%;
  animation: checked 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

$green: #fff;
$white: #fff;

// Misc
$curve: cubic-bezier(0.65, 0, 0.45, 1);

.checkmark {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: $white;
  stroke-miterlimit: 10;
  box-shadow: inset 0px 0px 0px $green;
  animation: fill 0.4s ease-in-out 0.4s forwards,
    scale 0.3s ease-in-out 0.9s both;
}

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: $green;
  fill: none;
  animation: stroke 0.6s $curve forwards;
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s $curve 0.8s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes scale {

  0%,
  100% {
    transform: none;
  }

  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px $green;
  }
}

@media only screen and (max-width: 767px) {
  .confirm-boxs {
    width: 100%;
    margin: 10px auto;
  }

  .confirm-boxs1 {
    width: 100%;
    margin: 10px auto;
  }
}

// ============TV screen==============
.bg-carve {
  background: linear-gradient(152deg, #bde0da 70%, #e6e6e6 60%);
  background-color: initial;
  color: #984a53;
  padding: 10px;
  margin: 0px 0px;
  // background:'#BDE0DA',
}

.cirt {
  // background: #eff8ff;
  width: 80%;
  margin: 0px auto;
  border-radius: 5px;
  background: #fff;
  border: solid 1px #a1b3e1;
  padding: 10px 0px;
}

.drtys {
  //  background: #d0f1eb;
  //   background: #c8ece6;
  //   background: #e4e6e5;
  padding: 0px 7px;
  margin: 4px auto;
  position: relative;
  text-align: center;
}

.drtys1 {
  background: #d0f1eb;
  background: #c8ece6;
  background: #1a73e8;
  padding: 0px 0px 0px 70px;
  height: 60px;
  color: #fff;
  line-height: 52px;
  margin: 10px 45px 10px 0px;
  position: relative;
  border-radius: 50px;
}

.ltuyiu {
  position: absolute;
  background: #fff;
  top: 0px;
  left: -10px;
  text-align: center;
  height: 60px;
  width: 60px;
  line-height: 60px;
  border-radius: 50%;
  color: #000;
}

// ============TV screen==============

.rem-bgs {
  color: #000;
  font-family: "League Spartan", Arial, sans-serif;
  background: url(../images/screen-bg.jpg) no-repeat center;
  background-size: cover;
  height: 100vh;
  position: relative;
}

.rem-bgs .ant-upload-list-item-info .anticon-loading .anticon,
.ant-upload-list-item-info .ant-upload-text-icon .anticon {
  display: none;
}

.fotrsp {
  background: #e9e9e9;
  color: #333;
  padding: 10px 0px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 15px;
  width: 100%;
  bottom: 0px;
  position: fixed;
}

.leftright {
  padding-left: 15px;
  padding-right: 15px;
  border-top: solid 12px #f3f4f6;
  padding-bottom: 20px;
}

.leftright h3 {
  font-size: 24px;
}

.butmat {
  margin-bottom: 90px;
}

.compainbar .ant-progress .ant-progress-outer .ant-progress-inner .ant-progress-bg {
  background-color: #017cc9;
  // background-color: #98d9eb;
  border-radius: 100px;
}

.compainbar .ant-progress .ant-progress-outer .ant-progress-inner {
  background-color: #d6d6d6;
  border-radius: 10px;
}

.compainbar .ant-progress .ant-progress-outer .ant-progress-inner {
  background-color: #d6d6d6;
  border: solid 1px #ccc;
  border-radius: 10px;
}

.compainbar .ant-progress .ant-progress-outer .ant-progress-inner .ant-progress-bg {
  background-color: #98d9eb;
  border-radius: 10px;
}

.compainbar .ant-progress-success-bg {
  background-color: #02a1ea;
  height: 16px !important;
  border-radius: 10px;
}

.compainbar .anticon svg {
  display: none;
}

@media only screen and (max-width: 767px) {
  .fotrsp {
    background: #e9e9e9;
    color: #333;
    padding: 10px 0px;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 15px;
    width: 100%;
    bottom: initial;
    position: initial;
  }

  .pay-ment-box-form .container::before {
    display: initial !important;
  }
}

@media only screen and (max-width: 767px) {
  .pay-ment-box-form .marginTop20 {
    margin-top: 0px !important;
  }

  .butmat {
    margin-bottom: 20px;
  }
}

// ============Slider============

.cunc {
  position: relative;
  height: 90px;
  font-family: "League Spartan", Arial, sans-serif;
  padding-top: 0px;
  margin-top: 0px;
}

.cunca {
  font-size: 24px;
  font-weight: 400;
  text-align: center;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.rais-prog .ant-progress .ant-progress-inner {
  height: 250px !important;
  width: 250px !important;
}

.rais-prog .ant-carousel .slick-dots li {
  display: none !important;
}

.rais-prog .slick-dots .slick-dots-bottom {
  display: none !important;
}

.inplt input {
  padding-left: 48px;
}

.compain-conterf {
  border-radius: 0px;
  border: solid 1px #e4e4e4;
  margin: 10px 0% 10px 0px;
  background: #f7f7f7;
  position: relative;
}

.new-tablescfc {
  margin: 0px;
  padding: 10px 0px;
}

.new-tablescfc table {
  width: 100%;
  border: solid 1px #e4e4e4;
}

.new-tablescfc table tr {
  border-bottom: solid 1px #e4e4e4;
}

.new-tablescfc table tr th {
  border-right: solid 1px #e4e4e4;
  padding: 10px 10px;
  background: #fafafa;
}

.new-tablescfc table tr td {
  border-right: solid 1px #e4e4e4;
  padding: 10px 10px;
  background: #fff;
  font-weight: 400;
}

.new-tablescfc table tr th:nth-child(1) {
  width: 200px;
}

.new-tablescfc table tr th:nth-child(4) {
  width: 160px;
}

.new-tablescfc table tr th:nth-child(5) {
  width: 120px;
}

.fcin input[type="number"] {
  width: 70% !important;
}

// .limtl{ width: 200px;}
// .limtl input { width: 200px; }
.ltiyst input {
  padding-left: 45px;
  height: auto;
}

.greenbar {
  text-align: center;
}

.bluebar {
  text-align: center;
}

.bluebar .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
  stroke: #001e57;
}

.greenbar .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
  stroke: #538234;
}

@media only screen and (max-width: 767px) {
  .limtl {
    width: 130px !important;
  }

  .limtl .ant-input-number {
    width: 130px !important;
  }

  .limtl input {
    width: 130px !important;
    padding-left: 10px !important;
  }

  .payipuyt .ant-col-11 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .payipuyt .ant-col-11 input {
    width: 98%;
  }

  .pay-but2 {
    margin-top: 50px;
  }

  .new-contaner1 {
    width: 98%;
    margin: 0px auto;
  }

  .dark-inss ul li {
    float: initial;
    width: 90%;
    margin: 10px auto;
  }
}

.new-table-camp {
  margin: 0px;
  padding: 10px 0px;
}

.new-table-camp table {
  width: 100%;
  border: solid 1px #e4e4e4;
}

.new-table-camp table tr {
  border-bottom: solid 1px #e4e4e4;
}

.new-table-camp table tr th {
  border-right: solid 1px #e4e4e4;
  padding: 10px 15px;
  background: #fafafa;
}

.new-table-camp table tr td {
  border-right: solid 1px #e4e4e4;
  padding: 10px 15px;
  background: #fff;
  font-weight: 400;
}

.new-table-camp table tr th:nth-child(1) {
  width: 40px;
}

.new-table-attend {
  margin: 0px;
  padding-left: 16px;
  padding-right: 6px;

  table {
    width: 100%;
    border: solid 1px #e4e4e4;

    tr th {
      border-right: solid 1px #e4e4e4;
      padding: 5px;
      background: #fafafa;
      cursor: pointer;
    }

    tr td {
      border-right: solid 1px #e4e4e4;
      padding: 5px;
      background: #fff;
      font-weight: 400;
    }
  }
}

.org-bg {
  // background-color: rgba(195, 124, 82, 0.7);
  // background: #10684c;
  color: #fff;
  background: #103768;
  position: relative;
  padding: 10px 10px 10px 70px;
  height: 90px;
  line-height: 40px;
}

.gree-bg {
  // background-color: rgba(155, 198, 129, 0.7);
  background: #244d4e;
  color: #fff;
  position: relative;
  padding: 10px 10px 10px 70px;
  height: 90px;
  line-height: 40px;
}

.blue-bg {
  // background-color: rgba(142, 186, 225, 0.7);
  background: #053415;
  color: #fff;
  position: relative;
  padding: 10px 10px 10px 10px;
  height: 90px;
  line-height: 40px;
}

.whit-cire {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  text-align: center;
  line-height: 46px;
  background: #fff;
  color: #000;
  position: relative;
  // left: 10px;
  top: 10px;
  margin: 0px 10px;
  display: inline-block;
}

.whit-cire img {
  margin: 8px auto 0px;
  text-align: center;
  vertical-align: middle;
}

.slidert-ng {
  position: relative;
  bottom: 0px;
  z-index: 999;
}

.rais-prog {
  // padding: 3.2% 0px;
  margin: 0;
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -35%);
  width: 100%;
}

.rem-bgs .ant-carousel .slick-dots li button {
  display: none !important;
}

.rem-bgs .ant-carousel .slick-dots li.slick-active button {
  background: initial !important;
}

.greenbar .ant-progress-inner {
  border-radius: 150px;
}

.greenbar .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
  stroke: #538234;
  stroke-width: 20px;
}

.bluebar .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
  stroke: #001e57;
  stroke-width: 20px;
}

.bluebar .ant-progress-inner {
  border-radius: 150px;
}

.bluebar .ant-progress-circle-trail {
  stroke: #6f97e9;
}

.greenbar .ant-progress-circle-trail {
  stroke: #a8d588;
}

.timer-box {
  background: #b97a57;
  text-align: center;
  padding: 0px 0px 0px 0px;
  max-width: 800px;
  margin: 0px auto 10px;
  position: relative;
  bottom: 10px;
  perspective: 1000px;
}

.timer-box h3 {
  padding: 10px 0px;
  text-align: center;
  color: #fff;
  font-size: 30px;
  margin: 0px;
  padding: 2px 0px 3px;
}

.timer-box1 {
  background: #18323d;
  color: #fff;
  font-weight: 300;
  text-align: center;
  font-size: 50px;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

.timer-box1 span {
  font-size: 20px;
}

.timer-box1 .timers {
  display: inline-block;
  padding: 5px 50px 0px 40px;
  margin: 0px 0px;
  position: relative;
}

.timer-box1 .timers span {
  position: absolute;
  right: 0px;
  top: 29px;
  text-align: left;
  width: 45px;
}

.time-but {
  position: fixed;
  left: 0px;
  bottom: 12%;
  width: 100%;
}

// =======flip================
.priuwer {
  perspective: 1000px;
}

.flip-bpxf {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.flip-bpxb {
  transform: rotateY(180deg);
}

.flip-bpxbb {
  transform: rotateY(180deg);
}

.cacount-bgs {
  background: linear-gradient(172deg, #fff 60%, #fffaf2 60%);
  background-color: initial;
  padding-bottom: 30px;
  height: 100vh;
}

.white-box-sprter {
  background-color: #fff;
  box-shadow: 0 2px 2px rgba(1, 1, 1, 0.1);
  padding: 25px 15px 30px 15px;
  margin-bottom: 20px;
}

.emailNews-cont div {
  width: 98% !important;
  margin: 0px auto;
}

.emailNews-cont .mobpic img {
  width: 100% !important;
}

.emailNews-cont .socialbutt a {
  display: inline-block;
  margin: 10px;
}

.emailNews-cont .mdilebutton a:nth-child(1) {
  margin: 10px auto 10px 50px !important;
}

.emailNews-cont .mdilebutton a:nth-child(2) {
  margin: 10px auto 10px -10px !important;
}

.emailNews-cont .textre {
  margin: 0px auto;
}

.emailNews-cont .progresso span:nth-child(2) {
  padding-right: 25px;
}

.emailNews-cont .divi-pic img {
  width: 98% !important;
  margin: 0px auto;
}

.modal-custom-detail-new .mobile-shapes .mobile-previews div.mdilebutton a {
  margin: 10px auto !important;
  float: initial !important;
  padding: 0px 20px !important;
}

.modal-custom-detail-new .mobile-shapes .mobile-previews div.socialbutt a {
  display: inline-block;
  padding: 10px 0px;
}

.emailNews-cont #temmplateDiv {
  padding-bottom: 50px;
}

.bottpad .ant-drawer-body {
  // margin-bottom: 40px;
}

.emailNews-cont ul {
  margin-left: 30px;
}

.emailNews-cont .alltime:hover {
  border: 0px;
}

.material-ui-rc-color-picker {
  position: fixed !important;
}

.newheight {
  height: 450px;
  overflow-y: auto;
  overflow-x: hidden;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-device-width: 1024px) {}

@media screen and (-webkit-min-device-pixel-ratio: 0) {

  select,
  textarea,
  input {
    font-size: 14px;
  }
}

.new-tablere {
  margin: 0px 0px 30px;
  padding: 10px 0px;
  width: 100%;
}

.new-tablere table {
  width: 100%;
  border: solid 1px #e4e4e4;
  overflow-x: visible;
  display: flex;
  white-space: pre;
}

.new-tablere table tr {
  border-bottom: solid 1px #e4e4e4;
  width: max-content;
  display: flex;
}

.new-tablere table tr th {
  border-right: solid 1px #e4e4e4;
  padding: 4px 5px;
  background: #fafafa;
}

.new-tablere table tr td {
  border-right: solid 1px #e4e4e4;
  padding: 5px;
  background: #fff;
  font-weight: 400;
}

.new-tablere .ant-input-number {
  height: auto;
  width: 98% !important;
  display: block;
}

.new-tablere .hert .input-text {
  height: auto;
  width: 98% !important;
  display: block;
}

.new-tablere .hert textarea {
  height: auto;
  width: 98% !important;
  display: block;
  overflow: initial;
}

.new-tablere table tr th:nth-child(1) {
  position: absolute;
  left: 36px;
  width: 30px;
}

.new-tablere table tr th:nth-child(2) {
  margin-left: 35px;
  display: block;
  background: #fff;
}

.new-tablere table tr td:nth-child(2) {
  margin-left: 35px;
  display: block;
}

.new-tablere table tr td:nth-last-child(1) {
  position: absolute;
  right: 35px;
  padding: 10px 16px 0px 0px;
}

.new-tablere table tr td:nth-last-child(2) {
  margin-right: 30px;
  display: block;
  padding-top: 5px;
  width: 160px;
}

.new-tablere table tr th:nth-last-child(1) {
  position: absolute;
  right: 35px;
  width: 50px;
  background: #fafafa;
  padding-left: 15px;
}

.new-tablere table tr th:nth-last-child(2) {
  position: absolute;
  right: 85px;
  padding-left: 10px;
  width: 160px;
  background: #fafafa;
  border-right: solid 1px #e4e4e4;
  border-left: solid 1px #e4e4e4;
}

.new-tablere table tr .cell-dropdown {
  margin-top: -19px;
}

.srty a {
  margin: 0px 3px;
  display: block;
  width: 28px;
  float: left;
}

// =====================all page height issue 11/07/22 ===================
.invoice-details-container:last-child {
  margin-bottom: 0px !important;
}

.nmelt .ant-select-selector {
  padding-left: 100px !important;
}

.righttabh {
  width: 100%;
  // max-height: 500px;
  // overflow: auto;
  padding-bottom: 50px;
  margin-bottom: 90px;
  // background: #eee;
}

.asstmarl {
  margin: 10px;
  border: solid 1px #e4e4e4;
  font-size: 14px;
  padding: 15px 15px;
  height: 420px;
  border-radius: 3px;
  background: #f8f8f8;
}

.asstmarl h3 {
  font-size: 22px;
  font-weight: 600;
  padding: 5px 0px;
  margin: 0px;
}

.asstmarl h4 {
  font-size: 16px;
  padding: 5px 0px;
  margin: 0px;
}

.asstmarl .bor {
  height: 1px;
  width: 100%;
  background: #d9d9d9;
  margin: 10px 0px;
}

.asstmarl .leftsl {
  float: left;
  width: 50%;
  padding: 3px 0px;
}

.ltuiy .primary-tab-container .ant-tabs-nav {
  position: fixed;
  z-index: 9999;
  width: 35%;
  background: #fff;
}

.ltuiy .ant-tabs .ant-tabs-top-content,
.ant-tabs .ant-tabs-bottom-content {
  padding-top: 30px;
}

.curs {
  cursor: pointer;
  display: block;
}

.ntybui {
  height: 70vh;
  font-size: 16px;
  overflow: scroll;
  display: block;
  position: relative;
}

.thert {
  position: sticky;
  display: inline-table;
  z-index: 9;
  left: 0px;
  top: 0px;
}

.ntybui thead tr th:nth-child(1) {
  width: 1%;
}

.ntybui thead tr th:nth-child(2) {
  width: 3.5%;
  padding: 10px 15px 10px 15px;
}

.ntybui thead tr th:nth-child(3) {
  width: 10.5%;
}

.ntybui thead tr th:nth-child(4) {
  width: 11.2%;
}

.ntybui thead tr th:nth-child(5) {
  width: 9.8%;
}

.ntybui thead tr th:nth-child(6) {
  width: 12.3%;
}

.ntybui thead tr th:nth-child(7) {
  width: 9.1%;
}

.ntybui thead tr th:nth-child(8) {
  width: 13%;
}

.ntybui thead tr th:nth-child(9) {
  width: 11.4%;
}

.ntybui tbody {
  // padding-top: 46px;
  display: block;
}

.ntybui tbody tr td:nth-child(1) {
  width: 1%;
}

.ntybui tbody tr td:nth-child(2) {
  width: 3.5%;
  padding: 10px 15px 10px 15px;
  display: revert;
}

.ntybui tbody tr td:nth-child(3) {
  width: 10.5%;
}

.ntybui tbody tr td:nth-child(4) {
  width: 11.2%;
}

.ntybui tbody tr td:nth-child(5) {
  width: 9.8%;
}

.ntybui tbody tr td:nth-child(6) {
  width: 12.3%;
}

.ntybui tbody tr td:nth-child(7) {
  width: 9.1%;
}

.ntybui tbody tr td:nth-child(8) {
  width: 13%;
}

.ntybui tbody tr td:nth-child(9) {
  width: 11.4%;
}

.ntybuis {
  height: 460px;
  font-size: 16px;
  overflow: scroll;
  display: block;
  position: relative;
}

.therts {
  position: sticky;
  display: inline-table;
  z-index: 9;
  left: 0px;
  top: 0px;
  width: 100%;
}

.ntybuis thead tr th:nth-child(1) {
  width: 1%;
}

.ntybuis thead tr th:nth-child(2) {
  width: 3.5%;
  padding: 10px 15px 10px 15px;
}

.ntybuis thead tr th:nth-child(3) {
  width: 10.5%;
}

.ntybuis thead tr th:nth-child(4) {
  width: 11.2%;
}

.ntybuis thead tr th:nth-child(5) {
  width: 9.8%;
}

.ntybuis thead tr th:nth-child(6) {
  width: 12.3%;
}

.ntybuis thead tr th:nth-child(7) {
  width: 9.1%;
}

.ntybuis thead tr th:nth-child(8) {
  width: 13%;
}

.ntybuis thead tr th:nth-child(9) {
  width: 11.4%;
}

.ntybuis tbody {
}

.ntybuis tbody tr td:nth-child(1) {
  width: 1%;
}

.ntybuis tbody tr td:nth-child(2) {
  width: 3.5%;
  padding: 10px 15px 10px 15px;
  display: revert;
}

.ntybuis tbody tr td:nth-child(3) {
  width: 10.5%;
}

.ntybuis tbody tr td:nth-child(4) {
  width: 11.2%;
}

.ntybuis tbody tr td:nth-child(5) {
  width: 9.8%;
}

.ntybuis tbody tr td:nth-child(6) {
  width: 12.3%;
}

.ntybuis tbody tr td:nth-child(7) {
  width: 9.1%;
}

.ntybuis tbody tr td:nth-child(8) {
  width: 13%;
}

.ntybuis tbody tr td:nth-child(9) {
  width: 11.4%;
}

.liyutnty {
  height: initial !important;
  overflow: initial !important;
}

.ckrt-cont h1,
h2,
h3,
h4,
h5 {
  font-weight: 700;
}

.ckrt-cont ul {
  list-style: none;
  margin: 0px;
  padding: 0px;
}

.ckrt-cont ul li {
  list-style: disc;
  margin: 5px 5px 5px 20px;
  padding: 5px;
}

.ckrt-cont p {
  padding: 10px 0px;
  margin: 0px;
}

.asstmarl .ant-menu-root {
  height: initial !important;
}

.asstmarl .ant-dropdown-menu-item {
  padding: 5px 0px !important;
}

.ntybuis1 {
  height: 460px;
  font-size: 16px;
  overflow: scroll;
  display: block;
  position: relative;
}

.thertsn {
  position: sticky;
  z-index: 9;
  left: 0px;
  top: 0px;
  width: 100%;
}

.ntybuis1 thead tr th:nth-child(1) {
  width: 3%;
}

.ntybuis1 thead tr th:nth-child(2) {
  width: 12%;
  padding: 10px 5px 10px 5px;
}

.ntybuis1 thead tr th:nth-child(3) {
  width: 21%;
}

.ntybuis1 thead tr th:nth-child(4) {
  width: 7%;
}

.ntybuis1 thead tr th:nth-child(5) {
  width: 11%;
}

.ntybuis1 thead tr th:nth-child(6) {
  width: 8%;
}

.ntybuis1 thead tr th:nth-child(7) {
  width: 7%;
}

.ntybuis1 thead tr th:nth-child(8) {
  width: 7%;
}

.ntybuis1 thead tr th:nth-child(9) {
  width: 8%;
}

.ntybuis1 tbody {
  // padding-top: 46px;
  // display: block;
}

.ntybuis1 tbody tr td:nth-child(1) {
  width: 3%;
}

.ntybuis1 tbody tr td:nth-child(2) {
  width: 12%;
  padding: 10px 5px 10px 5px;
  display: revert;
}

.ntybuis1 tbody tr td:nth-child(3) {
  width: 21%;
}

.ntybuis1 tbody tr td:nth-child(4) {
  width: 7%;
}

.ntybuis1 tbody tr td:nth-child(5) {
  width: 11%;
}

.ntybuis1 tbody tr td:nth-child(6) {
  width: 8%;
}

.ntybuis1 tbody tr td:nth-child(7) {
  width: 7%;
}

.ntybuis1 tbody tr td:nth-child(8) {
  width: 7%;
}

.ntybuis1 tbody tr td:nth-child(9) {
  width: 8%;
}

.texthert {
  height: 40px;
  position: relative;
  font-size: 18px;
}

.texthert p {
  margin: 0;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 0%;
  transform: translate(-0%, -50%);
  width: 100%;
}

.texthert1 {
  height: 40px;
  position: relative;
}

.texthert1 p {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 0%;
  transform: translate(-0%, -50%);
}

.liguy-max {
  height: 65px;
  overflow: hidden;
}

.short-text {
  height: 20px;
  overflow: hidden;
}

.asstmarl .ant-progress .ant-progress-outer .ant-progress-inner .ant-progress-bg {
  height: 22px !important;
}

.asstmarl .cirtb {
  background: #f8f8f8;
  height: 35px;
  width: 35px;
  border-radius: 50px;
  text-align: center;
  cursor: pointer;
  padding-top: 8px;
}

.asstmarl:hover .cirtb {
  background: #e4e4e4;
  // height:35px; width:35px;
  // border-radius:50px; text-align:center; cursor: pointer; padding-top:8px;
}

.rightt {
  padding: 3px 8px 3px 0px;
  text-align: right;
}

.leftt {
  padding: 3px 0px 3px 8px;
  text-align: left;
}

.leftts {
  padding: 3px 0px 3px 0px;
  text-align: left;
}

.submitt-but {
  background: #2505a3;
  color: #fff;
  padding: 0px 5px;
  margin: 2px 0px 0px 0px;
  border: 0px;
}

.assig-cur {
  input {
    cursor: pointer !important;
  }
}

.std-status-active {
  background: #0ba685;
  border-radius: 5px;
  color: #fff;
  padding-bottom: 3px;
}

.std-status-inactive {
  background: #d0281c;
  border-radius: 5px;
  color: #fff;
  padding-bottom: 3px;
}

.filed-jamp {
  margin-top: 0px;
  padding-top: 0px;

  .cell-dropdown {
    margin-top: 0px;
    padding-top: 0px;
    position: relative;
    top: 0px;

    .ant-select-arrow {
      margin-top: 0px;
      top: 33%;
    }

    .custom-dropdown-width {
      max-width: 100%;
    }

    .ant-select-clear {
      top: 7px;
    }
  }
}

.filed-jamps {
  .cell-dropdown {
    margin-top: 0px;
    padding-top: 0px;
    position: relative;
    top: 0px;

    .ant-select-arrow {
      margin-top: 0px;
      top: 33%;
    }

    .custom-dropdown-width {
      max-width: 100%;
    }

    .ant-select-clear {
      top: 7px;
    }

    // .ant-select-selector{ width: 140px;}
  }
}

.more-details-btn {
  font-size: 12px;
  color: $secondaryColor;
  display: inline-flex;
  align-items: center;
  box-shadow: none;
  cursor: pointer;

  &::before {
    content: "...";
  }
}

.asstmarl .actopn-circle {
  position: absolute;
  right: 10px;
  top: 15px;
  z-index: 999;
  display: none;
}

.asstmarl:hover .actopn-circle {
  display: block;
}

.assignment-details {
  // height: 170px;
  padding: 16px 0px;
}

.certso {
  width: 160px;
  margin-right: 0px;
  padding-left: 45px;
  padding-right: 10px;
  height: 33px;
}

.solpty {
  position: relative;
  margin-top: -6px;

  i {
    background: #e7e7e7;
    height: 32px;
    line-height: 30px;
    position: absolute;
    top: 0;
    left: 0;
    text-align: center;
    width: 40px;
    z-index: 9;
  }
}

.qute-sect {
  width: 100%;
  padding-top: 10px;
  padding-bottom: 0px;
}

.qute-sect2 {
  width: 100%;
  padding-top: 0px;
  padding-bottom: 20px;
}

.qute-sect1 {
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
}

.clerb {
  clear: both;
}

.drop-fileds {
  width: 140px;
  float: left;
  margin-right: 5px;
}

.drop-filedsd {
  width: 140px;
  float: left;
  margin-right: 5px;
}

.mar-top {
  margin-top: -20px;
}

.drop-fileds1 {
  width: 160px;
  float: left;
  margin-right: 5px;
}

.drop-fileds2 {
  width: 300px;
  float: left;
  margin-right: 5px;
}

.drop-filed3 {
  width: 60px;
  float: left;
  margin-right: 5px;
  margin-left: -5px;
}

.fliter-part {
  .ant-collapse-content {
    overflow: initial;
  }

  .ant-select-clear {
    top: 7px;
  }
}

.line-height20 {
  line-height: 20px;
  display: inline-block;
}

// .ant-select-clear{}

.shipt-text1 {
  width: 100%;
  position: relative;
  margin-bottom: 8px;

  input {
    width: 100%;
    padding-right: 42px;
  }

  span {
    position: absolute;
    right: 0px;
    top: 0px;
    width: 80px;
    background: #e9ecf0;
    text-align: center;
    height: 31px;
    line-height: 30px;
    z-index: 9;
  }
}

.shipment_dimension_unit{
  display: flex;
  align-items: center;
  gap: 2px;
}

.package_dimension_unit{
  display: flex;
  align-items: center;
  gap: 2px;
}

.shipt-texts {
  margin-bottom: 8px;
  width: 70px;
  padding-left: 1px;
  position: relative;

  input {
    // width: 100%;
    // padding-right: 80px;
  }

  span {
    position: absolute;
    right: 0px;
    top: 0px;
    left: 81px;
    width: 42px;
    background: #e9ecf0;
    text-align: center;
    height: 32px;
    line-height: 30px;
    z-index: 9;
  }
}

.shipt-texts-weight {
  margin-bottom: 8px;
  width: 100%;
  position: relative;

  input {
    width: 100%;
    padding-right: 42px;
  }

  span {
    position: absolute;
    right: 0px;
    top: 0px;

    width: 42px;
    background: #e9ecf0;
    text-align: center;
    height: 31px;
    line-height: 30px;
    z-index: 9;
  }
}

.txn-listing-table {
  border: 1px solid #e4e4e4;
  width: 100%;
  overflow-x: auto;

  th {
    background-color: #fafafa;
    font-weight: bold;
    white-space: nowrap;
  }

  th,
  td {
    border: 1px solid #e4e4e4;
    padding: 8px;
  }

  td {
    .action-data {
      margin-left: 10px;
      font-size: 16px;
      cursor: pointer;
    }
  }
}

.listing-table-container {
  overflow-x: auto;
  overflow-y: auto;
  height: calc(100vh - 200px);

  table {
    width: 100%;
    height: 300px;
    border: 1px solid #e4e4e4;

    thead th {
      background-color: #fafafa;
      font-weight: bold;
      white-space: nowrap;
      border: 1px solid #e4e4e4;
      padding: 8px;

      position: sticky !important;
      top: 0;
      z-index: 1;
    }

    tbody td {
      border: 1px solid #e4e4e4;
      padding: 8px;

      .action-data {
        margin-left: 10px;
        font-size: 16px;
        cursor: pointer;
      }
    }
  }

  /* Customize scrollbar */
  // &::-webkit-scrollbar {
  //   width: 4px ;
  //   height: 8px;
  // }

  // &::-webkit-scrollbar-thumb {
  //   background-color: #888;
  //   border-radius: 2px;
  // }

  // &::-webkit-scrollbar-track {
  //   background-color: #f5f5f5;
  //   border-radius: 2px;
  // }
}

.drawer-head-txt {
  text-align: left;
  font-size: 16px;
  color: #08173d;
  font-weight: bold;
}

.page-header-txt {
  font-size: 18px;
  color: #08173d;
  font-weight: 600;
}

.table-link-text {
  color: #0075ff;
  cursor: pointer;
  font-weight: 400;
  text-decoration: underline;

  .a {
    color: #0075ff !important;
  }
}

.school-listing-table {
  border: 1px solid #e4e4e4;
  width: 100%;
  border-collapse: collapse;

  thead {
    tr {
      th {
        background-color: #fafafa;
        border: 1px solid #e4e4e4;
        font-weight: bold;
        padding: 5px 8px;
      }
    }
  }

  tbody {
    tr:nth-child(even) {
      background-color: #f5f5f5;
    }

    td {
      border: 1px solid #e4e4e4;
      padding: 5px 8px;

      .action-data {
        margin-left: 10px;
        font-size: 16px;
        cursor: pointer;
      }
    }
  }
}

.attendance-listing-table {
  .user-icon {
    width: 36px;
    height: 36px;
    background-color: #e7e7e7;
    color: #000000;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    letter-spacing: 1px;
    cursor: pointer;
    padding: 3px 0 0 3px;
    margin-right: 10px;
    align-self: center;
  }

  overflow: auto;
  height: calc(100% - 108px);

  table {
    border: 1px solid #e4e4e4;
    width: 100%;
    border-collapse: collapse;
    overflow: auto;
    // max-height: calc(100vh - 220px);
    // height: calc(100vh - 220px);

    thead {
      tr {
        th {
          background-color: #ffffff;
          font-size: 12px;
          font-weight: 700;
          color: #283c50;
          border-bottom: 1px solid #e8e8ff;
          padding: 10px 15px;
          cursor: pointer;

          position: sticky !important;
          top: -1px;
          z-index: 1;

          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          &:first-child {
            width: 50px;
          }

          &:nth-child(2) {
            min-width: 200px;
          }
        }
      }
    }

    tbody {
      // tr:nth-child(even) {
      //   background-color: #f5f5f5;
      // }

      td {
        border-bottom: 1px solid #e4e4e4;
        padding: 10px 15px;
        // text-align: center;

        .action-data {
          margin-left: 10px;
          font-size: 16px;
          cursor: pointer;
        }

        &:first-child {
          width: 50px;
        }

        &:nth-child(2) {
          min-width: 200px;
        }
      }
    }
  }
}

.view-container {
  border-radius: 2px;
  background: #ffffff;
  // margin: 20px;
  // height: calc(100vh - 162px);
  height: calc(100vh - 130px);

  .ant-tabs {
    margin: 0px;
    height: calc(100% - 21px);

    .ant-tabs-bar {
      margin: 0px;
      padding: 0px 20px;

      .ant-tabs-nav-container {
        .ant-tabs-nav-wrap {
          .ant-tabs-nav-scroll {
            .ant-tabs-nav {
              .ant-tabs-tab {
                margin: 0 20px 0 0;
                padding: 8px 0;
              }
            }
          }
        }
      }
    }

    .ant-tabs-content {
      height: inherit;
    }
  }

  .footer {
    background-color: #fafbff;
    bottom: 0;
    // margin-bottom: 20px;
    height: 58px;
    display: flex;
    flex-direction: row;
    // width: calc(100% - 108px);
    width: 100%;
    overflow: visible;
    padding: 0px 20px;
    position: relative;
    margin-top: 0px;

    .f-left {
      width: 50%;
    }

    .f-right {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
}

.view-top-bar {
  border-bottom: 1px solid #e8e8ff;
  height: 54px;
  padding: 0px 20px;
  z-index: 99;
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .top-view-left {
    display: flex;
    flex-direction: row;
    gap: 16px;

    .page-heading {
      font-size: 16px;
      color: $primaryColor;
      font-weight: 700;
    }

    .vertical-sep {
      width: 1px;
      background-color: #e8e8ff;
      height: 24px;
    }
   
  }

  .top-view-right {
    display: flex;
    flex-direction: row;
    gap: 8px;
  }
}

.view-container-actions {
  display: flex;
  justify-content: space-between;
  height: 58px;
  background-color: #fafbff;
  align-items: center;
  border-radius: 2px 2px 0 0;
  padding: 0 20px;
  border: 1px solid #e8e8ff;
  margin: 0 -1px;
  width: 100%;

  .left-actions {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 16px;
    width:50%;

    .table-heading {
      font-size: 14px;
      color: $primaryColor;
      font-weight: 700;
    }

    .table-sub-heading {
      font-size: 12px;
      font-weight: 400;
      line-height: 1;
    }
  }

  .right-actions {
    display: flex;
    gap: 0px 8px;
    width: 50%;
    align-items: center;
    justify-content: flex-end;

    .popoverStyle {
      .ant-popover-content {
        overflow: auto;
        height: 10px !important;
        background-color: red !important;
      }
    }

    .ant-input-search {
      height: 38px !important;
      width: 250px !important;
    }

    button {
      height: 38px;
      border: 1px solid #e7e7e7;
      padding: 8px;
      background-color: #ffffff;
      justify-content: center;
      display: flex;
      align-items: center;
      cursor: pointer;
      // width: 120px;
      width: auto;
      min-width: fit-content;
    }

    .ant-dropdown-trigger {
      height: 38px;
      border: 1px solid #e7e7e7;
      padding: 8px;
      background-color: #ffffff;
      justify-content: center;
      display: flex;
      align-items: center;
      cursor: pointer;
    }

    .ant-select {
      width: 200px !important;
    }

    .ant-picker{
      width: auto;
    }
  }

  .vertical-sep {
    width: 1px;
    background-color: #e8e8ff;
    height: 20px;
  }

  .action-btn {
    align-items: center;
    font-size: 14px;
    background-color: #efefef;
  }

  .action-icon-btn {
    align-items: center;
    font-size: 18px;
    width: 50px;
    margin-right: 10px;
    border: 0px;
    cursor: pointer;

    &:hover {
      opacity: 0.9;
      background-color: #cfcfcf;
    }
  }

  .normal-btn {
    background-color: #16a085;
    border: 1px solid #16a085;
    padding: 5px 10px;
    border-radius: 2px;
    color: #ffffff;
    height: 38px;
    font-size: 14px;

    &:hover {
      background-color: #107360;
    }
  }
}

//normal btn for usage in top-view container
.create-action-btn {
  background-color: #1cb961;
  border: 1px solid #1cb961;
  border-radius: 5px;
  padding: 0 10px;
  color: #fff;
  height: 38px;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  cursor: pointer;

  i {
    font-size: 11px;
    // margin: 0 5px 0 0;
  }

  &:hover {
    background-color: #1cb961;
    border: 1px solid #1cb961;
    color: #fff;
  }

  .anticon {
    font-size: 12px;
  }

  &.icon-only {
    padding: 10px;
    background-color: #ffffff;
    color: #58596c;
    border: none;
    font-size: 16px;
  }
}

.actionBtn2 {
  height: 38px;
  border: 1px solid #e7e7e7;
  border-radius: 5px;
  padding: 0 10px;
  font-size: 12px;
  background-color: #fff;
  color: #000;
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;

  i {
    font-size: 11px;
    margin: 0 5px 0 0;
  }

  &:hover {
    background-color: #fff;
    border: 1px solid #e7e7e7;
    color: #000;
  }
}

.table-container {
  overflow-x: auto;
  overflow-y: auto;
  // height: calc(100% - 120px);
  height: calc(100% - 108px);

  &.no-top-bar {
    height: calc(100% - 60px);
  }

  &.table-h-profile {
    height: calc(100% - 85px);
  }

  &.no-pagination {
    height: calc(100% - 50px);
  }

  &.with-tabs {
    height: calc(100% - 145px);
  }

  table {
    width: 100%;

    thead {
      tr {
        th {
          background-color: #ffffff;
          font-size: 12px;
          font-weight: 700;
          color: #283c50;
          border-bottom: 1px solid #e8e8ff;
          padding: 8px 10px;
          cursor: pointer;

          position: sticky !important;
          top: 0;
          z-index: 1;

          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          .check-cell {
            display: flex;
            align-items: center;
            gap: 4px;
          }

          i {
            font-size: 10px;
            color: $primaryColor;
          }
        }
      }
    }

    tbody {
      tr {
        &:nth-child(even) {
          background-color: #fdfdff;
        }

        td {
          font-size: 12px;
          color: #283c50;
          font-weight: 400;
          border-bottom: 1px solid #e8e8ff;
          padding: 8px 10px;
          color: #283c50;
          // vertical-align: top;

          .action-icon {
            :hover:active:focus {
              cursor: pointer;
            }
          }

          // if row has child to show
          .child-sr {
            padding-left: 15px;
            display: flex;
            justify-content: center;
            padding-top: 10px;

            &::before {
              content: "~";
            }
          }

          &:first-child {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          &.one-line-view,
          .line-view {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .link {
            cursor: pointer;
            color: $secondaryColor;
          }

          .ant-select-selector {
            height: 38px;
            flex-wrap: nowrap;
            overflow: hidden;
            // width: px;
          }
        }

        &:hover {
          background-color: #f0f1f5;
        }
      }
    }
  }

  /* Customize scrollbar */
  // &::-webkit-scrollbar {
  //   width: 4px;
  //   height: 4px;
  // }

  // &::-webkit-scrollbar-thumb {
  //   background-color: #888;
  //   border-radius: 2px;
  // }

  // &::-webkit-scrollbar-track {
  //   background-color: #ffffff;
  //   border-radius: 4px;
  // }
}
.table-container-tile {
  max-height: 480px;

  &.no-top-bar {
    height: calc(100% - 60px);
  }

  &.table-h-profile {
    height: calc(100% - 85px);
  }

  table {
    width: 100%;

    thead {
      tr {
        th {
          background-color: #ffffff;
          font-size: 12px;
          font-weight: 700;
          color: #283c50;
          border-bottom: 1px solid #e8e8ff;
          padding: 8px 10px;
          cursor: pointer;

          position: sticky !important;
          top: 0;
          z-index: 1;

          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          .check-cell {
            display: flex;
            align-items: center;
            gap: 4px;
          }

          i {
            font-size: 10px;
            color: $primaryColor;
          }
        }
      }
    }

    tbody {
      overflow: auto;
      tr {
        &:nth-child(even) {
          background-color: #fdfdff;
        }

        td {
          font-size: 12px;
          color: #283c50;
          font-weight: 400;
          border-bottom: 1px solid #e8e8ff;
          padding: 8px 10px;
          color: #283c50;
          // vertical-align: top;

          .action-icon {
            :hover:active:focus {
              cursor: pointer;
            }
          }

          // if row has child to show
          .child-sr {
            padding-left: 15px;
            display: flex;
            justify-content: center;
            padding-top: 10px;

            &::before {
              content: "~";
            }
          }

          &:first-child {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          &.one-line-view,
          .line-view {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .link {
            cursor: pointer;
            color: $secondaryColor;
          }

          .ant-select-selector {
            height: 38px;
            flex-wrap: nowrap;
            overflow: hidden;
            // width: px;
          }
        }

        &:hover {
          background-color: #f0f1f5;
        }
      }
    }
  }

  /* Customize scrollbar */
  // &::-webkit-scrollbar {
  //   width: 4px;
  //   height: 4px;
  // }

  // &::-webkit-scrollbar-thumb {
  //   background-color: #888;
  //   border-radius: 2px;
  // }

  // &::-webkit-scrollbar-track {
  //   background-color: #ffffff;
  //   border-radius: 4px;
  // }
}

.row-adder {
  table {
    thead {
      tr {
        th {
          padding: 10px 10px;
        }
      }
    }
  }

  table {
    tbody {
      tr {
        td {
          padding: 10px 5px;
        }
      }
    }
  }
}

.time-table {
  thead {
    tr {
      th {
        border: 1px solid #e8e8ff;
      }
    }
  }

  tbody {
    tr {
      &:hover {
        background-color: unset !important;
      }

      td {
        border: 1px solid #e8e8ff;
        padding: 10px;

        .schedule-detail {
          // padding: 10px;
          // border-radius: 2px;
          // border: 1px solid #e7e7e7;
          // height: 94px;
          // margin: 0px -10px;
          // width: 200px;

          .line-view {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        &:hover {
          border: 2px solid $secondaryColor;
        }

        .no-schedule {
          height: 94px;
          margin: 0px -10px;
          width: 200px;
          // text-align: center;
          padding-top: 47px;
        }
      }
    }
  }
}

.product-table {
  overflow-x: auto;
  overflow-y: auto;
  // height: calc(100% - 120px);
  overflow: hidden;
  height: calc(100% - 108px);

  &.no-top-bar {
    height: calc(100% - 60px);
  }

  table {
    width: 100%;

    thead {
      tr {
        th {
          background-color: #ffffff;
          font-size: 12px;
          font-weight: 700;
          color: #283c50;
          border-bottom: 1px solid #e8e8ff;
          padding: 10px 10px;
          cursor: pointer;

          position: sticky !important;
          top: 0;
          z-index: 1;

          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          .check-cell {
            display: flex;
            align-items: center;
            gap: 4px;
          }

          i {
            font-size: 10px;
            color: $primaryColor;
          }
        }
      }
    }

    tbody {
      // overflow: auto;
      tr {
        &:nth-child(even) {
          background-color: #fdfdff;
        }

        td {
          font-size: 12px;
          color: #283c50;
          font-weight: 400;
          border-bottom: 1px solid #e8e8ff;
          padding: 5px 10px;
          color: #283c50;
          // vertical-align: top;

          .action-icon {
            :hover:active:focus {
              cursor: pointer;
            }
          }

          // if row has child to show
          .child-sr {
            padding-left: 15px;
            display: flex;
            justify-content: center;
            padding-top: 10px;

            &::before {
              content: "~";
            }
          }

          &:first-child {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          &.one-line-view,
          .line-view {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .link {
            cursor: pointer;
            color: $secondaryColor;
          }

          .ant-select {
            height: 32px;

            .ant-select-selector {
              height: 32px !important;

              .ant-select-selection-search {
                height: 32px !important;
              }
            }
          }

          .ant-input {
            height: 32px;
          }
        }

        &:hover {
          background-color: #f0f1f5;
        }
      }
    }
  }
 

  /* Customize scrollbar */
  // &::-webkit-scrollbar {
  //   width: 4px;
  //   height: 4px;
  // }

  // &::-webkit-scrollbar-thumb {
  //   background-color: #888;
  //   border-radius: 2px;
  // }

  // &::-webkit-scrollbar-track {
  //   background-color: #ffffff;
  //   border-radius: 4px;
  // }
}

.product-tableV2 {
  overflow-x: auto;
  overflow-y: auto;
  // height: calc(100% - 120px);
  height: calc(100% - 108px);

  &.no-top-bar {
    height: calc(100% - 60px);
  }

  table {
    width: 100%;

    thead {
      tr {
        th {
          background-color: #ffffff;
          font-size: 12px;
          font-weight: 700;
          color: #283c50;
          border-bottom: 1px solid #e8e8ff;
          padding: 10px 5px;
          cursor: pointer;

          position: sticky !important;
          top: 0;
          z-index: 1;

          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          .check-cell {
            display: flex;
            align-items: center;
            gap: 4px;
          }

          i {
            font-size: 10px;
            color: $primaryColor;
          }
        }
      }
    }

    tbody {
      tr {
        &:nth-child(even) {
          background-color: #fdfdff;
        }

        td {
          font-size: 12px;
          color: #283c50;
          font-weight: 400;
          border-bottom: 1px solid #e8e8ff;
          padding: 5px 2px;
          color: #283c50;
          // vertical-align: top;

          .action-icon {
            :hover:active:focus {
              cursor: pointer;
            }
          }

          // if row has child to show
          .child-sr {
            padding-left: 15px;
            display: flex;
            justify-content: center;
            padding-top: 10px;

            &::before {
              content: "~";
            }
          }

          &:first-child {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          &.one-line-view,
          .line-view {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .link {
            cursor: pointer;
            color: $secondaryColor;
          }
        }

        &:hover {
          background-color: #f0f1f5;
        }
      }
    }
  }

  /* Customize scrollbar */
  // &::-webkit-scrollbar {
  //   width: 4px;
  //   height: 4px;
  // }

  // &::-webkit-scrollbar-thumb {
  //   background-color: #888;
  //   border-radius: 2px;
  // }

  // &::-webkit-scrollbar-track {
  //   background-color: #ffffff;
  //   border-radius: 4px;
  // }
}

// threee dot action button in table which open overlay popup
.row-action-dropdown {
  background-color: #ffffff;
  display: inline-grid;
  max-height: 300px;
  min-width: 200px;
  overflow-y: scroll;
  padding: 5px 6px 5px 13px;

  .ant-dropdown-menu-item {
    display: inline-flex;
    align-items: center;
    border-radius: 4px;

    &:first-child {
      background-color: #ffffff;
      cursor: not-allowed;

      &:hover {
        background-color: #ffffff;
      }
    }

    &:last-child {
      margin-bottom: 5px;
    }

    &:hover {
      background-color: #f2f2f2;
    }

    i {
      margin-right: 7px;
    }
    div{
      font-size: inherit;
      width: 100%;
    }
  }
}

.create-action-dropdown {
  background-color: #ffffff;
  display: inline-grid;
  max-height: 300px;
  min-width: 200px;
  overflow-y: scroll;
  padding: 5px 6px 5px 13px;

  .ant-dropdown-menu-item {
    display: inline-flex;
    align-items: center;
    border-radius: 4px;

    &:last-child {
      margin-bottom: 5px;
    }

    &:hover {
      background-color: #f2f2f2;
    }
  }
}

// new transaction styles

.new-transaction-wrapper {
  margin-top: -20px;
  overflow: hidden;

  .ant-modal-content {
    height: 100vh;
    overflow: hidden;

    .ant-modal-close {
      .ant-modal-close-x {
        display: block;
        width: 40px;
        height: 40px;
        font-size: 16px;
        font-style: normal;
        line-height: 42px;
      }
    }

    .ant-modal-body {
      min-height: 100vh;
      overflow: hidden;
      padding: 0px !important;

      .txn-header {
        height: 48px;
        display: flex;
        align-items: center;
        padding: 0 40px 0 10px;
        border-bottom: 1px solid #e7e7e7;
        box-shadow: rgba(0, 0, 0, 0.45) 0px 1px 10px -8px;

        .timer-lRight{
          height: 32px;
          width: 100px;
          border: 1px solid #1CB980;
          background-color: #1CB980;
          .align-div{
            display: flex;
          background-color: #1CB980;
            height: 32px;
            font-size: 14px;
            color: white;
            font-weight: 700;
            align-items: center;
            justify-content: center;
          }
        }
        .detailDropdown{
          .ant-select-dropdown{
            min-width: 300px !important;
          }
        }
        .sep {
          width: 1px;
          background-color: #e7e7e7;
          margin: 0 5px;
        }

        .left-itms {
          width: 50%;
          display: flex;

          ul {
            display: flex;
            list-style-type: none;
            margin-bottom: 0px;
            gap: 10px;

            li {
              display: flex;
              align-items: center;
            }
          }

          .title {
            font-size: 16px;
            font-weight: 700;
            color: $primaryColor;
          }
        }

        .right-itms {
          display: flex;
          justify-content: flex-end;
          width: 50%;
          gap: 5px;
          align-items: center;

          .ant-btn {
            height: 38px;
          }
        }
      }

      .txn-body {
        height: calc(100vh - 50px);
        overflow: auto;

        .upoad-file-table table {
          width: 24% !important;
          min-width: 24% !important;
          max-width: 24% !important;
        }
      }

      .txn-details-container {
        padding: 10px;

        .deatils-field-value {
          height: 32px;
          padding-top: 5px;
        }

        .detail-address-value {
          display: flex;
          justify-content: space-between;
        }

        .order-value-info {
          background-color: #f3f3f3;
          padding: 2px 14px 4px 14px;
          // border-radius: 6px;
        }

        .anticon-question-circle {
          padding-left: 5px;
          cursor: pointer;
        }

        .ant-input {
          &.input-text {
            height: 32px;
          }
        }

        .ant-select-selector,
        .ant-picker {
          min-height: 32px !important;
          max-height: 32px !important;
          height: 32px !important;
        }

        .ant-picker {
          .ant-picker-input {
            input {
              font-size: 12px;
            }
          }
        }

        .details-heading {
          font-size: 14px;
          font-weight: 700;
          color: $primaryColor;
        }

        .details-heading-subheading {
          font-weight: 600;
        }

        .address-container {
          padding: 2px 5px;

          .billing-address {
            color: #656b79;
            // line-height: 1.5;
            line-height: 1.3;
          }
        }

        .ant-row {
          margin-top: 5px;
        }
      }

      .product-details-container {
        margin: 10px 0 0 0;
        padding: 0 10px 0px 10px;

        .ant-input-number-input {
          height: 26px;
          padding: 0px 5px 0px 5px !important;
        }

        .ag-cell {
          background-color: white;
        }

        .product-amount-values {
          // background-color: red;
          margin-top: 10px;
          padding-right: 2px;

          .ant-col-9 {
            text-align: right;
          }
        }

        .input-label-control {
          position: relative;
          margin-right: 10px;
          width: 100%;

          .mark {
            background-color: #e2e2e2;
            color: #000;
            height: 38px;
            top: 0;
            left: 0px;
            width: 38px;
            align-items: center;
            display: grid;
          }

          .ant-input {
            padding-left: 43px;
            height: 38px;

            &:focus {
              box-shadow: none;
            }
          }

          &:nth-last-of-type(1) {
            margin-right: 10;
          }
        }

        .upload-btn-so {
          padding: 10px 10px 10px 0px;
        }

        .footer {
          padding: 5px 10px;
          display: flex;
          background-color: #fafbff;
          position: fixed;
          bottom: 0;
          width: 100%;
          left: 0;
          bottom: 0;
          height: 50px;
          align-items: center;
          border-top: 1px solid #e7e7e7;

          &.extra-height {
            margin-top: calc(100vh - 630px);
          }

          .left-fItems {
            width: 50%;
            justify-content: flex-start;
          }

          .right-fItems {
            width: 50%;
            display: flex;
            justify-content: flex-end;
            gap: 10px;
          }
        }
      }
    }

    .grey-button {
      background-color: #ffffff;
      border-radius: 2px;

      &:hover {
        background-color: $hoverBgColor;
        color: rgba(0, 0, 0, 0.65);
        border: 1px solid #d9d9d9;
      }
    }

    .description-textarea {
      min-height: 80px;
      width: 60%;
      border: 1px solid #d7d7d7 !important;
    }

    .txn-footer-container {
      display: flex;
      align-items: center;
      justify-content: center;

      .add-footer {
        width: 50%;
        height: 38px;
        background-color: #ffffff;
        border-radius: 2px;

        &:hover {
          background-color: $hoverBgColor;
          color: rgba(0, 0, 0, 0.65);
          border: 1px solid #d9d9d9;
        }

        &:active,
        &:focus {
          color: rgba(0, 0, 0, 0.65);
          background-color: #ffffff;
          border: 1px solid #d9d9d9;
        }
      }
    }
  }
}

.txn-table {
  margin-top: 10px;

  table {
    width: 100%;

    thead {
      tr {
        th {
          background-color: #f3f3f3;
          font-size: 12px;
          font-weight: 700;
          color: #283c50;
          border: 1px solid #e8e8ff;
          padding: 5px 10px;
          width: percentage(1 / 9);
        }
      }
    }

    tbody {
      tr {
        td {
          font-size: 12px;
          color: #283c50;
          font-weight: 400;
          border: 1px solid #e8e8ff;
          padding: 5px 10px 5px 10px;
          color: #283c50;
        }
      }
    }
  }
}

.custom-drawer {
  .ant-drawer-header {
    background: #fff;
    position: sticky;
    top: 0;
    z-index: 9999;
  }

  .ant-drawer-body {
    position: relative;
    font-size: 12px;

    .tab-view {
      padding-bottom: 50px;
    }
  }

  .ant-drawer-footer {
    display: flex;
    justify-content: space-between;
    padding: 10px 24px;
  }
}

.ant-drawer-footer,
.ant-modal-footer {
  .ant-btn-primary {
    background-color: $secondaryColor;
    border-color: $secondaryColor;
  }

  .ant-btn-default {
    background-color: #ffffff;

    &:hover {
      border-color: 1px solid #d9d9d9 !important;
    }
  }

  ant-btn:hover,
  ant-btn.focus {
    border-color: #d9d9d9;
  }
}

.ant-modal-footer {
  justify-content: space-between;
  display: flex;
}

.i-label {
  margin-bottom: 2px;
  color: #58596c;

}

.social-icon {
  .anticon svg {
    font-size: 24px;
  }
}

.seo-input {
  margin: 20px 0;
}
.robots{
  margin: 20px 0px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.variable-charge{
  margin: 10px 0px;
 
    table{

      th,td {
        width: 18%;
        padding: 5px;
      }
      td input{
        width: 100%;
      }
    }
    
    button{
      border: none;
      background-color: transparent !important;
      cursor: pointer;
      color: #02a1ea;
      font-size: 12px;
    }

    .profit-toggle{
      .ant-switch {
        width: 46px;
        background-color: #4ca185 !important;
        color: #fff;
      }
    }
}

.seo-image-wrapper {
  color: #000;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  display: flex;
  padding: 20px;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;
  margin: 10px 0;
     .upload-image{
      display: none;
      cursor: pointer;
     }
     .seo-image-upload{
      height: 100px;
      border: 1px dashed #ccc;
      text-align: center;
      min-width: 150px;
      position: relative;
      padding: 0 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      background-color:  #f2f2f2;
      cursor: pointer;
         .add-image{
          color: #146eb4;
          font-size: 14px;
         }
         .picture-size{
          color: grey;
         }
     }
     .seo-info{
      color: #000;
     }

}

 .seo-preview {
  width: 90%;
  margin: 0 20px;
  padding: 20px;
  border: 1px solid #a59d9d;
  border-radius: 5px;

  .preview {
    font-size: 20px;
    font-weight: 700;
    display: flex;
    align-items: center;
    gap: 5px;

  }

  .seo-data-link a {
    color: #004d00;
    font-size: 14px;
  }
}

.employee-select {
  .ant-select-selector {
    width: 100%;
    display: flex;
  }
}

.tooltip-icon {
  color: $secondaryColor;
  font-size: 10px;
  padding-left: 5px;
  cursor: pointer;
}

.add-icon {
  i {
    font-size: 10px;
    cursor: pointer;
  }
}

.status-toggle {
  .ant-switch {
    width: 67px;

    &.ant-switch-checked {
      .ant-switch-inner {
        position: relative;

        &:before {
          content: "Active";
          color: #fff;
          font-size: 11px;
        }
      }
    }

    .ant-switch-inner {
      position: relative;

      &:before {
        content: "Inactive";
        color: rgba(#000, 0.7);
        font-size: 11px;
      }
    }
  }
}

.status-label {
  padding: 2px 10px;
  color: #ffffff;
  border-radius: 20px;
  min-width: 64px;
  max-width: fit-content;
  text-align: center;
  // display: list-item;
  // max-width: 80px;

  &.open, &.approved {
    background-color: #1cb980;
  }

  &.closed {
    background-color: #ffb020;
  }

  &.unpaid {
    background-color: #ff000d;
  }

  &.label_only {
    background-color: $secondaryColor;
  }

  &.pending{
    background-color: #FFA700;
  }
}

.pointer {
  cursor: pointer;
}

.pay-btn {
  bottom: 0px !important;
  right: 70px !important;
  top: unset !important;

  .ant-btn {
    height: 38px !important;
  }
}

.line-view {
  white-space: nowrap;
  // overflow: hidden;
  text-overflow: ellipsis;
}

.currency-label {
  height: 36px;
  margin: 1px 0 1px 3px;
  width: 40px;
  padding: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e7e7e7;
  border-radius: 2px 0px 0px 2px;
}

.back-blur {
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(3px);
}

.m_touch_top {
  .ant-modal{
    margin-top: -20px;
    padding-bottom: 0px;
  }
}

.closedDiv {
  max-height: 0;
  overflow: hidden;
  transition: max-height 1.8s ease-in-out;

  &.open {
    max-height: 100%;
    overflow: visible;
    transition: max-height 1.8s ease-in-out;
  }
}

.form-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 20px;
  width: 100%;
  bottom: 0;
  position: absolute;
}

.lead-div {
  padding: 20px 10%;
  overflow-y: auto;
  height: calc(100vh - 110px);

  .sub_head {
    font-size: 16px;
    font-weight: 500;
  }

  .ant-select-selector {
    height: 38px;
    flex-wrap: nowrap;
    overflow: hidden;
  }
}
.find-location-button {
  // position: absolute;
  top: 10px;
  left: 10px;
  width: 100px;
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
  z-index: 1000; /* Ensure the button is on top */
}
.address-area{
  margin-top: 20px;
  display: flex;
  // gap: 10px;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border: 1px solid grey;
  border-radius: 4px;
  // width: 100%;


}

.find-location-button:hover {
  background-color: #0056b3;
}
.lead-map-div{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  gap: 20px;
}

.lead_head {
  padding-left: 10%;
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: 500;
  height: 60px;
}

.preview-head {
  font-weight: 700;
}

.lead_sub_head {
  font-weight: 700;
  color: rgba(0, 0, 0, 0.65);
  line-height: 1.1em;
}

.light-txt {
  color: #939393;
  font-weight: 400;
}

.reminder-up {
  width: 86px;
  padding: 2px 5px;
  color: #adb94c;
  background-color: #edf7b4;
  border: 1px solid #bccb45;
  border-radius: 4px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.reminder-exp {
  width: 80px;
  max-width: 120px;

  padding: 2px 5px;
  color: #e13434;
  background-color: #e99393;
  border: 1px solid #e13434;
  border-radius: 4px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.ant-picker-time-panel-column {
  overflow-y: scroll;
  /* Enable vertical scrolling */
}

/* Hide scrollbars for Webkit-based browsers (Chrome, Safari) */
.ant-picker-time-panel-column::-webkit-scrollbar {
  width: 0;
  height: 0;
}

/* Hide scrollbar for Firefox */
.ant-picker-time-panel-column {
  scrollbar-width: none;
}

.lc_main {
  height: calc(600px - 128px);
  // min-height: 50vh;
  overflow-y: auto;
}

.lc_footer {
  border-top: 1px solid #d9d9d9;
  padding: 10px 0px 0px 0px;
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 100%;
  bottom: -10px;
}

.drawer-notes {
  position: absolute;
  bottom: 15px;
  display: flex;
  align-items: center;
  width: 90%;
}

.top-layer {
  height: 100%;
  min-height: 100vh;
  position: absolute;
  width: 100%;
  background-color: rgba(85, 85, 227, 0.54);
  z-index: 9999;
  backdrop-filter: blur(4px);

  .info-container {
    position: sticky;
    top: calc(50vh - 200px);
    left: calc(50% - 325px);
    width: 700px;
    height: 400px;
    background-color: #ffffff;
    opacity: 1;
    display: flex;
    flex-direction: row;

    .full_cont{
      width: 100%;
      height: 100%;
      padding: 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .head{
        font-size: 16px;
        font-weight: 700;
        text-align: center;
      }

      .alert_msg{
        font-size: 14px;
      }

      .pending_fee_details{

      }

      .info_footer{
        text-align: right;

      }
    }

    .left {
      width: 35%;
      height: 100%;
      padding: 10px;

      display: flex;
      align-items: center;
      justify-content: center;
    }

    .right {
      width: 60%;
      height: 100%;
      padding: 10px;

      .close {
        position: relative;
        right: -410px;
        top: 5px;
        cursor: pointer;
        height: 0px;
      }

      .header {
        font-size: 18px;
        font-weight: 700;
        color: rgba(0, 117, 255, 1);
        padding: 20px 10px 10px 10px;
        width: calc(100% - 16px);
        // margin-top: -10px;
        margin-top: 5px;
        // text-align: center;
      }

      .body {
        display: flex;
        flex-direction: column;
        gap: 8px;
        padding: 0px 10px;
        line-height: 1.3em;
        font-size: 12px;
        // margin-top: 10px;

        .f-label {
          font-size: 14px;
          font-weight: 700;
          margin: 20px 0 10px 0;
        }

        .otp-label {
          font-size: 14px;
          margin: 20px 0 10px 0;
          // color: #999999;
        }

        .ant-input {
          height: 46px;
          border-radius: 6px;
        }

        .form-list {
          max-height: 70px;
          overflow-y: auto;
          font-weight: 700;
          min-height: 30px;
          line-height: 1.5em;
        }

        .ant-btn-primary {
          margin: 10px 0px;
          height: 46px;
          // background-color:rgba(28, 185, 128, 1);
          // border-color: rgba(28, 185, 128, 1);
          // &:focus{
          //   background-color:rgba(28, 185, 128, 1);
          //   border-color: rgba(28, 185, 128, 1);
          // }
        }

        .w0 {
          max-width: 0;
          overflow: hidden;
          transition: all 2s;
        }

        .wf {
          max-width: 100%;
          overflow: visible;
          transition: all 2s;
        }
      }
    }
  }

  @media (max-width: 768px) {
    .info-container {
      flex-direction: column;
      // top: auto;
      left: auto;
      width: auto;
      height: auto;
    }
  
    .info-container .left{
      display: none;
    }

    .info-container .right {
      width: 100%;
      height: auto;
    }
  
    .info-container .right .close {
      position: absolute;
      right: 10px;
      top: 10px;
    }
  }
}

.otp-input {
  height: 46px;
  width: 46px !important;
  margin-right: 20px;
  border: none;
  border-bottom: 1px solid rgb(118, 118, 118);
  font-size: 18px;
  font-weight: 500;

  // &.error{
  //   color: red;
  // }

  &:focus-visible,
  &:focus,
  &:hover,
  &:active {
    outline: none;
    border-bottom: 2px solid $secondaryColor !important;
  }
}

.act-timeline {
  height: calc(100vh - 100px);
  overflow-y: auto;
  padding: 20px 30px;
}

.button-action {
  border-top: 1px solid #e5e5e5;
  display: flex;
  justify-content: center;
  margin-top: 12px;
  margin-right: -17px;
  margin-left: -17px;
  padding-top: 10px;

  .ant-btn {
    width: 100px;
  }
}

.address-submit-btn {
  // margin-top: 250px;
  // overflow: hidden;
  position: fixed;
  margin-right: 0px !important;
  // padding: 20px;
  bottom: 0;
  width: -webkit-fill-available;
  // width: 42%;
}

.ant-legacy-form-item-label {
  text-align: left;
}
.footer-tile-popup{
  background-color: #000;
}
.ant-skeleton-content{
  padding: 20px;
  .ant-skeleton-title{
    margin-top: 4px !important;
  }
}
.vertical-line {
  width: 1px;
  background-color: #e8e8ff;
  height: 34px;
}
.table-count{
  display: flex;
  align-items: center;
}
.link-text{
  color: #0075ff;
  cursor: pointer;
  font-weight: 400;
}
.txn-modal-dialog{
  .text-right {
    padding-right: 0px;
    text-align: right !important;

  }
}
.popup-footer{
  height: 48px;
  background-color: #0075FF;
}

  .ant-input-number-input {
    padding: 0px 11px 0px 11px !important;
}
.custom-count{
  position: absolute;
  z-index: auto;
  margin-top: -29px;
  margin-left: 40%;
}
.extra-pd{
  padding: 0 10px 40px 10px !important;
}
.cus-pd{
  padding: 20px 0px 10px 30px !important;
}
.cus-footer{
  display: flex;
  margin-top: 10px;
  justify-content: space-between;
  bottom: 0;
  position: fixed;
  width: 100%;
  border-top: 1px solid #e7e7e7;
  align-items: center;
  height: 50px;
  background-color: #fafbff;
  left:0;

  .left-btn{
    width: 50%;
    padding: 10px;
  }
  .right-btn{
    width: 50%;
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }
}

.create-coupon-modal {
  .ant-modal-content {
    min-height: 600px;
    position: relative;
  }

  .coupon-align-center {
    align-items: center;
  }

  .ant-modal-body {
    max-height: 560px;
    padding: 0;

    form.ant-legacy-form {
      max-height: 550px;
      overflow-y: auto;
      padding: 20px 40px 60px 20px;
    }
  }

  .form-footer {
    background: #fff;
    border-top: 1px solid #d8dcde;
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 15px 20px;
  }

  .create-coupon {
    .ant-row {
      margin-bottom: 15px;

      .ant-col-8 {
        text-align: right;
        padding-right: 20px;
      }
    }
  }
}

.custom-row-coupon {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.create-coupon-row {
  justify-content: center;
  margin-bottom: 15px;
}
.first-caps{
text-transform: capitalize;
}
.font-14{
  font-size: 14px;
}

.image-preview{
  margin-top: -20px;
  overflow: hidden;
  .ant-modal-content {
    height: 100vh;
  }
  .ant-modal-body {
    min-height: 100vh;
    overflow: auto;
    padding: 0px !important;
  }
}

.email-tag{
  background-color: #bad7fb;
  padding: 3px 8px;
  border: 1px solid #63a2f1;
  border-radius: 6px;
  margin-right: 8px;
  line-height: 30px;
  text-wrap: nowrap;
}

.userStatus {
  .ant-select-dropdown{
    top:40px !important
  }
}
.complaint-toggle{

    .ant-switch {
      width: 70px;
      background-color: #4ca185;
      &.ant-switch-checked {
        .ant-switch-inner {
  
  
          &:before {
            content:"Order";
            color: #fff;
            font-size: 11px;
          }
        }
      }
  
      .ant-switch-inner {
        &:before {
          content: "Invoice";
          color: #fff;
          font-size: 11px;
        }
      }
    }
  
}

.create-class-section{
  display: flex;
  justify-content: center;
  align-items: center;
}


// payment loader
.open_loader{
  width: 100%;
  height: 100vh;
  position: absolute;
  z-index: 999;
  background-color: rgba(240, 240, 240, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;

  .payment-loader {
    width : 24%;
    position: absolute;
    top: 50%;
    left : 50%;
    -webkit-transform: translateY(-50%) translateX(-50%);
    -moz-transform: translateY(-50%) translateX(-50%);
    -o-transform: translateY(-50%) translateX(-50%);
    transform: translateY(-50%) translateX(-50%);
  }
  
  .payment-loader .binding {
    content : '';
    width : 60px;
    height : 4px;
    border : 2px solid #00c4bd;
    margin : 0 auto;
  }
  
  .payment-loader .pad {
    width : 25%;
    height : 60px;
    border-radius : 8px;
    border : 2px solid #00c4bd;
    padding : 6px;
    margin : 0 auto;
  }
  
  .payment-loader .chip {
    width : 12px;
    height: 8px;
    background: #00c4bd;
    border-radius: 3px;
    margin-top: 4px;
    margin-left: 3px;
  }
  
  .payment-loader .line {
    width : 52px;
    margin-top : 6px;
    margin-left : 3px;
    height : 4px;
    background: #00c4bd;
    border-radius: 100px;
    opacity : 0;
    -webkit-animation : writeline 3s infinite ease-in;
    -moz-animation : writeline 3s infinite ease-in;
    -o-animation : writeline 3s infinite ease-in;
    animation : writeline 3s infinite ease-in;
  }
  
  .payment-loader .line2 {
    width : 32px;
    margin-top : 6px;
    margin-left : 3px;
    height : 4px;
    background: #00c4bd;
    border-radius: 100px;
    opacity : 0;
    -webkit-animation : writeline2 3s infinite ease-in;
    -moz-animation : writeline2 3s infinite ease-in;
    -o-animation : writeline2 3s infinite ease-in;
    animation : writeline2 3s infinite ease-in;
  }
  
  .payment-loader .line:first-child {
    margin-top : 0;
  }
  
  .payment-loader .line.line1 {
    -webkit-animation-delay: 0s;
    -moz-animation-delay: 0s;
    -o-animation-delay: 0s;
    animation-delay: 0s;
  }
  
  .payment-loader .line.line2 {
    -webkit-animation-delay: 0.5s;
    -moz-animation-delay: 0.5s;
    -o-animation-delay: 0.5s;
    animation-delay: 0.5s;
  }
  
  .payment-loader .loader-text {
    text-align : center;
    margin-top : 20px;
    font-size : 16px;
    line-height: 16px;
    color : #5f6571;
    font-weight: bold;
  }
  
  
  @keyframes writeline {
    0% { width : 0px; opacity: 0; }
    33% { width : 52px; opacity : 1; }
    70% { opacity : 1; }
    100% {opacity : 0; }
  }
  
  @keyframes writeline2 {
    0% { width : 0px; opacity: 0; }
    33% { width : 32px; opacity : 1; }
    70% { opacity : 1; }
    100% {opacity : 0; }
  }
  
}

.cke_notifications_area{
  display: none;
}
.ant-modal-wrap.invalid-plan-modal {
  z-index: 999999;
}
.font-20{
font-size: 20px;
}
.pointer_top{
  border-bottom: 1px solid #ffffff;
  width: fit-content;
  &:hover, &.active{
    border-bottom: 1px solid #F7F7F7;
    color: #0075FF;
    cursor: pointer;
}
}
.pointer_top{
  border-bottom: 1px solid #ffffff;
  width: fit-content;
  &:hover, &.active{
    border-bottom: 1px solid #F7F7F7;
    color: #0075FF;
    cursor: pointer;
}
}

.selected-contacts{
    .ant-select-selector{
      height: 100px;
      min-height: 50px;
      flex-wrap: wrap;
      overflow: auto;
      align-items: flex-start !important;
    }
    .ant-select-selection-placeholder{
      top: 12%;
    }
}
.table-height{
  height: calc(100vh - 53px);
  overflow: auto;
}
.time_table_actions{
  width: 99%;
  display: flex;
  justify-content: end;
  align-items: center;
  padding: 10px 10px 0px ;
}
.ant-notification{
margin: 15px !important;
}
.ant-notification-bottom {
 
  // right: auto !important; /* Reset right alignment */
  // left: 50% !important; /* Center horizontally */
  // transform: translateX(-50%); /* Adjust to truly center */
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  bottom: 20px; /* Ensure it's at the bottom */
  // width: fit-content;
}


.ant-notification-notice{
  padding: 10px 24px;
  border-radius: 30px;
  .anticon{
    color: white !important;
  }
  .ant-notification-notice-message{
    color: white;
  }
  .anticon.ant-notification-notice-icon-success{
    color: white;
  }
  .ant-notification-notice-close{
    color: white;
  }
}
.ant-notification-notice.error{
  background-color: #F85353;
  
}

.ant-notification-notice.success{
  background-color: #1CB980;
}

.ant-notification-notice.warning{
  background-color: #ffcc00;
}
.custom-notification .ant-notification-notice {
  // position: fixed;
  // right: auto !important; /* Reset right alignment */
  // left: 50% !important; /* Center horizontally */
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  bottom: 0;
  
  // transform: translateX(-50%);
  margin-bottom: 20px; /* Adjust as needed */
}



