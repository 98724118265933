.email-template-modal {
  .ant-drawer-content-wrapper {
    width: 700px!important;

    .ant-drawer-wrapper-body {
      position: relative;
    }
  }
  .ant-drawer-content {
    box-shadow: 0 6px 20px rgba(0, 0, 0, .5);
    max-width: 700px;
    margin-left: auto;
    width: 100%;

    .scrollable-content {
      padding-bottom: 10px;

      .email-to-control {
        .ant-legacy-form-item {
          #Email_Template_receivers {
            &:hover {
              border: 1px solid #51a35d;
            }

            &:focus {
              outline: 0;
            }
          }
        }

        .ant-legacy-form-item-label {
          top: 9px!important;
        }

        .to-input-control {
          border: 1px solid #e5e5e5;
          display: flex;
          min-height: 34px;
          position: relative;

          .ant-select-focused {
            .ant-select-selector {
              border-width: 0!important;
            }
          }

          .ant-select-multiple {
            border-color: transparent;
            outline: 0;
            width: calc(100% - 135px);

            &:hover {
              .ant-select-selector {
                border-width: 0!important;
              }
            }
          }

          .ant-select-selector {
            outline: 0;
            padding-left: 38px;

            .ant-select-selection-search-input {
              padding-left: 0;
            }
          }
        }

        .cc-bcc-button {
          align-items: flex-end;
          display: inline-flex;
          line-height: 1.5;
        }
      }
    }

    .ant-drawer-body {
      margin-right: 0;
      overflow-x: hidden;
      padding: 20px 5px 0 5px;
      width: 690px;
    }

    .ant-modal-close-icon {
      transition: transform .5s,opacity .2s!important;
      -ms-transform-origin: center center;
      transform-origin: center center;

      &:hover {
        transform: rotate(180deg);
      }
    }

    .ant-modal-header {
      border-bottom: 1px solid #e5e5e5;
      padding-top: 20px;

      .ant-modal-title {
        padding-bottom: 0;
      }
    }

    .ant-modal-body {
      padding-top: 20px;
      padding-bottom: 0;
      overflow-x: hidden;
    }

    .modal-content {
      padding-left: 20px;
      padding-right: 20px;

      .email-form-group {
        position: relative;
      }

      .ant-legacy-form-item {
        position: relative;

        .ant-legacy-form-item-label {
          line-height: 1.2;
          position: absolute;
          top: 12px;
          left: 10px;
          text-align: left;
          z-index: 99;

          label {
            color: #656b79;
          }
        }

        .ant-legacy-form-item-control {
          input {
            padding-left: 2px;
          }
        }
      }

      .subject-form-control {
        margin-bottom: 15px;

        #Email_Template_subject {
          padding-left: 65px;

          &::placeholder {
            color: #656b79;
          }
        }
      }

      .file-uploader-container {
        margin-top: 15px;
        position: relative;

        >.upload-files-container {
          display: flex;
          flex-direction: column-reverse;

          .ant-upload-list {
            align-items: center;
            border: 1px solid #d9d9d9;
            border-radius: 4px;
            display: inline-flex;
            // font-weight: 600;
            margin-top: 5px;
            margin-bottom: 15px;
            min-height: 32px;
            width: 100%;
            flex-wrap: wrap;

            .ant-upload-list-item {
              margin-top: 0;
              margin-bottom: 2px;
            }
          }
        }
      }

      .footer-btn-group {
        border-top: 1px solid #e5e5e5;
        padding-top: 8px;
        margin-right: -60px;
        background: #fff;
        // position: fixed;
        bottom: 0;
        right: 60px;
        width: 700px;
        margin-left: -30px;
        padding-bottom: 8px;
        text-align: right;
        z-index: 99;
        position: fixed;

        .ant-btn {
          margin-right: 20px;
          height: 38px;
        }
      }
    }
  }

  .ck.ck-editor__main{
    min-height: 250px;

    border:none !important;
    .ck-content.ck-editor__editable{
      min-height: 250px;
    }
  }
}

.copy-checkbox {
  >.ant-legacy-form-item-label {
    width: 100%;

    label {
      padding-left: 15px;
    }
  }
}

.email-editor {
  padding-top: 5px;
  padding-left: 1px;
}

.ant-upload-list-item-name-icon-count-1{
  padding-left: 5px;
}