@import '~antd/dist/antd.css';
@import '../common.scss';
@import '../../assets/stylesheets/global/variable';


%list-flex {
  display: flex;
  align-items: center;
  // list-style-type: none;
  flex-direction: row;
}

.header-wrapper {
  background: #ffffff;
  color: $headerText;
  display: flex;
  align-items: center;
  position: fixed;
  z-index: 9;
  border-bottom: 1px solid $header-border-color;
  height: $header-height;
  padding: 20px;
  transition: linear 0.25s; 
  box-shadow: 26px 0px 3px 0px rgba(0, 0, 0, 0.08);


  &.expanded-header{
    // margin-left: $collapsed-sidebar-width;
    width: calc(100% - #{$collapsed-sidebar-width});
    right: 0;
    // margin-left: 85px;
    // width: calc(100% - 85px);
  }
  &.normal-header{
    margin-left: $expanded-sidebar-width;
    width: calc(100% - #{$expanded-sidebar-width});
  }

  .header-option {
    color: $headerText;
    line-height: 20px;
    list-style-type: none;
    padding: 0px !important;
  }
  .header-icon{
    padding: 10px 13px !important;
    margin-right: 2px;
    border-radius: 30px;

    &:hover,
    &:active,
    &:focus {
      cursor: pointer;
      background-color: #eff2fe;
      color: #0075FF;

    }
  }

  .menu-option-wrapper {
    position: relative;
  }

  .header-left-section {
    @extend %list-flex;
    width: 50%;
    height: 100%;

    button{
      width: 100px;
      height: 38px;
      background-color: #ffffff;
      border: 1px solid #f1f1f1;
      color: red;
      font-size: 12px;
      display: flex;
      align-items: center;  
      justify-content: center;
      border-radius: 5px;
      color: #58596C;
      cursor: pointer;

      &.active{
        background-color: #1CB980;
        color: #ffffff;
        border: 1px solid #1CB980;
      }

      i{
        font-size: 11px;
        padding-top: 4px;
        margin-right: 4px;
      }


    }

    .toggle-menu{
      font-size: 18px;
      color: #606060;
      cursor: pointer;
    }

    // .header-option {
    //   position: relative;
    //   color: rgb(78, 78, 78);
    //   font-size: 16px;
  
    //   .user-dropdown {
    //     border: 1px solid #f0f0f1;
    //     border-radius: 4px;
    //     display: none;
    //     width: 360px;
  
    //     .user-header {
    //       padding: 20px 15px 10px 15px;
    //       position: relative;
    //       display: flex;
    //       flex-direction: row;
    //       font-size: 14px;
    //       border-bottom: 1px solid #e7e7e7;
  
    //       .user-info{
    //         padding-left: 20px;
  
    //         .link{
    //           color: #0075FF;
    //           cursor: pointer;
    //           position: relative;
    //           text-decoration: underline;
    //           font-size: 12px;
    //         }
  
    //         .user-email{
    //           font-size: 12px;
    //         }
    //       }
  
    //       .close {
    //         cursor: pointer;
    //         font-size: 20px;
    //         opacity: .7;
    //         position: absolute;
    //         top: 15px;
    //         right: 15px;
  
    //         &:hover {
    //           opacity: 1;
    //         }
    //       }
  
    //       .my-account-logout {
    //         .link {
    //           color: #478BCA;
    //           cursor: pointer;
    //           position: relative;
  
    //           &:after {
    //             content: '';
    //             position: absolute;
    //             top: 3px;
    //             right: -20px;
    //             background-color: #656b79;
    //             width: 1px;
    //             height: 15px;
    //           }
    //         }
  
  
    //       }
    //     }
  
    //     .organisation-info {
    //       background-color: #fff;
    //       color: #656b79;
    //       font-size: 14px;
  
    //       .info-row{
    //         display: flex;
    //         padding: 8px 12px;
  
    //         .title {
    //           text-transform: capitalize;
    //           width: 40%;
    //         }
    //       }
  
    //       &:first-child{
    //         margin-top: 15px;
    //       }
  
    //       .link {
    //         color: #0075FF;
    //       }
    //     }
  
    //     .user-footer {
    //       border-top: 1px solid #e7e7e7;
    //       padding: 10px;
    //       text-align: center;
          
    //       .logout{
    //         background-color: #ff4d4f;
    //         color: #ffffff;
    //         width: 100%;
    //         height: 40px;
    //       }
  
    //       ul {
    //         color: #656b79;
    //         display: flex;
    //         justify-content: center;
    //         padding-top: 1em !important;
  
    //         li {
    //           cursor: pointer;
    //           list-style-type: disc;
    //           margin-right: 15px;
    //           padding-right: 15px;
    //           padding-left: 15px;
    //         }
    //       }
    //     }
    //   }
  
    //   &.active {
    //     .user-dropdown {
    //       display: inline-block;
    //       background-color: #fff;
    //       border-radius: 4px;
    //       box-shadow: 0 2px 18px rgba(155, 155, 155, 0.2);
    //       cursor: default;
    //       position: absolute;
    //       top: 45px;
    //       right: 12px;
  
    //       &:after {
    //         content: '';
    //         width: 0;
    //         height: 0;
    //         border-left: 5px solid transparent;
    //         border-right: 5px solid transparent;
    //         border-bottom: 5px solid #fff;
    //         position: absolute;
    //         top: -5px;
    //         right: 5px;
    //       }
    //     }
    //   }
    // }

  }


  .header-right-section{
    @extend %list-flex;
    justify-content: flex-end;
    width: 50%;
    margin: 0px;
    list-style-type: none;
    gap: 25px;

    li {
      &.header-item{
        position: relative;
        color: #BBBBBB;
        font-size: 16px;
        height: 40px;
        width: 40px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color:#F8F8F8;
        border-radius: 10px;
  
        i{
          color: #BBBBBB;
          font-size: 16px;
          margin-top: 4px;
        }

      }
      &.user-profile{
        max-width: 150px;
        width: min-content;
        background-color: unset;
        cursor: pointer;

        .top-profile-pic{

          img{
            height: 40px;
            width: 40px;
            border-radius: 1000px;
          }
        }


        .user-icon{
          width: 40px;
          height: 40px;
          background-color: #FCDF4C;
          color: #000000;
          font-weight: 600;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 30px;
          letter-spacing: 1px;
          cursor: pointer;
          padding: 3px 0 0 3px;
        }

        .user-detail{
          margin-left: 10px;
          color: #071028;
          width: 70px;
          display: flex;
          // align-items: center;
          justify-content: center;
          flex-direction: column;
          line-height: 1.3;

          .role{
            color: #939393;
            font-size: 11px;
            letter-spacing: 0px;
          }
        }
        

        .right-dropdown{
          display: flex;
          align-items: center;
          margin-left: 10px;

          i{
            color: #FFA1AC;
            // opacity: 50%;
            margin-top: 4px;
          }
        }

        .user-dropdown {
          display: inline-block;
          background-color: #fff;
          border-radius: 4px;
          box-shadow: 0 2px 18px rgba(155, 155, 155, 0.2);
          cursor: default;
          position: absolute;
          top: 68px;
          right: 7px;
          width: 286px;

          &:after {
            content: '';
            width: 0;
            height: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-bottom: 5px solid #fff;
            position: absolute;
            top: -5px;
            right: 22px;
          }
    
          .user-header {
            padding: 20px 15px 10px 15px;
            position: relative;
            display: flex;
            flex-direction: row;
            font-size: 14px;
            border-bottom: 1px solid #e7e7e7;
    
            .user-info{
              padding-left: 20px;
              color: #656b79;
    
              .link{
                color: #0075FF;
                cursor: pointer;
                position: relative;
                // text-decoration: underline;
                font-size: 12px;

                &:hover{
                  text-decoration: underline;
                }
              }
    
              .user-email{
                font-size: 12px;
              }
            }
    
            .close {
              cursor: pointer;
              font-size: 20px;
              opacity: .7;
              position: absolute;
              top: 15px;
              right: 15px;
    
              &:hover {
                opacity: 1;
              }
            }
    
            .my-account-logout {
              .link {
                color: #478BCA;
                cursor: pointer;
                position: relative;
    
                &:after {
                  content: '';
                  position: absolute;
                  top: 3px;
                  right: -20px;
                  background-color: #656b79;
                  width: 1px;
                  height: 15px;
                }
              }
    
    
            }
          }
    
          .organisation-info {
            background-color: #fff;
            color: #656b79;
            font-size: 14px;
    
            .info-row{
              display: flex;
              padding: 8px 12px;
    
              .title {
                text-transform: capitalize;
                width: 40%;
              }
            }
    
            &:first-child{
              margin-top: 15px;
            }
    
            .link {
              color: #0075FF;
            }
          }
    
          .user-footer {
            border-top: 1px solid #e7e7e7;
            padding: 10px;
            text-align: center;
            
            .logout{
              background-color: #ff4d4f;
              color: #ffffff;
              width: 100%;
              height: 40px;
              .anticon{
                display: inline-flex;
              }
            }
    
            ul {
              color: #656b79;
              display: flex;
              justify-content: center;
              padding-top: 1em !important;
    
              li {
                cursor: pointer;
                list-style-type: disc;
                margin-right: 15px;
                padding-right: 15px;
                padding-left: 15px;
              }
            }
          }
        }
    
        // &.active {
        //   .user-dropdown {
        //     display: inline-block;
        //     background-color: #fff;
        //     border-radius: 4px;
        //     box-shadow: 0 2px 18px rgba(155, 155, 155, 0.2);
        //     cursor: default;
        //     position: absolute;
        //     top: 60px;
        //     right: 23px;
        //     width: 286px;
    
        //     &:after {
        //       content: '';
        //       width: 0;
        //       height: 0;
        //       border-left: 5px solid transparent;
        //       border-right: 5px solid transparent;
        //       border-bottom: 5px solid #fff;
        //       position: absolute;
        //       top: -5px;
        //       right: 5px;
        //     }
        //   }
        // }
        
      }

  
    }
  }

  // .header-left-functions,
  // .header-right-functions {
  //   @extend %list-flex;
  //   margin-bottom: 0;
  //   margin-left: 87px;
  // }

  // .header-right-functions {
  //   margin-left: auto;
  // }
}

.ant-menu-root {
  height: 100vh;
}

.header-right-functions {
  .header-option {
    position: relative;
    color: rgb(78, 78, 78);
    font-size: 16px;
    padding: 0px;

    .user-dropdown {
      border: 1px solid #f0f0f1;
      border-radius: 4px;
      display: none;
      width: 360px;

      .user-header {
        padding: 20px 15px 10px 15px;
        position: relative;
        display: flex;
        flex-direction: row;
        font-size: 14px;
        border-bottom: 1px solid #e7e7e7;

        .user-info{
          padding-left: 20px;

          .link{
            color: #0075FF;
            cursor: pointer;
            position: relative;
            // text-decoration: underline;
            font-size: 12px;

            &:hover{
              text-decoration: underline;
            }
          }

          .user-email{
            font-size: 12px;
          }
        }

        .close {
          cursor: pointer;
          font-size: 20px;
          opacity: .7;
          position: absolute;
          top: 15px;
          right: 15px;

          &:hover {
            opacity: 1;
          }
        }

        .my-account-logout {
          .link {
            color: #478BCA;
            cursor: pointer;
            position: relative;

            &:after {
              content: '';
              position: absolute;
              top: 3px;
              right: -20px;
              background-color: #656b79;
              width: 1px;
              height: 15px;
            }
          }


        }
      }

      .organisation-info {
        background-color: #fff;
        color: #656b79;
        font-size: 14px;

        .info-row{
          display: flex;
          padding: 8px 12px;

          .title {
            text-transform: capitalize;
            width: 40%;
          }
        }

        &:first-child{
          margin-top: 15px;
        }

        .link {
          color: #0075FF;
        }
      }

      .user-footer {
        border-top: 1px solid #e7e7e7;
        padding: 10px;
        text-align: center;
        
        .logout{
          background-color: #ff4d4f;
          color: #ffffff;
          width: 100%;
          height: 40px;
        }


        ul {
          color: #656b79;
          display: flex;
          justify-content: center;

          li {
            cursor: pointer;
            list-style-type: disc;
            margin-right: 15px;
            padding-right: 15px;
            padding-left: 15px;

            &:first-child {
              list-style-type: none;
            }

            &:last-child {
              margin-right: 0;
              padding-left: 0;
            }
          }
        }
      }
    }

    &.active {
      .user-dropdown {
        display: inline-block;
        background-color: #fff;
        border-radius: 4px;
        box-shadow: 0 2px 18px rgba(155, 155, 155, 0.2);
        cursor: default;
        position: absolute;
        top: 45px;
        right: 12px;

        &:after {
          content: '';
          width: 0;
          height: 0;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-bottom: 5px solid #fff;
          position: absolute;
          top: -5px;
          right: 5px;
        }
      }
    }
  }
}


// .user-icon{
//   padding: 10px 8px;
//   background-color: #eff2fe;
//   border-radius: 30px;
//   letter-spacing: 1px;
//   cursor: pointer;

//   &:hover{
//     background-color: #edf1ff;
//     color: #0075FF;
//   }
// }
.top-profile-pic{

  img{
    height: 40px;
    width: 40px;
    border-radius: 1000px;
  }
}
.user-upgrade{
  color: #5a5be4;
  cursor: pointer;
  background-color: #efefff;
  border-radius: 10px;
  padding: 0.6rem 1.2rem;

  .grothTrial{
    font-weight: 700;
    font-size: 0.875rem;
  }
  .trialUpgrade{
    color: #0075FF;
  }
}
.notification-wrapper{
  position: relative;
  overflow: hidden;
}
.alert-messages{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
 
  z-index: 999;
  transition: opacity 0.5s ease-out;
  background-color: #ECB930;
  display: flex;
  justify-content: space-between;
  align-items: center;
 
  padding: 10px 2rem;
  font-size: 14px;

  .left-content{
    display: flex;
    gap: 10px;
     .message{
      display: flex;
      gap: 5px;
     }
  }
  .right-content{
    display: flex;
    gap: 20px;
    align-items: center;

    button{
      border: 1px solid rgb(122, 122, 122);
      background-color: transparent;
      cursor: pointer;
      padding: 5px 10px;
      border-radius: 3px;
      font-weight: 700;
    }
  }
}