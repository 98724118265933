@import '~antd/dist/antd.css';
@import '../common.scss';

.login-wrapper {
    // background: url("./../../assets/images/login-bg.jpg") no-repeat;
    background-size: cover;
    background-color: #ECF5FF;
    min-height: 100vh;

    .login-panel {
        align-items: center;
        display: flex;
        justify-content: center;
        height: 97vh;
        width: 430px;
        flex-direction: column;
        align-items: center;

        .left-text {
            align-self: flex-start;
            padding-top: 100px;

            h1 {
                font-size: 36px;
                font-weight: 700;
                line-height: 1.3;
            }

            p {
                font-size: 16px;
                font-weight: 400;
                width: 250px;
            }
        }

        .login-form {
            // background: url("./../../assets/images/login-form-background.png") no-repeat;
            background-color: #FFFFFF;
            background-size: cover;
            color: #fff;
            font-family: Rutledge, sans-serif;
            // margin-top: 50px;
            min-height: 400px;
            padding: 41px 23px;
            // max-width: 400px;
            width: 100%;
            height: 500px;
            box-shadow: 0px 0px 21px 2px rgba(12, 9, 27, 0.07);


            h4 {
                color: #030303;
                font-size: 24px;
                font-weight: 700;
                margin-bottom: 0px;
                text-align: left;
            }

            .sub-heading{
                color: #3D3E58;
                margin-bottom: 25px;
            }

            .or-text {
                text-align: center;
                padding-bottom: 10px;
                position: relative;

                &:before {
                    background-color: #0062b2;
                    content: '';
                    position: absolute;
                    top: 10px;
                    left: 10px;
                    height: 1px;
                    width: 120px;
                }

                &:after {
                    background-color: #0062b2;
                    content: '';
                    position: absolute;
                    top: 10px;
                    right: 10px;
                    height: 1px;
                    width: 120px;
                }
            }

            .link-url {
                padding-bottom: 30px;
                text-align: center;
                padding-top: 28px;

                a {
                    color: #0075FF;
                    text-decoration: underline;
                    font-size: 16px;
                    font-weight: 500;
                    text-decoration: none;
                }
            }

            .input-label {
                font-size: 16px;
            }

            .input-error {
                color: $error-red;
                border-color: $error-red;
            }

            .ant-input {
                border-radius: 0 3px 3px 0;
                color: #000;
                font-size: 16px;
                height: 40px;

                &:hover {
                    border-color: #51a35d;
                }
            }

            .icon {
                background: $grey-color;
                border-radius: 3px 0 0 3px;
                border: 1px solid $grey-color;
                color: #000;
                font-size: 16px;
                font-weight: 600;
                height: 50px;
                line-height: 40px;
                width: 45px;
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner:hover {
                @include green-border;
            }

            .login-form-input-error {
                &:hover, &:focus {
                    border-color: $error-red;
                }
            }

            .ant-checkbox-checked .ant-checkbox-inner {
                @include green-btn-style;
            }

            .remember-checkbox {
                margin-top: -5px;

                .ant-checkbox-wrapper-checked {
                    .ant-checkbox-inner {
                        background-color: #0062b2;
                        border-color: #0062b2;
                    }
                }

                .ant-checkbox-inner {
                    border-color: #63738a;

                    &:hover {
                        border-color: #0062b2;
                    }
                }
                span {
                    color: #fff;
                    font-size: 16px;
                }
            }

            .login-form-submit {
                background-color: #0075FF!important;
                border-color: 1px solid #3D48E5!important;
                color: #fff;
                font-size: 16px;
                height: 48px;
                width: 100%;
                padding: 6px 10px;
                gap: 10px;
                // background: #0075FF;
                // border: 1px solid #3D48E5;
                border-radius: 4px;
                margin-top: 25px;
                

                &:hover {
                    background-color: #0073d1;
                    opacity: .8;
                }
            }
        }
    }

    .login-inner-wrapper {
        margin-right: auto;
        margin-left: auto;
        max-width: 430px;
        width: 100%;
    }
}

.site-alert-container {
    background-color: rgba(#000, .7);
    color: #fff;
    padding-top: 15px;
    padding-bottom: 15px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    .inner {
        display: flex;
        font-size: 15px;
        justify-content: center;
        position: relative;

        .fa-info-circle {
            margin-top: 3px;
        }

        .message {
            width: 45%;

            .font-strong {
                font-weight: 600;
            }

            .link {
                color: #000;
                cursor: pointer;
                text-decoration: underline;
            }
        }
    }

    .close-message-section {
        cursor: pointer;
        position: absolute;
        top: 15px;
        right: 15px;
        z-index: 99;
    }

    &.hide {
        display: none;
    }
}

.remember-me-tooltip {
    .ant-tooltip-inner {
        background-color: #fff;
        color: #000;
    }

    .ant-tooltip-arrow {
        &:before {
            background-color: #fff;
        }
    }
}



.login-page{ height: 100vh; 
    background-image: linear-gradient(to right, #4b98e9 , #4aaa98); 
    position: relative;}
  
  .login-box{margin: 0; font-family: 'Lato', sans-serif; 
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);  min-width: 1200px;}
  .login-box .left-side{ background: #fff; 
    // border-top: 10px solid #406b4d;
    // border-bottom: 10px solid #d7d7d7; 
    padding: 50px ; float: left; border-radius: 26px 0px 0px 26px; width: 50%; 
    position: relative; height: 485px;}
  
  .login-logo{ height: 60px; width: 60px; 
    border-radius: 50%; 
    top: -30px; 
    margin-left: -30px;
  left: 50%; position: absolute;
  }
  .login-logo img{ width: 100%;}
  .login h2{ font-size: 50px; text-align: center;  padding-top: 10px; 
     color: #8b8b8b; font-weight: 300; font-family: 'Lato', sans-serif; margin: 0px; }
  .login .text{ padding: 10px 0px 35px; color:#406b4d ; text-align: center; font-size: 18px;}
  .login .text a{color:#406b4d ; text-decoration: none;}
  .login-fed-box{ padding: 10px 0px 0px;}
  
  .login-feds{ background: initial; border-radius: initial; 
    border: 0px; width: 100%; color: #000;
    border-bottom: 2px solid #cdcdcd; height: 50px; margin-bottom: 10px; padding: 0px 5px;}
    .login-feds:focus{outline: 0px; border-bottom: 2px solid #406b4d; box-shadow: initial; }
  .top-add{ padding-top: 20px;}
  .login-sub{ border: 0px;
    background-image: linear-gradient(to right, #4b98e9 , #4aaa98); 
    color: #fff;  border-radius: 50px; font-size: 24px; width: 100%; 
    height: 50px; line-height: 48px; cursor: pointer;
  }
  .test-fortg{margin-top: 7px; text-align: center;}
  .test-fortg a{ font-size: 16px; color: #818181; }
  
  
  .login-box .right-side{ background: url("./../../assets/images/right-pic-bg.jpg") no-repeat;
    background-size: cover;
       float: left; border-radius: 0px 26px  26px 0px ; width: 50%; 
    position: relative; height: 485px; overflow: hidden; text-align: center; }
  
  .login-box .right-side img{ width: 80%; margin: 0px auto;}
  .login-box .right-side .carousel-inner{ padding: 30px;}
  .login-subs{ border: 0px;
    background-image: linear-gradient(to right, #4b98e9 , #4aaa98); 
    color: #fff;  border-radius: 50px; font-size: 24px; width: 200px; 
    height: 50px; line-height: 48px; position: absolute; 
    left:50% ; margin-left:-100px; bottom: 61px; z-index: 999;  cursor: pointer;
  }
  .login-subs a{ color: #fff; text-decoration: none;}

  .login-box .right-side h3{ font-size: 34px !important; 
    font-weight: 400;  text-align: center; color: #fff ; 
    padding: 20px 0px 10px 30px; margin: 0px; }
.line-box5{border-bottom: solid 1px #363636; padding:3px 0px ; 
    margin: 10px 0px ; width: 100%;}

.login-box .right-side h3 span{ font-size: 18px; color: #a4a4a4}

    .login-box .right-side h4{ font-size: 18px !important; 
        color: #fff;  
        text-align: left;  font-weight: 300;
        padding: 10px 0px 0px 80px; margin: 9px 0px; position: relative;}
.login-box .right-side h4 span{ position: absolute; left: 35px; top: 10px; width: 30px;} 
.login-box .right-side h4 span img{ width: 100%;}
  
.but-buttons .ant-upload.ant-upload-select-picture-card{ width: 100%;}

.login-wrapper .login-panel .login-form .ant-input{
    height: 48px;
    background: #F5F7F9;
    border: 1px solid #E7E7EA;
    border-radius: 4px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    gap: 10px;
}
.no-account-signup{
    font-size: 14px;
    margin-top: 25px;
    color: #3D3E58;
}

.password-icon{
    color: #9E9FAB;
    font-size: 24px;
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}


// loader css 
.lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 3px solid #111111;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #7659fc transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  