.modal-custom-detail{
    overflow: inherit;
}
.modal-custom-detail .ant-modal-content{
    min-height: 100vh;
}


.custom-modal-content {
    .ant-modal-content {
         min-height: 680px;
        max-height: 680px;
        // overflow-y: auto;
    }
  
}

.custom-modal-full-height-content {
  .ant-modal-content {
      min-height: 800px;
      max-height: 800px;
      overflow-y: auto;
  }
}

.customModal-button-group {
    display: flex;
    justify-content: space-between;

    .ant-btn-default{
      background-color: #ffffff;

    }
}

.customModal-button-group-center {
  display: flex;
  justify-content: center;
}

.balance-sheet-popup-container{
  height: 400px;
  overflow: scroll;
}

.ant-modal-body{
  .agGridWrapper{
    height: 400px;
    overflow: scroll;
  }
}

.balance-sheet-table {
  border-collapse: collapse;
  color: #656b79;
  font-family: "Open Sans", "Segoe UI", "Droid Sans", Tahoma, Arial, sans-serif;
  font-size: 13px;
  width: 100%;

  th, td {
    border: 1px solid #f5f5f5;
    text-align: left;
    padding: 6px 10px;
  }


  tr {
    &:nth-child(even) {
      background-color: #f9f9f9;
    }

    &:nth-child(odd) {
      background-color: #fbfbfb;
    }
  }

  .bold-text {
    font-weight: 700;
  }

  .text-center {
    text-align: center;
  }

  .text-right {
    text-align: right;
  }

  .white-bg {
    background: #fff;
    border-right: 0;
  }
}


.modal-custom-registration .ant-modal-content .ant-modal-body{max-height: initial;}
.seting-org-online{  overflow: auto; height: 500px; }
.contflt .ant-legacy-form-item-label{ overflow: initial;}
.nert{ padding-left: 45px;}

.newAssignment-new .ant-modal-body{max-height: 700px;}

.register-container {
  padding: 20px 8% 40px 8%;
  overflow: scroll;
  // margin: 0 8%;
  background-color: #ffffff;
  max-height: calc(100vh - 58px);
  height: 100vh;

  .reg-header{
      display: flex;
      align-items: center;
      margin: 0 !important;
      padding: 10px 15px;
      line-height: 17px;
  
      .address-part{
          display: flex;
          flex-direction: column;
          align-items: flex-end;
      }
  }

  .form-container{
      margin-top: 5px;

      .form-header{
          padding: 10px;
          display: flex;
          flex-direction: column;
          align-items: center;
          line-height: 1.3;
  
          .form-heading{
              font-size: 18px;
              font-weight: 700;
              color: #000000;
          }
  
          .form-subheading{
              font-size: 12px;
              color: #000000;
              text-align: center;
          }
      }
      .info-box{
          display: flex;
          flex-direction: row;

          .parents-info{
              padding: 5px 10px 5px 20px;
              margin: 15px 0 15px 0;
              font-size: 12px;
              width: 50%;

              .info-head{

                  font-weight: 700;
                  font-size: 13px;
                  margin-bottom: 8px;

                  
                  .primary-label{
                      padding: 3px 8px;
                      background-color: #FFB020;
                      color: #ffffff;
                      height: 20px;
                      text-align: center;
                      border-radius: 44px;
                      font-size: 11px;
                      font-weight: 400;
                  }
              }

          }
      }
      .forms-box{
          padding: 15px;
          height: calc(100vh - 320px);
          overflow: auto;
          font-size: 12px;
      }
  }



}
.primary {
  background: rgb(26, 115, 232);
  border-radius: 2px;
  color: #ffffff;
}
.info-box{
  display: flex;
  flex-direction: row;

  .parents-info{
      padding: 5px 10px 5px 20px;
      margin: 15px 0 15px 0;
      font-size: 12px;
      width: 50%;

      .info-head{

          font-weight: 700;
          font-size: 13px;
          margin-bottom: 8px;

          
          .primary-label{
              padding: 3px 8px;
              background-color: #FFB020;
              color: #ffffff;
              height: 20px;
              text-align: center;
              border-radius: 44px;
              font-size: 11px;
              font-weight: 400;
          }
      }

  }
}
.form-detail-ro{
  padding-left: 45px;
}
.bold-title{
  font-size: 14px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.65);
}
.download-btn{
  display: flex;
  // justify-content: end;
  padding: 3px;
  border: 1px solid #e7e7e7;
  width: 97px;
  height: 38px;
  text-align: center;
  align-items: center;
  cursor: pointer;
  margin-bottom: 5px;
  margin-left: 90.5%;
  background-color: #fff;
}
.reporting-container-actions{
  display: flex;
  justify-content: space-between;
  height: 58px;
  background-color: #fafbff;
  align-items: center;
  border-radius: 2px 2px 0 0;
  padding: 0 20px;
  border: 1px solid #e8e8ff;
  margin: 0 -1px;
  width: 100%;
}

.back-white{
  background-color: #ffffff;
} 
.back-white:hover{
  background-color: #ffffff;
}
.def-pdd{
  .ant-modal-content{
    max-height: fit-content;
    min-height: fit-content;
  }
  .ant-modal-body{
      max-height: fit-content;
    }
}
.image-container{
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin-top: 15px;
  height: 535px;
  overflow: auto;
  padding: 20px;
  
}
.image-upload-modal{
  .ant-modal-body{
  height: 600px;
  position: relative;
  overflow: hidden !important;
  padding: 0px !important;
  .ant-btn{
    background-color: #0075FF;
    border-color: #0075FF;
    color: #ffffff;
    position: absolute;
    right: 0;
    margin-right: 25px;
  }
  }

}
// .review-modal{
//   display: flex;
//   justify-content: center;
// align-items: center;
//   flex-direction: column;
// }

.custom-rate .ant-rate-star {
  font-size: 50px; 
}

.review-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.review-modal
     img {
  width: auto;
  height: 200px;
  max-width:300px;
  margin: 5px; 
}

.review-modal .image_container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.review-modal .image_container img {
  margin: 5px;
}
.image-btn{
  margin-top: 10px;
}
.media-section {
  margin-bottom: 1rem; 
  display: block; 
}
.media-content {
  padding: 1rem; 
  border-radius: 8px;
  transition: height 0.3s ease-in-out; 
  .ant-upload-list {
    margin-top: 10px;
    height: auto; 
  }
 
  .ant-upload {
    width: 100%; 
    border-radius: 12px;
    display: block;
    background-color: #ECF5FF;
    border: 1px dashed #0075FF;
  }
  
  .ant-upload-list {
    margin-top: 10px; 
  }
  
  .ant-upload {
    width: 100%; 
    display: block;
  }
  
 
}
.image_holder{
  display: flex;
  gap:10px;
  height: auto;
  flex-wrap: wrap;
}