.lock-screen-container {
  width: 100%;

  .form-control {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: center;

    .title {
      font-size: 24px;
      margin-bottom: 0;

      .color-text {
        color: #16a085;
      }
    }

    .sub-title {
      color: #656b79;
      font-size: 14px;
      font-weight: 600;

      &.highlighted {
        color: #111;
      }
    }

    .user-img-box {
      border: 4px solid #fff;
      border-radius: 4px;
      height: 95px;
      margin-bottom: 10px;
      width: 95px;

      img {
        height: 90px;
        width: 90px;
      }
    }

    .form-width {
      width: 300px;

      .ant-btn-primary {
        width: 100%;
      }
    }

    .logout-btn {
      align-self: flex-start;
      background-color: #e9ecf0;
      border-radius: 4px;
      color: #4a4a4a;
      font-weight: 500;
      font-size: 12px;
      padding: 3px 8px;
    }
  }

  .lock-screen-bg {
    background: url("./../../../../assets/images/lock-screen-bg.png");
    background-size: cover;
    height: 100vh;
  }
}
