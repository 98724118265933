@import "../../assets//stylesheets/global/variable";

.lead-meta {
  .page-title-bar {
    border-bottom: 1px solid #e8e8ff;
    height: 54px;
    padding: 0px 20px;
    z-index: 99;
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    // justify-content: space-between;
    align-items: center;
    gap: 10px;

    .page-title {
      font-size: 16px;
      color: $primaryColor;
      font-weight: 700;
    }

    .sep {
      width: 1px;
      background-color: #e7e7e7;
      height: 40%;
    }
  }

  .customSelect {
    margin-top: 5px;
    .ant-select-selector {
      height: 34px !important;
      width: 100%;
    }

    .ant-select-selector {
      min-height: 25px !important;
      border-radius: 5px !important;
      background-color: #dedede !important;
      font-weight: 500;
      border: none !important;
    }
  }

  .reminder-section {
    margin-top: 25px !important;
  }

  .settings-body {
    background-color: #ffffff;
    padding: 10px;
    height: calc(100vh - 125px);
    overflow-y: auto;

    .settings-body-child {
      .child-title {
        font-size: 14px;
        font-weight: 600;
        color: $primaryColor;
        padding-bottom: 5px;
        border-bottom: 1px solid #e7e7e7;
      }

      .child-body {
        display: flex;
        gap: 10px;
        margin: 10px 0;
        flex-wrap: wrap;

        .tile {
          width: calc((100% - 60px) / 3);
          border: 1px solid #f5f7f9;
          padding: 20px;
          border-radius: 4px;
          display: flex;
          background-color: #f5f7f9;
          cursor: pointer;
          height: 120px;

          &:hover {
            background-color: #e8ebee;
          }

          .tile-icon {
            padding-right: 12px;
            i {
              font-size: 18px;
            }
          }
          .tile-title {
            font-size: 14px;
            font-weight: 600;

      

            .tile-description {
              font-weight: 400;
              line-height: 1.3;
              font-size: 11px;


              margin-left: 22px;
    margin-top: 1px;
            }
          }
        }
      }
    }
  }
}

.datetime-picker-container  {
  display: flex;

  .ant-picker  {
    height: 32px !important;
  }
}

.title-wrap {
  margin-top: 10px;

  .input-text {
    height: 32px !important;
  }
}

// form buider page

.form-builder-section {
  background-color: #ffffff;
  height: calc(100vh - 98px);
  width: 100%;

  .drag-section {
    background-color: #efefef;
    height: calc(100vh - 98px);
    position: fixed;
  }

  .drag-header {
    padding: 10px;
    border-bottom: 1px solid #dbdbdb;

    h3 {
      font-size: 16px;
      font-weight: 700;
    }
  }

  .search-section {
    padding: 0 20px;
    .search-input {
      height: 32px;
      width: 100%;
      margin: 20px auto;
      border: 1px solid #a7a4a4;
    }
  }

  .drag-input-wraper {
    background-color: #dedddd;
    padding: 20px 10px 10px 10px;
  }

  .inner-additional-details {
    // border: 2px dotted #d6d6d6;
    height: calc(100vh - 273px); 
    overflow-y: auto; 
    overflow-x: hidden;

    &::-webkit-scrollbar {
      // display: none;
      width: 6px;
    }

  }


  .lead-dropdown-style{
    background-color: red;
    border: 1px solid red;
  }
 

  
  .input-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 5px;

    label {
      font-weight: 600;
      font-size: 12px;
    }
  }

  .required-tag {
    margin-left: 5px;
  }

  .button-group {
    display: flex;
    gap: 15px;

    button {
      background: none;
      outline: none;
      border: none;
      cursor: pointer;
    }
  }

  .draggableCompoent-lable {
    font-size: 12px;
    font-weight: 400;
    color: #000000;
  }
}

.drawerTitle {
  text-align: left !important;
  text-transform: capitalize;
  font-size: 14px;
}

.req-checkbox {
  margin-top: 20px;
  font-size: 14px;
  font-weight: 500;
}

.add-options {
  margin-top: 20px;

  .add-optopn-label {
    font-size: 20px !important;
    font-weight: 600 !important;
  }
}

.add-remove-button-wrap {
  margin-top: 19px;
}

.add-button {
  background: MediumSeaGreen;
  border-color: MediumSeaGreen;
  color: #ffffff;
}

.minus-button {
  background: #f03e3e;
  border-color: #f03e3e;
  color: #ffffff;
}

.minus-button:hover {
  background: #f03e3e;
  border-color: #f03e3e;
  color: #ffffff;
}

.add-button:hover {
  background: MediumSeaGreen;
  border-color: MediumSeaGreen;
  color: #ffffff;
}

.dragable-components {
  cursor: move;
  border: 2px dotted #807d7d;
  padding: 5px;
  height: 78px;
  width: 95px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  gap: 5px;
  border-radius: 4px;
  background: #ffffff;
  margin: 0 auto;

  img {
    width: 20px;
    height: auto;
  }

  p {
    font-size: 14px;
    font-weight: 600;
  }
}

.modal-container {
  position: relative;
}

.model-button-wraper {
  display: flex;
  align-items: center;
  gap: 10px;
}

.preview-show-form {
  min-height: 450px;
  display: flex;
  gap: 10px;
  flex-direction: column;
  max-height: 550px;
  overflow: scroll;
  padding-bottom: 50px;
}

.preview-show-form::-webkit-scrollbar {
  display: none;
  width: 0;
}

.model-footer {
  position: absolute;
  width: 100%;
  bottom: 0;
  background-color: #fafbff;
  margin-left: -25px;
  padding: 15px;
  display: flex;
  justify-content: flex-end;
}

.footer-position {
  position: absolute;
  width: 100%;
  bottom: 0;
}

.input-field {
  height: 32px;
}

.field-marginTop {
  margin-top: 16px;
}

.input-dotted-wrapper {
  border: 1px dotted #d6d6d6;
  padding: 10px;
}

.input-margin {
  margin-bottom: 15px;
}

.drawer-footer {
  position: absolute;
  bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 90%;
}

.lead-tile{
  // width: calc((100% - 60px) / 3);
  width: 100%;
          border: 1px solid #f5f7f9;
          padding: 24px;
          border-radius: 10px;
          // display: flex;
          background-color: #ffffff;
          cursor: pointer;
          height: 107px; 
          &:hover{
            background-color: #E8E8FF;
        }
}

.lead-back{
  background-color: #E8E8FF;
}

.tile-icon1{
margin-top: 2px;
width: 26.947px;
height: 26.947px;

}
.tile-title1{
 
  margin-left: 46px;
    margin-top: -36px;
}


.tile-description1 {
  margin-left: 46px;
margin-top: 3px;
line-height: 117%; /* 13px */

}

.header-tabs{
  padding: 10px;
}


