.StoreSettings-container{
  .profile-container{
    .sidenav{
      width:300px;

      @media only screen and (min-width: 1024px) and (max-width: 1400px) {
        width:260px;
      }
    }
    .main-body{
      width: calc(100% - 300px);

      @media only screen and (min-width: 1024px) and (max-width: 1400px) {
        width: calc(100% - 260px);
      }

      .store-model-container-f{
        // height: 100vh;
        padding: 0;

        .menuEstore-Container .ant-checkbox-wrapper{
          margin: 2px 0;
          align-items: center;

          .category-name-style{
            line-height: 14px;
          }
        }

        .menuEstore-Container{
          .img-Icon{width: 14px;}
          .header-structure-card.ant-card-bordered{
            border-right:none;
            height: 85vh;
          }
          .header-structure-card{
            .menuEstore-structure-card-content{
              overflow-y: auto;
              @media only screen and (min-width: 1024px) and (max-width: 1400px) {
                height:63vh;
              }
              @media only screen and (min-width: 1401px) {
                height:74vh;
              }

              .header-structure-card-buttons-wapper{
                display: flex;
                justify-content: space-between;
                align-items: center;

                .buttons-inner-wapper{
                  align-items: center;
                  gap:1rem;
                  margin-bottom:0;

                  .remove-btn{
                    color:#e50b20;
                  }
                  .cancel-btn{
                    color:#000;
                  }
                }
              }
              .ant-collapse-item{
                border: 1px solid #d9d9d9;
                border-radius: 4px;

                .ant-collapse-header{
                  border: none;
                }
              }
            }
            
          }
          .footer-btn{
            background-color: #fafbff;
            position: relative;
            
            display: flex;
            justify-content: end;
            margin:10px auto;
            bottom: auto;
            right: auto;
          } 
        }
      }
    }
  }
}

.menus-main-container {
  padding: 50px;

  .menu-list-card {
    width: 270px;
    background: #f7f7f7;
    min-width: 270px;
    height: fit-content;
    border-radius: 4px;

    .ant-card-body {
      padding: 16px;
      .ant-collapse-content > .ant-collapse-content-box {
        max-height: 250px;
        overflow-y: auto;
      }
    }
    .collapse-container {
      border: none;
      background: #f7f7f7;
    }

    .category-name-style {
      display: flex;
    }

    .menu-list-card-inner-wrapper  {
      background-color: red;
       
    }
  }

  .header-structure-card  {
    width: 100%;
    background: #f7f7f7;
    min-width: 270px;
    height: fit-content;
    max-height: 425px;
    overflow-y: auto;

    .empty-list {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 20px 0;
    }
  }
  
  .-collapse-header {
    display: flex;
    align-items: center;
     justify-content: space-between;



    .header-structure-card-collapse-header-child  {
      display: flex;
      align-items: center;
      gap : 20px;

      .collapse-header-right {
       font-size: 14px;
       font-weight: 600;
      
      }
    }
  }

  .header-structure-card-buttons-wapper  {
    margin-top: 10px;
  }

  .header-structure-card-buttons-wapper  {
      display: flex;
      align-items: center;
      justify-content: space-between;
    
    .buttons-inner-wapper {
      display: flex;
      align-items: center;
      gap : 15px;

      .remove-btn  {
        color: red;
        text-decoration: underline;
      }

       .cancel-btn {
        color: black;
       }
    }

    .update-btn  {
      background-color: aquamarine;
    }
  }
}

.menus-main-container {
  border-radius: 4px;
  .ant-checkbox-wrapper {
    display: flex;
    align-items: center;
  }

  .save-container {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}


.img-Icon{
  width: 20px;
  height: auto;
}

.menuEstore-Container{
  .ant-checkbox-wrapper{
    display: flex;
    margin:10px 0px;
   }
  .ant-card-bordered{
    border: none;
    border-right:1px solid #f0f0f0;
    height: calc(100vh - 21vh);
  }
  .ant-collapse{
    background-color: transparent;
    border:none;
    
  }
  .menuEstore-structure-card-content{
    height: calc(100vh - 32vh);
    .ant-collapse-item{
      border-bottom: none;
      .ant-collapse-header{
        border:1px solid rgb(160, 160, 160);
        border-radius: 5px;
      }
    }
    .header-structure-card-collapse-header{
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .header-structure-card-collapse-header-child{
      display: flex;
      gap: 10px;
      width: 60%;
      .collapse-header-right {
        font-size: 14px;
       
       
        text-overflow: ellipsis; 
   white-space: nowrap; 
   overflow: hidden; 
       }
    }
    .buttons-inner-wapper{
      display: flex;
      gap: 10px;
      margin-bottom: 5px;
    }
  }
  .save-container{
    text-align: right;
  }
  
}

.banner-manangement{
          
            .ant-upload.ant-upload-select-picture-card{
              width: 150px !important;
              height: 150px !important;
              background-color: white;
            }

          .ant-upload-list-picture-card .ant-upload-list-item{
            width: 150px !important;
            height: 150px !important;
          }
          .ant-upload-list-picture-card-container{
            width: 160px !important;
            height: 150px !important;
          }

          .desktop-banner{
            .ant-upload.ant-upload-select-picture-card{
              width: 150px !important;
              height: 80px !important;
              background-color: white;
            }
            .ant-upload-list-picture-card .ant-upload-list-item{
              width: 150px !important;
              height: 80px !important;
            }
            .ant-upload-list-picture-card-container{
              width: 160px !important;
              height: 80px !important;
            }
          }
          
}

.modal-banner{
  .ant-upload.ant-upload-select-picture-card{
    width: 100% !important;
    height: 324px !important;
    background-color: white;
  }
}