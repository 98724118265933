.forgetPasswordPart{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ECF5FF;
    height: 100vh;
  }
  
  .forgetPasswordForm {
    background-color: #fff;
    background-size: cover;
    max-width: 400px;
    min-height: 400px;
    padding: 40px 30px;
    width:100%;
    box-shadow: 0 0 21px 2px rgba(12,9,27,.07);
  }
  .forgetPasswordForm .inputField {
    margin: 0 0 15px 0;
  }
  .forgetPasswordForm .inputField input {
    padding: 10px;
  }
  .forgetPasswordForm .inputField .ant-btn{
    width: 100%;
    background-color: #0075FF;
    border: 1px solid #4096ff;
    color:#fff;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: bold;
    height: 44px;
  }
  .forgetPasswordForm .inputField .ant-btn:hover{
    color:#fff;
  }
  .forgetPasswordForm .inputField a{
    color: #0075FF;
    text-decoration:none;
  }
  .forgetPasswordForm .inputField .link{
    color: #0075FF;
    text-decoration:none;
  }
  .forgetPasswordPart h2{
    font-size: 24px;
    color:#031639;
    font-weight: 700;
  }
  .forgetPasswordPart p{
    font-size: 13px;
    color:#58596C;
    font-weight: 500;
  }
  .cursor-pointer {
    cursor: pointer;
  }
  // .ant-modal{top:40px;}
  // .ant-modal-body{
  //   max-height: 480px;
  //   overflow-y: auto;
  // }