.react-resizable {
  position: relative;
  background-clip: padding-box;
}

.react-resizable-handle {
  position: absolute;
  width: 10px;
  height: 100%;
  bottom: 0;
  right: -5px;
  cursor: col-resize;
  z-index: 1;
}

.main_loader {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0.94;
  overflow: hidden;
  z-index: 99999;
}

.error {
  color: red;
}

.ag-header-cell {
  .ag-react-container {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

input[type="file"] {
  width: 100px;
  color: transparent;
}

.user-dropdown .ant-upload-list-item-name {
  display: none;
}

.user-dropdown .anticon {
  display: none;
}

// .ant-modal-title{
//     text-align: left !important
// }

strong {
  color: #4d4d4d !important;
}

.ag-grid-custom-cell {
  text-align: end;
}

div.ag-grid-cell-error-boundary {
  border-color: rgb(201, 0, 0) !important;
}
div.ag-grid-cell-warning-boundary {
  border-color: #f39200 !important;
}

.ag-grid-clear-cell-error-boundary {
  border-color: green !important;
}

.cursor-pointer {
  cursor: pointer;
  color: #0075ff;
}

.cursor-disabled{
  cursor: not-allowed;
 color: #0075ff;
 
  opacity: 0.5;
}

.table-cell-action {
  .cursor-pointer {
    cursor: pointer;
    color: #0075ff;
    padding-right: 5px;
  }
}

.ant-modal-body {
  padding: 10px;
}
.ag-body-viewport.ag-layout-auto-height .ag-center-cols-clipper,
.ag-body-viewport.ag-layout-auto-height .ag-center-cols-container {
  min-height: 28px;
}

.status-code {
  border-radius: 40px;
  color: #fff;
  background-color: #aaa;
  font-size: 11px;
  padding: 4px 0;
  margin: 0 auto;
  text-align: center;
  max-width: 100%;
  width: 120px;
  text-transform: capitalize;

  &.new {
    background-color: #6a7fb0;

  }
  &.partially {
    &.fulfilled {
      background-color: #f6773d;
  
    }
  }
  &.label-orange {
    background-color: #ed934e;

  }
  &.awarded {
    background-color: #149e8c !important;

  }
  &.default {
    background-color: #707882 !important;

  }
  // &.pending {
  //   background-color: #92a8a8 !important;
  // }
  &.pending {
    background-color: #92a8a8;

  }
  &.label-primary {
    background-color: #4f69e0;

  }
  &.success {
    background-color: #53bd9b;

  }
  &.label-success {
    background-color: #53bd9b;

  }
  &.rejected {
    background-color: #d64636;

  }
  &.converted {
    background-color: #97a68b;

  }
  &.convertedToQuote {
    background-color: #97a68b;

  }
  &.outForPricing {
    background-color: #a0b976;

  }
  &.negotiating {
    background-color: #a3954b;

  }
  &.submitted {
    background-color: #137a58;

  }
  &.accepted {
    background-color: #0f8b3f;

  }
  &.supplierResponded {
    background-color: #2e57a1;

  }
  &.canceled {
    background-color: #b96230;

  }
  &.fulfilled {
    background-color: #1cb980;

  }
  &.open {
    background-color: #58596c;

  }
  &.unpaid {
    background-color: #59868f;

  }
  &.paid {
    background-color: #268258;

  }
  &.active {
    background-color: #2f664d;

  }
  &.processed {
    background-color: #387538;

  }
  &.declined {
    background-color: #c93210;

  }
  &.dueDays {
    background-color: #c93210;

  }
  &.shipped {
    background-color: #99b966;
    
  }
  &.invoiced {
    background-color: #138766;
  }
  &.not {
    background-color: #a7ad88;
  }
  &.delivered {
    background-color: #138766;
  }
  &.partially-paid {
    background-color: #99b966;
  }
  &.partially.fulfilled {
    background-color: #ed934e;
  }
  &.delivered {
    background-color: #138766;
  }
  &.not {
    background-color: #a7ad88;
  }
  &.invoiced {
    background-color: #138766;
  }
  &.shipped {
    background-color: #99b966;
  }
  &.declined {
    background-color: #c93210;
  }
  &.processed {
    background-color: #387538;
  }
  &.active {
    background-color: #2f664d;
  }
  &.paid {
    background-color: #268258;
  }
  &.unpaid {
    background-color: #59868f;
  }
  &.open {
    background-color: #58596c;
  }
  &.fulfilled {
    background-color: #1cb980;
  }
  &.canceled {
    background-color: #b96230;
  }
  &.supplierResponded {
    background-color: #2e57a1;
  }
  &.accepted {
    background-color: #0f8b3f;
  }
  &.new {
    background-color: #6a7fb0;
  }
  &.label-orange {
    background-color: #ed934e;
  }
  &.awarded {
    background-color: #149e8c !important;
  }
  &.default {
    background-color: #707882 !important;
  }
  &.pending {
    background-color: #92a8a8;
  }
  &.label-primary {
    background-color: #4f69e0;
  }
  &.success {
    background-color: #53bd9b;
  }
  &.rejected {
    background-color: #d64636;
  }
  &.converted {
    background-color: #97a68b;
  }
  &.convertedToQuote {
    background-color: #97a68b;
  }
  &.outForPricing {
    background-color: #a0b976;
  }
  &.negotiating {
    background-color: #a3954b;
  }
  &.submitted {
    background-color: #137a58;
  }
  &.initiated.paid {
    background-color: #268258;
  }
  &.initiated {
    background-color: #fdc427 !important;
  }
  &.redy {
    background-color: #e4702c;
  }
  &.overpayment {
    background-color: #c50115;
  }
  &.cancelled{
    background-color: #c50115;
  }

  &.cancellationrequested {
    background-color: #e4702c;
  }
  &.draft {
    background-color: #ed934e;
  }
  &.ready {
    background-color: #137a58;
  }
}

.ant-input-suffix {
  z-index: 0 !important;
  display: flex;
  align-items: center;
}

.anticon {
  z-index: 1 !important;
}

hr {
  height: 1px;
  background-color: #ccc;
  border: none;
}
.align-end {
  text-align: end;
}

.fa {
  padding: 2px;
}

.ag-row-inline-editing {
  .editable-cell-textarea {
    border: none !important;
  }
}

.ag-row-editing.ag-row {
  border-style: none !important;
}

// .show-address-popup{
//     .ag-cell-inline-editing{
//         height: 35px !important;
//     }
// }

.custom-height {
  //   height: 35px !important;
  resize: none;
  width: 100%;
}

.upload-notes {
  color: #39403e;
  font-weight: 400;
  font-size: 11px;
}

.grid-uploader {
  height: auto !important;
}

.custom-readonly-cell {
  pointer-events: none;
  opacity: 0.7;
}

.ant-select-clear {
  margin-top: 0px !important;
  top: 30%;
}

.ant-spin-nested-loading > div > .ant-spin {
  z-index: 1;
}

p {
  padding: 0px !important;
  margin: 0px 0px 0px 0px !important;
}

.ag-theme-balham .ag-cell-description {
  padding-right: 0;
  // padding-left: 15px;
}

.ag-cell {
  line-height: 18px !important;
  white-space: pre-wrap !important;
  word-break: break-word !important;
  overflow-wrap: break-word !important;
}

div.custm-ckeditor {
  outline: 0px solid;
  border-bottom: transparent;
}

.description-input {
  resize: none;
  outline: 1px solid #d9d9d9 !important;
  min-height: 30px;
}

.additional-padding {
  padding: 5px 10px;
}

.audit-trail-textarea {
  width: 100%;
  border: 1px solid #757575;
  border-bottom: 1px solid #757575 !important;
  min-height: 50px;
  border-radius: 2px;
  text-align: left;
  padding: 5px;
  margin-bottom: 15px;
}

span.ant-avatar {
  // height: auto !important;
  // width: auto !important;
  max-width: 230px !important;
  max-height: 90px !important;
  line-height: 100px !important;
}

.description-textarea {
  width: 462px;
  resize: none;
  padding: 5px;
  border: 1px solid #757575 !important;
  min-height: 100px;
  margin-bottom: 10px;
}

.add-new .ant-select-tree-switcher {
  display: none !important;
}

// .input-text-error {
//   border: 1px solid #ff4d4f !important;

//   &:hover {
//     border: 1px solid #ff4d4f !important;
//   }

//   &:focus {
//     border: 1px solid #ff4d4f !important;
//   }
// }

.ant-tree-select-dropdown
  .ant-select-tree-list-holder-inner
  .ant-select-tree-treenode {
  padding: 0px !important;
}

.ant-tree-select-dropdown
  .ant-select-tree-list-holder-inner
  .ant-select-tree-treenode
  .ant-select-tree-node-content-wrapper {
  padding: 4px;
}

.delete {
  color: #f44336;
  cursor: pointer;
}

.upoad-file-table table {
  width: 45%;
}

.pointer-none .ag-react-container {
  pointer-events: none;
}

.complaint-drawer {
  overflow-x: hidden !important;
  overflow-y: auto;
  margin-bottom: 60px;
}
.ant-dropdown {
  z-index: 999999 !important;
}

.action-btn-readonly {
  //  background-color: #cccfd2 !important;
  // box-shadow: 0 2px 2px rgba(0,0,0,.2) !important;
  height: 38px !important;
  padding-top: 5px !important;
  margin-right: 0 !important;
  width: 15px !important;
  // border-color: #cccfd2 !important;
  margin-left: 7px !important;
}

.add-footer-textarea {
  //@extend .description-textarea;
  width: 100%;
  border: 1px solid #757575 !important;
  text-align: center;
  padding: 5px;
  margin-bottom: 15px;
}


.cell-wrap-text {
  white-space: normal !important;
}

.extra-width {
  .ant-select-dropdown {
    width: 250px !important;
  }
}

.view-container{
  .table-container{
    table{
      tr{
        td{
          .ant-dropdown-trigger{
            cursor: pointer;
          }
        }
      }
    }
  }
}
.flex{
  display: flex;
}
.gap10{
  gap: 10px;
}
.opopacity0{
  opacity: 0;
}
.opopacity1{
  opacity: 1;
}
.sortTable-Container {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}
.justify-center {
  justify-content: center;
}
.justify-start {
  justify-content: flex-start;
}
.justify-end {
  justify-content: flex-end;
}
.justify-space-between{
  justify-content: space-between;
}