.invoice-header {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;

  .header-action-container {
    display: inline-flex;
    margin-top: 10px;
    margin-bottom: -10px;

    .ant-checkbox {
      margin-top: 9px;
    }

    .ant-legacy-form-item {
      padding-left: 10px;
      margin-bottom: 0;

      .ant-legacy-form-item-children {
        top: -5px;
      }

      .custom-dropdown-width {
        margin-right: 10px;
      }
    }

    &.sales-invoice {
      .custom-dropdown-width {
        width: 200px;

        .ant-select-arrow {
          right: 10px;
        }
      }
    }
  }
}

.invoice-details-container {
  border: 20px solid #e9ecf0;
  background-color: #fff;
  width: 100%;
  padding: 15px 15px 0 15px;

  .ant-avatar-icon {
    img {
      height: 75px;
      margin-top: 8px;
      object-fit: fill;
    }
  }

  .ant-legacy-form-item {
    display: inline-block;
    width: 100%;

    .custom-dropdown-width {
      .ant-select-selection-selected-value {
        line-height: unset;
      }
    }

    .year {
      color: #656b79;
      line-height: 2;
      margin-left: 15px;
      font-weight: 700;

      &.ml0 {
        margin-left: 0;
      }
    }

    .phone {
      color: #656b79;
      line-height: 1.5;
      margin-bottom: 20px;
      padding-left: 15px;
      width: 200px;

      &.pl0 {
        padding-left: 0;
      }
    }

    .address-gst {
      line-height: 1.3;

      &.space-fixed {
        .billing-address {
          padding-left: 0;
        }
      }
    }

    .date-picker-container {
      .ant-calendar-picker {
        width: 200px;
      }

      &:last-child {
        margin-bottom: 6px;
      }
    }

    .project-dropdown {
      .custom-dropdown-width {
        width: 100%;
        margin-left: 0;
      }
    }
  }

  .right-col {
    &.align-form {
      text-align: right;

      .ant-row.ant-legacy-form-item {
        width: 400px;
      }

      .title {
        padding-right: 20px;
      }

      .value {
        text-align: left;
      }
    }
  }

  .detail-column {
    color: #39414b;
    text-align: end;
    padding-right: 15px;
  }

  .billing-address {
    color: #656b79;
    padding-left: 15px;
    line-height: 1.5;
  }

  .grey-button {
    background-color: #e9ecf0;
    margin-right: 10px;
  }

  &:last-child {
    border-top: 5px;
    margin-bottom: 40px;
    padding-bottom: 15px;
  }

  &.border-top {
    border-top: 20px solid #e9ecf0;
  }

  .ant-col-2 {
    text-align: left;

    .grey-button {
      margin-right: 0;
    }
  }

  .remarks-title {
    font-size: 13px;
    padding-top: 20px;
    padding-bottom: 15px;

    &:nth-last-of-type(1) {
      padding-top: 0;
    }
  }

  .checkbox-control-group {
    float: right;
  }

  .description-textarea {
    width: 462px;
    resize: none;
    //padding: 5px;
    border: 1px solid #757575;
    min-height: 100px;
    margin-bottom: 10px;
  }

  .upoad-file-table {
    width: 450px;
  }

  .ag-body-viewport.ag-layout-auto-height .ag-center-cols-clipper,
  .ag-body-viewport.ag-layout-auto-height .ag-center-cols-container {
    min-height: 25px;
  }

  .ag-theme-balham {
    .ag-header-container {
      .ag-header-row {
        background-color: #f3f3f3;
        color: #39414b;
      }
    }
  }

  .add-footer-textarea {
    //@extend .description-textarea;
    width: 100%;
    border: 1px solid #757575;
    text-align: center;
    padding: 5px;
    margin-bottom: 15px;
  }

  .ant-cascader-picker {
    width: 100%;
    height: 100%;
  }

  .product-amount-details {
    background-color: #f8f8f8;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    color: #656b79;
    margin-top: 85px;
    margin-left: auto;
    margin-bottom: 40px;
    padding: 20px 30px 10px 30px;
    width: 65%;

    .ant-col-9 {
      text-align: right;
    }

    .ant-col-7 {
      text-align: right;
    }

    .ant-col-14 {
      text-align: right;
      padding-right: 20px;
    }

    .ant-col-8 {
      text-align: right;
      padding-right: 20px;
    }

    .ant-col-9 {
      text-align: right;
      padding-right: 20px;
    }

    &.top-space {
      padding-top: 20px;
    }

    .input-group {
      //  display: flex;
      width: 100%;

      .input-label-control {
        position: relative;
        margin-right: 10px;
        width: 100%;

        .mark {
          background-color: #e2e2e2;
          color: #000;
          height: 32px;
          line-height: 32px;
          position: absolute;
          top: 0;
          left: -2px;
          text-align: center;
          width: 35px;
          z-index: 1;
        }

        .ant-input {
          padding-left: 40px;

          &:focus {
            box-shadow: none;
          }
        }

        &:nth-last-of-type(1) {
          margin-right: 10;
        }
      }

      .label {
        font-size: 13px;
        line-height: 32px;
        padding-right: 10px;
      }
    }

    .custom-dropdown-width {
      text-align: left;
      // width: 200px;
      width: 140px;
      left: -2px;
    }

    .amount-summary {
      margin-bottom: 15px;

      .title {
        font-size: 13px;
      }

      .rupee {
        font-size: 14px;
      }
    }

    .w-30 {
      display: inline-block;
      padding-right: 20px;
      width: 30%;
    }

    .w-70 {
      display: inline-block;
      width: 70%;
    }

    .w-50-default {
      display: inline-block;
      width: 50%;
    }

    .w-50 {
      display: inline-block;
    }

    .ant-row-flex-end {
      margin-bottom: 20px;
    }

    .ant-col-7 {
      align-items: center;
      // display: inline-flex;
      text-align: right;
      padding-right: 20px;
    }
  }

  .title {
    color: #39414b;
    text-align: right;
  }

  .ag-root-wrapper {
    overflow: unset;

    .ag-large-textarea {
      textarea {
        margin-bottom: -5px;
        height: auto;
        resize: none;
        overflow: hidden;
      }
    }
  }
}

.shipping-instructions {
  width: 450px;
  resize: none;
  padding: 5px;
  margin-bottom: 10px;
}

.add-footer-textarea2 {
  width: 100%;
  border: 1px solid #757575 !important;
  text-align: center;
  padding: 5px;
  margin-bottom: 15px;
}

.split-rfq-details-container {
  .remarks-title {
    padding-top: 0;
  }

  .checkbox-control-group {
    float: right;
  }

  .description-textarea {
    width: 462px;
    resize: none;
    padding: 5px;
    margin-bottom: 10px;
  }

  .add-footer-textarea {
    //@extend .description-textarea;
    width: 100%;
    text-align: center;
    padding: 5px;
    margin-bottom: 10px;
  }
}

.space-lr {
  display: inline-block;
  padding-left: 10px;
}

.ag-theme-balham .ant-select {
  width: 100%;
}

div.ag-header-cell {
  cursor: auto !important;
}

.custom-address-dropdown {
  border-bottom: 1px solid #bfbebe;
}

.transaction-table {
  .ag-cell-not-inline-editing {
    cursor: pointer;
  }

  .ag-react-container {
    height: auto;

    .ant-tree-select {
      height: 28px;

      .ant-select-selector {
        min-height: 28px;
        height: 28px;
      }
    }
  }

  .ag-center-cols-viewport {
    // width: fit-content;
  }
}

//.split-column{
//  align-self: unset !important;
//  display: unset !important;
//}
.price-box {
  position: relative;
  margin-left: 10px;
  margin-right: 10px;
  padding: 4px 6px 4px 35px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  height: 32px;

  .icons1 {
    background-color: #e2e2e2;
    color: #000;
    height: 32px;
    line-height: 30px;
    position: absolute;
    top: 0;
    left: -2px;
    text-align: center;
    width: 35px;
    z-index: 1;
  }
}

.price-box1 {
  position: relative;
  margin-left: 10px;
  margin-right: 10px;
  padding: 4px 5px 4px 65px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  height: 32px;

  .icons2 {
    background-color: #e2e2e2;
    color: #000;
    height: 32px;
    line-height: 30px;
    position: absolute;
    top: -1px;
    left: -2px;
    text-align: center;
    width: 60px;
    z-index: 1;
  }
}

.right1 {
  background-color: #e2e2e2;
  color: #000;
  height: 32px;
  line-height: 30px;
  position: absolute;
  top: 0;
  right: -2px;
  text-align: center;
  width: 35px;
  z-index: 1;
}

.product-amount-details {
  // background-color: #F8F8F8;
  // box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  color: #656b79;
  margin-top: 85px;
  margin-left: auto;
  margin-bottom: 40px;
  padding: 20px 30px 10px 30px;
  width: 65%;

  .ant-col-9 {
    text-align: right;
  }

  .ant-col-7 {
    text-align: right;
  }

  .ant-col-14 {
    text-align: right;
    padding-right: 20px;
  }

  .ant-col-8 {
    text-align: right;
    padding-right: 20px;
  }

  .ant-col-9 {
    text-align: right;
    padding-right: 20px;
  }

  &.top-space {
    padding-top: 20px;
  }

  .input-group {
    //  display: flex;
    width: 100%;

    .input-label-control {
      position: relative;
      margin-right: 10px;
      width: 100%;

      .mark {
        background-color: #e2e2e2;
        color: #000;
        height: 32px;
        line-height: 32px;
        position: absolute;
        top: 0;
        left: -2px;
        text-align: center;
        width: 35px;
        z-index: 1;
      }

      .ant-input {
        padding-left: 40px;

        &:focus {
          box-shadow: none;
        }
      }

      &:nth-last-of-type(1) {
        margin-right: 10;
      }
    }

    .label {
      font-size: 13px;
      line-height: 32px;
      padding-right: 10px;
    }
  }

  .custom-dropdown-width {
    text-align: left;
    // width: 200px;
    width: 140px;
    left: -2px;
  }

  .amount-summary {
    margin-bottom: 15px;

    .title {
      font-size: 13px;
    }

    .rupee {
      font-size: 14px;
    }
  }

  .w-30 {
    display: inline-block;
    padding-right: 20px;
    width: 30%;
  }

  .w-70 {
    display: inline-block;
    width: 70%;
  }

  .w-50-default {
    display: inline-block;
    width: 50%;
  }

  .w-50 {
    display: inline-block;
  }

  .ant-row-flex-end {
    margin-bottom: 20px;
  }

  .ant-col-7 {
    align-items: center;
    // display: inline-flex;
    text-align: right;
    padding-right: 20px;
  }
}