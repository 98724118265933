.modal-table-container {
  // max-height: calc(100vh - 420px);
  overflow-y: auto;

  .table-heading {
    color: #000;
    padding-bottom: 10px;
  }

  .transaction-details-table {
    tbody {
      td {
        strong {
          font-weight: 600;
        }

        .description-cell {
          padding-top: 10px;
          max-width: 350px;
          text-align: left!important;
          word-break: break-all;

          pre {
            font-family: 'Segoe UI','Droid Sans',Tahoma,Arial,sans-serif;
            font-size: 13px;
            line-height: 1.4;
            margin-bottom: 0;
            text-align: left;
          }

          ul {
            margin-bottom: 0;
            margin-left: 20px;

            li {
              padding-bottom: 5px;

              &:last-child {
                padding-bottom: 0;
              }
            }
          }

          p {
            padding-bottom: 5px!important;
          }

          &.pr100 {
            padding-right: 100px;
          }

          .title {
            font-weight: 600;
          }
        }
      }
    }
  }

  table {
    width: 100%;

    tr {
      border-width: 0;
    }

    thead {
      tr {
        background-color: #cbd0d2;
        height: 30px;

        th {
          color: #676a6c;
          font-size: 13px;
          padding: 5px 10px;

          &:first-child {
            border-left: 1px solid #e9ecf0;
          }

          &:last-child {
            border-right: 1px solid #e9ecf0;
          }
        }
      }
    }

    tbody {
      tr {
        height: 30px;

        td {
          font-size: 13px;
          padding: 5px 10px;
          vertical-align: top;

          &:first-child {
            border-left: 1px solid #e9ecf0;
          }

          &:last-child {
            border-right: 1px solid #e9ecf0;
          }
        }

        &:nth-child(even) {
          background-color: #eef3f5;
        }

        &:last-child {
          border-bottom: 1px solid #e9ecf0;
        }
      }

      .link {
        color: #4697ce;

        &:hover {
          cursor: pointer;
        }
      }
    }

    th, td {
      &.cell-size-50 {
        text-align: center;
        width: 50px;
      }

      &.cell-size-100 {
        text-align: center;
        width: 100px;
      }
    }
  }

  .right-text {
    text-align: right;
    padding-right: 20px;
  }

  &.vertical-space {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  &.rfqBidding-supplier-list {
    table {
      .editable-row {
        .ant-table-cell {
          .table-cell-action {
            display: none;

            .link {
              cursor: default;
              margin-right: 5px;

              .fa {
                background-color: transparent!important;
                color: #656b79;
                font-size: 16px;
                padding: 0;
                width: 20px;

                &:hover {
                  color: #272727;
                  cursor: pointer;
                }
              }

              &:last-child {
                margin-right: 0;
              }
            }
          }
        }

        &:hover {
          box-shadow: 0 2px 2px rgba(0, 0, 0, .2);

          .table-cell-action {
            display: inline-flex;
          }
        }
      }
    }
  }
}
