@import '../../../assets//stylesheets/global/variable';

.StoreSettings-container {
  .page-title-bar {
    border-bottom: 1px solid #E8E8FF;
    height: 54px;
    padding: 0px 20px;
    z-index: 99;
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    // justify-content: space-between;
    align-items: center;
    gap: 10px;

    .page-title {
      font-size: 16px;
      color: $primaryColor;
      font-weight: 700;
    }

    .sep {
      width: 1px;
      background-color: #e7e7e7;
      height: 40%;
    }

  }

  .settings-body {
    background-color: #ffffff;
    padding: 10px;
    height: calc(100vh - 125px);
    overflow-y: auto;

    .settings-body-child {

      .child-title {
        font-size: 14px;
        font-weight: 600;
        color: $primaryColor;
        padding-bottom: 5px;
        border-bottom: 1px solid #e7e7e7;
      }

      .child-body {
        display: flex;
        gap: 10px;
        margin: 0px 0;
        flex-wrap: wrap;

        .tile {
          width: calc((100% - 60px) / 3);
          border: 1px solid #F5F7F9;
          padding: 20px;
          border-radius: 4px;
          display: flex;
          background-color: #F5F7F9;
          cursor: pointer;
          height: 120px;

          &:hover {
            background-color: #e8ebee;
          }

          .tile-icon {
            padding-right: 12px;

            i {
              font-size: 18px;
            }

          }

          .tile-title {
            font-size: 14px;
            font-weight: 600;

            .tile-description {
              font-weight: 400;
              line-height: 1.3;
              font-size: 11px;
            }
          }

        }
      }
    }

  }
}


// eStore css 
.store-model-container {
  height:100vh;
  // padding:0px 20px 40px 0px;
  // width: 100%;
  overflow: auto;
  
  .store-main-container{
     padding: 10px;
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .store-footer {
    height: 20%;

    position: sticky;
    bottom: 0;
    display: flex;
    justify-content: end;


  }

  .model-head {
    height: 40px;
    margin: 0;
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
  }

  .f-item-center {
    display: flex;
    align-items: center;
  }

  .heading {
    font-size: 16px;
    font-weight: 700;
    color: #08173d;
  }

  .sub-heading {
    font-weight: 400;
  }

  .text-right {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .col-right {
    display: flex;
    align-items: center;
    gap : 5px;
    cursor : pointer
  }

  .footer-btn{
    position: fixed;
    display: flex;
    bottom: 0px;
    right: 3px;
    justify-content: end;
    border-top: 1px solid #e7e7e7;
    background-color: #fafbff;
 
    padding: 20px;
    height: 54px;
    align-items: center;
    width: calc(100% - 435px);
       
      }
      .custom-header{

      
  .ant-radio-group {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    gap: 20px;

  }

  .option-box {
    display: flex;
    gap: 10px;

    .recomm-label {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 105px;
      height: 26px;
      background-color: #1cb961;
      color: #fff;
      border-radius: 48px;
      padding: 10px;
    }

  }

   .ant-radio .ant-radio-inner{
    width: 24px;
    height: 24px;

  }


   .ant-radio-inner::after {
    width: 16px;
    height: 16px;
  }

  
  .custom-collapse{
    width: 800px;
    border-radius: 6px;

  }
  .ant-collapse{
    background-color: #ffffff !important;
    border: none !important;


    .ant-collapse-item{
      border: 1px solid #d9d9d9 !important;
      margin-bottom: 10px !important;
      border-radius: 6px;
    }
  }
  .selected-box {
    display:flex;
    justify-content: space-between;

  }
      }

  .custom-panel {
    .ant-collapse-header{
      // padding: 16px 40px 16px 20px;
      padding: 20px 48px 16px 22px;
    }
  }
  .box-content {
    display: flex;
    flex-direction: column;
    margin-top: -8px;
  }
  .jus-content{
    justify-content: space-between;
    gap:10px;
    align-items: center;
    padding-right: 10px;
  }
  .ref-box{
    width:189px;
    height:289px;
    background-color: #D9D9D9;
    border-radius: 6px;

  }

  .f-row{
    flex-direction: row;
  }

  .f-btn{
    display: flex;
    justify-content: end;

    .ant-btn-primary {
   border-radius: 4px !important;

  }
 
  }
  .strong-txt{
font-weight: 700;
color: #031639;
font-size: 12px;
  }
  .flex-item{
    display: flex !important;
    align-items: center !important;
  }
  .d-items{
    display: flex;
    gap: 85px;
  }
}
.store-model-container::-webkit-scrollbar {
  display: none;
}

.day-wrapper {
  width: 60%;
  margin: 10px;
  height: 45px;
  padding: 5px;

  td {
    width: 15%;
    padding: 5px auto;
    text-align: start;
  }

  .timing {
    position: relative;

    .ant-select {
      width: 100px;

      .ant-select-selector {
        height: none !important;
        min-height: none !important;

      }
    }

    .dropdown-list {
      position: absolute;
      padding: 10px;
      top: 43px;
      background-color: #ffffff;
      border-radius: 10px;
      cursor: pointer;
      box-shadow: 10px 10px 5px -4px rgba(219, 216, 219, 1);
      overflow-y: scroll;
      z-index: 99;
      font-size: 16px;
      font-weight: 400;
      width: 120px;
      max-height: 16rem;
      cursor: pointer;

      li {
        list-style: none;
        padding: 5px;
        font-size: 14px !important;
      }
    }
  }


  .data-inner-wrapper {
    display: flex;
    align-items: flex-start;
    gap: 20px;
  }
}
.model-container{
  padding: 20px 0;
}

.custom-switch .ant-switch-inner {
  display: none;
  /* Hide the switch inner content (labels) */
}


.languages-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e7e7e7;
  padding-bottom: 10px;
}

.primary-laguage-warpper {
  display: flex;
  align-items: center;
  gap: 10px;
}

.laguage-label {
  font-size: 14px;
}

.linkStyle {
  a {
    font-size: 14px;
    cursor: pointer;
  }
}

.primary-label-lang {
  padding: 3px 8px;
  background-color: #FFB020;
  color: #ffffff;
  height: 20px;
  text-align: center;
  border-radius: 44px;
  font-size: 11px;
  font-weight: 400;
  display: flex;
  align-items: center;
}

.color-danger {
  color: red;
}

.model-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  bottom: -50px;
  width: 100%;
}
.cus-space {
  // padding: 8px 10px !important;
  padding: 8px 0px 185px 10px !important;
}
.coupon-container {
  height: calc(100vh - 225px) !important;
}

.customEditor{

  margin-top: 20px;
  .cke_contents{
    height: 450px !important;
  }
}
.count-box{
  border: 1px solid #d9d9d9;
  height: 38px;
  padding: 8px;
  cursor: pointer;
  
  .disabled {
    pointer-events: none;
    opacity: 0.5;
  }
  
}
.note{
  background-color: #FFEA00;
  color: #FF5722;
}