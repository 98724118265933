@import "./../assets/stylesheets/global/variable";
.horizontal-seperator {
  margin: 15px 0;
  border-bottom: 1px solid #e9ecf0;
}

.fw-600 {
  font-weight: 600;
}

@mixin green-btn-style {
  color: $white;
  background-color: $green-btn-bg;
  border-color: $green-btn-border;
}

@mixin default-btn-style {
  color: #656b79;
  background-color: #ffffff;
  border-color: #d9d9d9;
}

@mixin green-border {
  border: 1px solid $green-btn-border;
}

@mixin white-wrapper {
  background: $white;
  border-radius: 3px;
}

h4 {
  font-size: $fs-18;
}

.border-red {
  border: 1px solid $error-red;
}

.hide {
  display: none;
}

.link-color {
  color: #4697ce;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.pull-right {
  float: right;
}

// Lead-list
.customSelect {
  .ant-select-selector {
    height: 25px !important;
  }

  .ant-select-selector {
    min-height: 25px !important;
    border-radius: 5px !important;
    background-color: #dedede !important;
    font-weight: 500;
    border: none !important;
  }
}

.side-drawer {
  height: calc(100vh - 70px);
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 15px 0;
}

.side-Wrapper {
  padding: 5px 15px;
}

.divider {
  border-top: 2px solid #d6d6d6;
  margin: 10px 0;
}

.label-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;

  button {
    background: none;
    border: none;
    outline: none;
  }
}

.date-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    .date {
        font-weight: 500;
    }

    .paid {
        color: #0075FF;
    }
}

.main-page {
  display: flex;
}

.main-lead-list-page {
  width: calc(100% - 270px);
}

.side-drawer {
  min-width: 270px !important;
  background-color: #ffffff;
}

.side-header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.closebtn {
  background-color: #fff !important;
  border: none;
  outline: none;
}

.phone-no {
  display: flex;
  gap: 5px;
  align-items: center;
  font-weight: 500;
}

.editWrap {
  margin-top: 15px;
}

.editbuttonType {
  font-weight: 700;
  font-size: 14px;
}

.label-input {
  margin-top: 15px;
  color: #58596c;
}

.label-text {
  background-color: #fff !important;
  color: #939393;
  font-size: 12px;
  font-weight: 400;
}

.project {
    margin-top: 15px;
}

.project-heading {
    font-weight: 700;
    font-size: 12px; 
}

.input-wrap {
  margin-top: 20px;
}

.input-label {
  font-weight: 700;
}

.lead-management {
  margin-top: 15px;

  .ant-select-selector{
    // border-radius: 5px;
    // background-color: #dedede;
    height: 38px;
    flex-wrap: nowrap;
    overflow: hidden;
  }
}

.reminder-wrap {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
}

.note-wrap {
  margin-top: 15px;
}

.textAreaStyle {
  width: 100%;
  padding-top: 10px;
  padding: 10px;
}

.note-text {
  padding: 3px 0 !important;
  color: #58596c;
  font-size: 12px;
  font-weight: 500;
  overflow-wrap: break-word;
  line-height: 1.3em;
}

.save-button {
  color: "#fffffff";
  background: #4697ce;
  text-align: end;
  border: 1px solid #4697ce;
}

.form-answers {
  margin-top: 15px;
}

.view-form {
  color: #4697ce;
  text-align: right;
}

.text-wrap {
  margin-top: 10px;

  p,
  span {
    font-size: 12px;
    font-weight: 500;
  }
  h6 {
    font-weight: 700;
  }
}

.custom-dropdown-menu{
  height: 250px;
  overflow: auto;
    .user-icon{
        width: 40px;
        height: 40px;
        background-color: #e7e7e7;
        color: #000000;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 30px;
        letter-spacing: 1px;
        cursor: pointer;
        padding: 3px 0 0 3px;
      }
    .ant-checkbox-group{
        display: flex;
        flex-direction: column;
        max-height: 500px;
        overflow: auto;
        gap: 20px;
        padding-top: 14px;
        :first-child{
            margin-left: 8px;
        }
        .ant-checkbox-wrapper{
            display: flex;
            align-items: center;
            span{
                display: flex;
            }
            .ant-checkbox-input{
                display: flex;
                // align-items: center;
            }
        }
    }
    .custom-dropdown-detail{
        display: flex;
        flex-direction: column;
    }

}

.table-col-drawer{
    overflow-y: auto;
    // height: 100%;
    height: calc(100vh - 376px);
   
    table{
        width: 100%;
        .user-icon{
            width: 40px;
            height: 40px;
            background-color: #e7e7e7;
            color: #000000;
            font-weight: 600;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 30px;
            letter-spacing: 1px;
            cursor: pointer;
            padding: 3px 0 0 3px;
          }
          .custom-dropdown-detail{
            display: flex;
            flex-direction: column;
        }

        thead {
            tr {
              th {
                background-color: #ffffff;
                font-size: 12px;
                font-weight: 700;
                width: 50%;
                color: #283c50;
                border-bottom: 1px solid #e8e8ff;
                padding: 10px 0px;
                cursor: pointer;
      
                position: sticky !important;
                top: 0;
                z-index: 1;
      
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
          }
        tbody {
            tr {
              &:nth-child(even) {
                background-color: #fdfdff;
              }
              td {
                font-size: 12px;
                color: #283c50;
                font-weight: 400;
                border-bottom: 1px solid #e8e8ff;
                padding: 10px 10px;
                color: #283c50;
                // vertical-align: top;
      
                .action-icon {
                  :hover:active:focus {
                    cursor: pointer;
                  }
                }
                // if row has child to show
                .child-sr {
                  padding-left: 15px;
                  display: flex;
                  justify-content: center;
                  padding-top: 10px;
      
                  &::before {
                    content: "~";
                  }
                }
      
                &:first-child {
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
      
                &.one-line-view,
                .line-view {
                  // white-space: nowrap;
                  // overflow: hidden;
                  // text-overflow: ellipsis;
                }
                .link {
                  cursor: pointer;
                  color: $secondaryColor;
                }
              }
              &:hover {
                background-color: #f0f1f5;
              }
            }
          }
    }
}

.success {
  color: #1CB980;
}
.failed {
  color: #F85353;
}