@import '~antd/dist/antd.css';
@import '../../common.scss';

$text-color: #58596C;

.form-footer {
  right: 0;
  bottom: 0;
  margin-top: 10px;
}

.success-button {
  float: right;
}

.cancel-button {
  float: left;
}


.ant-legacy-form-item {
  margin-bottom: 10px;
}

.ant-checkbox-checked .ant-checkbox-inner {
  @include green-btn-style;
}

.ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner:hover {
  @include green-border;
}

.address-label {
  margin-bottom: 20px;
  color: $text-color;
}

.ant-tabs-tab-active {
  background: #fff;
  color: #727475;
  border-color: #e8e8e8 !important;
  border-bottom: 1px solid #fff !important;
}

.tab-view {
  min-height: 260px;
}
