.product-details-container {
  margin: 20px 0;

  .customer-details {
    background-color: #fff;
    padding-bottom: 50px;
    padding-left: 15px;

    .address-info {
      width: 150px;
      line-height: 2.2;
    }

    .customer-identity-number {
      .ant-col-10 {
        color: #39414b;
        // text-align: right;
        padding-right: 20px;
        padding-bottom: 10px;
      }

      .ant-col-14 {
        padding-bottom: 10px;
      }
    }
  }

  .ant-collapse {
    background-color: #e9ecf0;
    margin-bottom: 20px;

    .ant-collapse-header {
      color: #000;
      font-size: 16px;
      font-weight: 700;
      letter-spacing: .1em;
      padding: 9px 15px;
    }

    .anticon-right {
      left: unset!important;
      right: 15px;
      transform: rotate(180deg) translateY(6px)!important;
    }

    .ant-collapse-item-active {
      .anticon-right {
        transform: rotate(0deg) translateY(-6px)!important;
      }
    }
  }
}

.modal-table-container {
  .align-left {
    text-align: left;
    padding-left: 20px;

    .grey-text {
      color: #656b79;
    }
  }
}

