// .reports-container {
//   padding-top: 10px;
//   margin-left: -20px;
//   margin-right: -20px;

//   // .breadcrumb-section {
//   //   background-color: #dddede;
//   //   padding: 0px 0px 0px 20px;
//   //   margin-bottom: 20px;
//   // }
// }

.main-box {
  background-color: #fff;
  // box-shadow: 0 2px 2px rgba(0, 0, 0, .1);
  padding: 15px 15px 30px 15px;
  margin: 20px 0px;
  display: flex;
  flex-direction: row;
  min-height: 200px;

  .customer-box{
    width: 50%;
    display: flex;
    flex-direction: column;

    .title {
      color: #2c2c2c;
      font-size: 16px;
      font-weight: 300;
      padding: 10px 10px;
    }

    .report-box{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 10px ;

      .report-body{
        background-color: rgb(245, 245, 245);
        padding: 15px 10px;
        border-radius: 4px;
        width: 48%;

        &:hover{
          background-color: rgb(220, 220, 220);
          cursor: pointer;

          // .report-title{
          //   font-size: 16px;
          //   font-weight: 500;
  
          //   .title-icon{
          //     font-size: 20px;
          //     margin-right: 10px;
          //   }
          // }
        }

        .report-title{
          font-size: 14px;
          font-weight: 500;

          .title-icon{
            font-size: 18px;
            margin-right: 10px;
          }
        }

        .report-sub-title{
          font-size: 12px;
        }

      }
    }


  }


  // .title {
  //   color: #000;
  //   font-size: 18px;
  //   font-weight: 300;
  //   padding-bottom: 20px;

  //   &.small {
  //     font-size: 18px;
  //   }
  // }

  // .report-actions {
  //   display: flex;
  //   justify-content: space-between;

  //   .action-box {
  //     // width: 40%;
  //     padding: 20px;
  //     // border: 2px solid #bbbbbb;
  //     background-color: rgb(236, 236, 236);
  //     border-radius: 6px;


  //     .link {
  //       color: #040404;
  //       font-size: 14px;
  //       font-weight: 400;

  //       &:hover {
  //         cursor: pointer;
  //         text-decoration: underline;
  //       }
  //     }
  //   }
  // }

}